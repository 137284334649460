import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: false,
  isEnrolled: false,
  isValidated: false,
  freelancer: {
    name: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    password: "",
    bio: "",
    profilePictureURL: "",
    address: "",
    city: "",
    state: "",
    documentUrl: "",
    categoryId: "",
    subCategoryId: "",
    serviceName: "",
    serviceDescription: "",
    packageName: "",
    packageDescription: "",
    packagePrice: "",
    serviceImages: [],
    packageMetadataArray: [],
  },
  customer: {},
  token: null,
  loading: false,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setFreelancerAuth: (state, action) => {
      state.freelancer = action.payload;
      localStorage.setItem("freelancerAuth", JSON.stringify(action.payload));
    },
    setCustomerAuth: (state, action) => {
      state.customer = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setLoggedIn, setFreelancerAuth, setCustomerAuth } =
  authSlice.actions;

export const selectLoggedIn = (state) => state.auth.loggedIn;
export const selectFreelancerAuth = (state) => state.auth.freelancer;
export const selectCustomerAuth = (state) => state.auth.customer;

export default authSlice.reducer;
