import React from "react";
import ProgressBar from "pages/auth/signup/components/ProgressBar";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "layout/main/MainLayout";
import AuthLayout from "layout/auth/AuthLayout";
import Service from "pages/auth/signup/freelancer/steps/service/Service";
import Package from "pages/auth/signup/freelancer/steps/package/Package";
import ServicePictures from "pages/auth/signup/freelancer/steps/service-pictures/ServicePictures";
import ServiceMetadata from "pages/auth/signup/freelancer/steps/service-metadata/ServiceMetadata";
import { useDispatch, useSelector } from "react-redux";
import Widget from "components/widget/Widget";
import { itemRender } from "functions/ItemRender";
import customAxios from "middleware/customAxios";
import { setFreelancerAuth } from "features/auth/AuthSlice";
import Rate from "pages/auth/signup/freelancer/steps/rate/Rate";

export default function AddService() {
  let [progress, setProgress] = React.useState(0);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let { step } = useParams();
  let freelancerAuth = useSelector((state) => state.auth.freelancer);
  let [submitted, setSubmitted] = React.useState(false);
  let [logState, setLogState] = React.useState(false);

  const handleSubmit = () => {
    console.log("submitted");

    // make serviceMetadata array
    let serviceMetadataArray = [];
    freelancerAuth.ServiceMetadata.forEach((metadata) => {
      let key = metadata.key;
      let value = metadata.value;

      // create object
      let metadataObject = {
        [key]: value,
      };

      serviceMetadataArray.push(metadataObject);
    });

    let data = {
      categoryId: freelancerAuth.category?.id,
      subCategoryId: freelancerAuth.subCategory?.id,
      serviceName: freelancerAuth.serviceName,
      serviceDescription: freelancerAuth.serviceDescription,
      packageName: freelancerAuth.packageName,
      packageDescription: freelancerAuth.packageDescription,
      packagePrice: freelancerAuth.rate,
      serviceImages: freelancerAuth.serviceImages,
      packageMetadataArray: serviceMetadataArray,
    };

    console.log(data);

    customAxios.post("/freelancer/service/add", data).then((res) => {
      // dispatch(setFreelancerAuth(null));
      console.log(res);
      // navigate("/my-services");
    });
  };

  React.useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    switch (step) {
      case "Service Information":
        setProgress(0);
        break;
      default:
        setProgress(0);
    }
  }, [step]);

  // Switch case statement to render the correct step
  const renderStep = () => {
    switch (step) {
      case "Service Information":
        return (
          <AuthLayout
            heading={"Service Information"}
            subtitle={
              "Please fill in the following information to add a new service"
            }
          >
            <Service next={"/my-services/add/Service Pictures"} />
          </AuthLayout>
        );
      case "Service Pictures":
        return (
          <AuthLayout
            heading={"Service Pictures"}
            subtitle={
              "Please fill in the following information to add a new service"
            }
          >
            <ServicePictures next={"/my-services/add/Service Metadata"} />
          </AuthLayout>
        );
      case "Service Metadata":
        return (
          <AuthLayout
            heading={"Service Metadata"}
            subtitle={
              "Please fill in the following information to add a new service"
            }
          >
            <ServiceMetadata next={"/my-services/add/Packages"} />
          </AuthLayout>
        );

      case "Packages":
        return (
          <AuthLayout
            heading={"Packages"}
            subtitle={
              "Please fill in the following information to add a new service"
            }
          >
            <Package next={"/my-services/add/Rate"} />
          </AuthLayout>
        );
      case "Rate":
        return (
          <AuthLayout
            heading={"Packages"}
            subtitle={
              "Please fill in the following information to add a new service"
            }
          >
            <Rate next={"/my-services/add/Preview"} />
          </AuthLayout>
        );
      case "Preview":
        return (
          <AuthLayout
            heading={"Service Preview"}
            subtitle={
              "Please fill in the following information to add a new service"
            }
          >
            <Widget
              heading={"Service Information"}
              subHeading={""}
              editUrl={"/auth/signup/seller/step/Service"}
            >
              {itemRender("Category", freelancerAuth.category?.name)}
              {itemRender("Sub Category", freelancerAuth.subCategory?.name)}
              {itemRender("Service", freelancerAuth.serviceName)}
              <div className="col-span-2">
                {itemRender("Description", freelancerAuth.serviceDescription)}
              </div>
            </Widget>
            <Widget heading={"Service Pictures"} subHeading={""}>
              <div className="col-span-3 grid grid-cols-8">
                {freelancerAuth?.serviceImages.map((item, index) => {
                  return (
                    <div key={index}>
                      <img
                        className="w-20 transition-all hover:h-40 hover:w-40 h-20 object-cover rounded-md"
                        src={item}
                        alt="service"
                      />
                    </div>
                  );
                })}
              </div>
            </Widget>
            {/* Package */}
            <Widget
              heading={"Package"}
              subHeading={""}
              editUrl={"/auth/signup/seller/step/Package"}
            >
              {itemRender("Package", freelancerAuth.packageName)}
              <div className="col-span-2">
                {itemRender("Description", freelancerAuth.packageDescription)}
              </div>

              {itemRender("Price", JSON.stringify(freelancerAuth.rate))}
            </Widget>
            {/* Package Metadata */}
            <Widget
              heading={"Package Metadata"}
              subHeading={""}
              editUrl={"/auth/signup/seller/step/Package Metadata"}
            >
              {freelancerAuth?.ServiceMetadata?.map((item, index) => {
                return (
                  <div key={index}>{itemRender(item.key, item.value)}</div>
                );
              })}
            </Widget>
            <div className="flex items-start justify-start py-10">
              {submitted ? (
                <button
                  type="button"
                  className=" flex flex-row justify-between text-teal-600 text-md font-medium p-2 px-6 rounded-md cursor-not-allowed"
                >
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-teal-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"
                    ></path>
                  </svg>
                  {logState}
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={() => {
                    setSubmitted(true);
                    // 5 seconds later
                    setTimeout(() => {
                      setSubmitted(false);
                    }, 5000);
                    handleSubmit();
                  }}
                  className="bg-teal-600 text-white text-sm font-medium p-2 px-10 rounded-full hover:bg-teal-800"
                >
                  Submit
                </button>
              )}
            </div>
          </AuthLayout>
        );

      default:
        return <div>Hello</div>;
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col justify-between absolute z-50 top-0 bg-white">
      {renderStep()}
      <ProgressBar progress={progress} />
    </div>
  );
}
