import { StarIcon } from "@heroicons/react/solid";
import StarRatings from "components/star-ratings/StarRatings";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function WorkCard({
  key,
  id,
  image,
  title,
  price,
  user,
  path,
  ad,
}) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() =>
        !ad ? navigate(path, { state: { id } }) : window.open(path)
      }
      className="rounded-xl relative flex flex-col bg-white hover:cursor-pointer w-full"
    >
      {/* gradient */}
      <div className="absolute top-0 left-0 w-full h-[190px] rounded-2xl bg-gradient-to-b bg-opacity-20 from-black/50 to-transparent z-10"></div>
      <div
        style={{ backgroundImage: `url(${image})` }}
        className="bg-cover  relative bg-center  hover:brightness-105 h-[200px] md:h-[190px] transition-all duration-150 bg-no-repeat  w-full rounded-xl"
      >
        {/* Create a gradient over */}
      </div>
      <div className="flex flex-row space-between  pt-3 items-start w-full">
        <div className="flex flex-col space-y-1 md:min-h-[50px w-fit overflow-hidden">
          <h1 className=" font-semibold  text-lg  h-fit ] ">{title}</h1>
          <p className="text-slate-600 z-10 text-sm e">
            <span className="text-xs md:text-sm">From</span> {price}
          </p>
        </div>

        {/* user */}
      </div>
      {/* <div className="flex flex-row items-center space-x-2 pt-1">
        <p className="text-sm">4.5</p>

        <StarIcon className="h-4 w-4 text-yellow-400" />
      </div> */}
      <div
        onClick={() => {
          console.log("user", user);
          navigate(`/freelancer/profile/${user._id}`);
        }}
        className="flex absolute left-3  z-30 items-center space-x-2 pt-3 "
      >
        <div
          style={{
            backgroundImage: `url(${
              user?.profileImage ??
              "https://www.pngitem.com/pimgs/m/35-350426_profile-icon-png-default-profile-picture-png-transparent.png"
            })`,
          }}
          className="bg-cover relative bg-top h-8 w-8 rounded-full"
        ></div>
        <h1 className="text-white z-30  text-xs md:text-xs">{user?.name}</h1>
      </div>
    </div>
  );
}
