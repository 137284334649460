import HTMLReactParser from "html-react-parser";
import MainLayout from "layout/main/MainLayout";
import React from "react";

export default function EscrowPayment() {
  return (
    <MainLayout>
      <div className="grid md:grid-cols-2 gap-10 w-full bg-teal-700 p-5 md:p-20 text-white rounded-2xl">
        <div className="flex col-span-1 flex-col space-y-3 md:space-y-5 justify-center items-start p-5">
          <img
            src="https://www.svgrepo.com/show/429474/secure-gdpr-user.svg"
            className="w-8 h-8 md:w-20 md:h-20"
            alt="secure"
          />
          <h1 className="text-4xl md:text-5xl font-heading ">
            Payments on lock
          </h1>
          <p className="text-base">
            Hivvaru pay is a secure payment system that allows you to get paid
            for your work. We have made the process of getting a hire and
            earning as a freelancer as simple as possible.
          </p>
          <ul className="list-check list-inside text-base">
            <div className="flex flex-row space-x-3 items-center">
              {/* add check button */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <li className="py-1">No hidden fees</li>
            </div>
            <div className="flex flex-row space-x-3 items-center">
              {/* add check button */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <li className="py-1">Cashless payments, no cash in hand</li>
            </div>
            <div className="flex flex-row space-x-3 items-center">
              {/* add check button */}
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <li className="py-1">
                100% gurantee that you will get paid if you finish the work
              </li>
            </div>
          </ul>
        </div>
        <div
          style={{
            backgroundImage: `url(https://www.hrmanagementapp.com/wp-content/uploads/2019/06/freelancer-2.jpg)`,
          }}
          className="bg-cover bg-center bg-no-repeat col-span-1 h-64 md:h-96 rounded-xl"
        />
      </div>
      {/* more about payment */}
      {HTMLReactParser(
        `<p>Hivvaru&#39;s payment system is designed to be easy to use, secure, and transparent for both<br />
Freelancers and Clients.</p>

<p><br />
<strong>Booking.</strong><br />
After the client decides to book a service, the Freelancer and Client negotiate a Service Fee.<br />
Once confirmed, payment process is redirected to the Bank of Maldives secure payment<br />
gateway. The Client can purchase the Service using credit cards or debit cards used in the<br />
Maldives.</p>

<p><br />
<strong>Escrow.</strong><br />
Hivvaru holds the payment in escrow until the Freelancer completes the work and the Client<br />
approves it. When the payment is received by us, the client will be notified by email as a<br />
successful payment confirmation and an SMS is sent to the freelancer, so they could begin<br />
their service.</p>

<p><br />
<strong>Completed.</strong><br />
Once the Client approves the work and confirms that the Freelancer has completed the<br />
service, Hivvaru releases the payment to the Freelancer minus a Customer service fee of 5%<br />
of the total value of the service. The Service fee is transferred to the Freelancer via internet<br />
banking.</p>

<p><br />
To further ensure the security of payments, Hiwaru Team uses an anti-fraud system that<br />
monitors transactions for suspicious activity, and also offers dispute resolution services to help<br />
resolve conflicts between Clients and Freelancers.</p>`
      )}
    </MainLayout>
  );
}
