import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function ServiceCard({ data }) {
  let name = data?.name?.replace(/\s+/g, "-").toLowerCase();
  let navigate = useNavigate();
  return (
    <div
      onClick={() =>
        navigate(`/my-services/${name}`, {
          state: {
            data,
          },
        })
      }
      className=" relative rounded-xl hover:translate-x-1  transition-all duration-150 cursor-pointer"
    >
      {/* graident */}

      <div className="flex flex-col space-y-1 items-start text-white text-end p-5 justify-start h-full w-full bg-blend-saturation bg-clip-padding backdrop-filter duration-0 backdrop-blur-sm bg-opacity-50  bg-gray-50  transition-opacity  border border-slate-200 rounded-xl shadow ">
        <p className="text-xs text-teal-700">{data?.category?.name}</p>
        <h1 className=" z-30 font-bold text-start  text-xl text-slate-800 ">
          {data.name}
        </h1>
      </div>
    </div>
  );
}
