import React from "react";

export default function PrimaryButton({ heading, icon, onClick, disable }) {
  return (
    <button
      class={` ${
        disable
          ? "bg-slate-200 cursor-not-allowed"
          : "bg-gradient-to-br from-teal-900 to-teal-600 hover:bg-teal-800 cursor-pointer"
      }  justify-center rounded-full border border-transparent  px-6 py-2 text-xs md:text-xs font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:w-auto sm:text-sm`}
      onClick={disable ? "" : onClick}
    >
      {icon}
      {heading}
    </button>
  );
}
