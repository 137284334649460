import dayjs from "dayjs";

export default function DaySelect({
  weeksOfTheMonth,
  handleDateChange,
  handleDateClick,
  selectedDate,
  currentDay,
}) {
  return (
    <>
      <div className={"flex justify-between"}>
        {weeksOfTheMonth[0].map((day, index) => (
          <div
            className={
              "h-[30px] w-[30px] m-[2px] flex items-center justify-center text-[#9BA4B4]"
            }
            key={`week-day-${index}`}
          >
            {dayjs(day).format("dd")}
          </div>
        ))}
      </div>
      {weeksOfTheMonth.map((week, weekIndex) => (
        <div className={"flex"} key={`week-${weekIndex}`}>
          {week.map((day, dayIndex) => (
            <div
              onClick={() => {
                handleDateChange(day);
                // handleDateClick(true)
              }}
              key={`day-${dayIndex}`}
              className={
                selectedDate.clone().toDate().getMonth() !== day.getMonth()
                  ? `${"h-[30px] w-[30px] m-[4px] flex items-center justify-center rounded-md cursor-pointer hover:bg-orange-50"} text-[#DAE1E7]`
                  : dayjs(currentDay).isSame(day, "date")
                  ? `${"h-[30px] w-[30px] m-[4px] flex items-center justify-center rounded-md cursor-pointer hover:bg-orange-50"} text-[#E43F5A]`
                  : `${"h-[30px] w-[30px] m-[4px] flex items-center justify-center rounded-md cursor-pointer hover:bg-orange-50"} text-[#1B1B2F]`
              }
            >
              {day.getDate()}
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
