import { BrowserRouter, Outlet } from "react-router-dom";
import React from "react";
import { AnimatedRoutes } from "routes";
import customAxios from "middleware/customAxios";
import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "features/global/globalSlice";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

// You can also pass an optional settings object
// below listed default settings
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 40, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: true, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});

function App() {
  const [loading, setLoading] = React.useState(false);
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [decodedToken, setDecodedToken] = React.useState(null);
  const dispatch = useDispatch();
  const isFreelancer = useSelector((state) => state.global.isFreelancer);
  const user = useSelector((state) => state.global.user);

  React.useEffect(() => {
    // ste loading to false after 5 seconds
    if (token) {
      let decoded = jwtDecode(token);
      dispatch(setUser(decoded.user));
      setDecodedToken(decoded);
    }
    setLoading(true);

    customAxios
      .get("/admin/categories")
      .then((res) => {
        localStorage.setItem("categories", JSON.stringify(res));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
}

export default App;
