import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setFreelancerAuth } from "features/auth/AuthSlice";

export default function Package({ next }) {
  let freelancerAuth = JSON.parse(localStorage.getItem("freelancerAuth"));
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [errorMsg, setErrorMsg] = React.useState("");
  let [isError, setIsError] = React.useState(false);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "packageName",
            displayName: "Package Name",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "packageDescription",
            displayName: "Package Description",
            type: "editor",
            inputType: "text",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      packageName: freelancerAuth?.packageName ?? "",
      packageDescription: freelancerAuth?.packageDescription ?? "",
    },
    validationSchema: Yup.object().shape({
      packageName: Yup.string().required("Package Name is required"),
      packageDescription: Yup.string().required(
        "Please enter a package description"
      ),
    }),
    onSubmit: async (values, { setErrors }) => {
      console.log(values);
      //   Keep the previous values and add the new values
      values = { ...freelancerAuth, ...values };

      navigate(next ? next : "/auth/signup/seller/step/Rate");
      dispatch(setFreelancerAuth(values));

      // create a user
    },
  };

  return (
    <div className="flex flex-col -space-y-1">
      <FormGenerator
        ErrorMsg={errorMsg}
        isError={isError}
        height={""}
        buttonSubmit={{ title: "Proceed" }}
        formData={formData}
      />
    </div>
  );
}
