import AuthLayout from "layout/auth/AuthLayout";
import BankingInfo from "pages/auth/signup/customer/steps/banking-info/BankingInfo";
import CourseEnroll from "pages/auth/signup/customer/steps/documents/Documents";
import Profile from "pages/auth/signup/customer/steps/profile/Profile";
import React from "react";
import { useParams } from "react-router-dom";
import ProgressBar from "pages/auth/signup/components/ProgressBar";
import BasicInformation from "./basic-information/BasicInformation";
import Preview from "./preview/Preview";

export default function CustomerCreateStep() {
  let { step } = useParams();
  let [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    switch (step) {
      case "Basic Information":
        setProgress(0);
        break;
      case "Document Upload":
        setProgress(50);
        break;
      case "Banking Info":
        setProgress(70);
        break;
      case "Profile":
        setProgress(95);
        break;
      default:
        setProgress(0);
    }
  }, [step]);

  // Switch case statement to render the correct step
  const renderStep = () => {
    switch (step) {
      case "Basic Information":
        return (
          <AuthLayout
            heading={`Let's start with the basics`}
            subtitle={`Tell us about yourself`}
          >
            <BasicInformation
              nextUrl={"/create-customer/step/Document Upload"}
            />
          </AuthLayout>
        );

      case "Document Upload":
        return (
          <AuthLayout
            heading={`Great. Now upload your document`}
            subtitle={`We need to verify your identity. Please upload your document.`}
          >
            <CourseEnroll nextUrl={"/create-customer/step/Banking Info"} />
          </AuthLayout>
        );
      case "Banking Info":
        return (
          <AuthLayout
            heading={`Great. Now add your banking info`}
            subtitle={`In order to carry on any bank transactions we need your banking info.`}
          >
            <BankingInfo nextUrl={"/create-customer/step/Profile"} />
          </AuthLayout>
        );
      case "Profile":
        return (
          <AuthLayout
            heading={`Almost done. Setup your profile`}
            subtitle={`Fill in the details`}
          >
            <Profile nextUrl={"/create-customer/step/Preview Profile"} />
          </AuthLayout>
        );
      case "Preview Profile":
        return (
          <AuthLayout
            heading={`Your setup is ready. Preview it`}
            subtitle={`Click on the button below to submit. You can edit it later if you want to.`}
          >
            <Preview />
          </AuthLayout>
        );
      default:
        return <p>default</p>;
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col justify-between absolute z-50 top-0 bg-white">
      {renderStep()}
      <ProgressBar progress={progress} />
    </div>
  );
}
