import React from "react";
import Footer from "components/navigation/footer/Footer";
import NavBar from "components/navigation/NavBar";

export default function MainLayout({ children, breadCrumbs, title }) {
  return (
    <div className="relative bg-black leading-relaxed">
      <NavBar />
      <div className="flex items-start min-h-[calc(100vh-20px)] justify-center bg-white  ">
        <div className="pt-14 md:pt-20   flex flex-col space-y-7 md:space-y-10  w-full">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
}
