import React from "react";

export default function ProgressBar({ progress }) {
  return (
    <div className="w-full fixed bottom-0 h-2 bg-gray-200 rounded-full">
      <div
        style={{ width: `${progress}%` }}
        className="h-full bg-teal-500 p-1 rounded-full"
      ></div>
    </div>
  );
}
