import { Tab } from "@headlessui/react";
import NavBar from "components/navigation/NavBar";
import Widget from "components/widget/Widget";
import MainLayout from "layout/main/MainLayout";
import customAxios from "middleware/customAxios";
import moment from "moment";
import React from "react";
import AuthHeading from "ui/typography/heading/AuthHeading";
import Parse from "html-react-parser";
import SecondarButton from "ui/buttons/SecondaryButton";
import LoadingPrimaryButton from "ui/buttons/LoadingPrimaryButton";
import PrimaryButton from "ui/buttons/PrimaryButton";
import FreelancerNavBar from "components/navigation/FreelancerNavBar";
import FreelancerLayout from "layout/freelancer/FreelancerLayout";
import { itemRender } from "functions/ItemRender";
import ServiceCard from "./service-card/ServiceCard";
import { Outlet, useNavigate } from "react-router-dom";

export default function MyServices() {
  let [services, setServices] = React.useState([]);
  let navigate = useNavigate();

  React.useEffect(() => {
    customAxios.get("/freelancer/services").then((res) => {
      setServices(res);
    });
  }, []);

  return (
    <div className="relative">
      <FreelancerLayout>
        <div className="grid grid-cols-2  w-full">
          <div className="flex flex-col item space-y-1 md:space-y-3 text-left">
            <AuthHeading heading={"My Services"} />
            <p className="text-slate-600 text-sm  md:text-sm">
              {"Here you can find all your Services in this platform"}
            </p>
          </div>
          <div className="flex justify-end items-start">
            <PrimaryButton
              heading={"Add a New Service"}
              onClick={() => navigate("/my-services/add/Service Information")}
            />
          </div>
        </div>

        <div className="flex flex-col w-full">
          <div className="pt-2 grid grid-cols-1 md:grid-cols-3 gap-3">
            {services?.map((service, i) => (
              <ServiceCard data={service} />
            ))}
          </div>
        </div>
      </FreelancerLayout>
      <Outlet />
    </div>
  );
}
