import MainLayout from "layout/main/MainLayout";
import React, { Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthHeading from "ui/typography/heading/AuthHeading";
import { Combobox, Popover, Tab, Transition } from "@headlessui/react";
import { categories } from "data/categories";
import FilterCategories from "components/filter-categories/FilterCategories";
import Banner from "components/home/banner/Banner";
import { replaceDashes, replaceSpaces } from "functions/replaceDashes";
import Checkbox from "components/checkbox/Checkbox";
import ComboBoxComponent from "components/combo-box/ComboBoxComponent";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondarButton from "ui/buttons/SecondaryButton";
import WorkCard from "components/work-card/WorkCard";
import Ads from "components/advertisement/Ads";
import customAxios from "middleware/customAxios";
import {
  CheckIcon,
  ChevronDownIcon,
  HomeIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import CustomSwitch from "components/switch/Switch";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchResults() {
  let { query } = useParams();

  let navigate = useNavigate();
  const { SubCategory } = useParams();

  let [checked, setChecked] = React.useState(false);
  let [selectedPrice, setSelectedPrice] = React.useState({});
  let [sortBy, setSortBy] = React.useState({});
  let [services, setServices] = React.useState([]);
  let [selectedFilters, setSelectedFilters] = React.useState([]);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function fetchServices(urlSearchParams) {
    customAxios
      .get(
        "/content/services?" +
          (urlSearchParams ? urlSearchParams.toString() : "")
      )
      .then((res) => {
        setServices(res);
      });
  }

  React.useEffect(() => {
    scrollToTop();
  }, [services]);

  const sortByOptions = [
    {
      name: "Price: Low to High",
      value: "priceLowToHigh",
    },
    {
      name: "Price: High to Low",
      value: "priceHighToLow",
    },
    {
      name: "Newest First",
      value: "newest",
    },
    {
      name: "Oldest First",
      value: "oldest",
    },
  ];

  return (
    <MainLayout>
      <div className="space-y-6 relative bg-slate-50">
        <div className="flex flex-col px-5 md:px-20 space-y-3 md:flex-row md:justify-between md:items-center w-full 0">
          <div className="flex flex-col space-y-2 md:space-y-4 pt-10">
            {/* Bread Crumb */}
            <div className="flex items-center space-x-2">
              <p
                onClick={() => navigate("/")}
                className="text-sm font-medium hover:text-teal-500 text-gray-500 hover:cursor-pointer"
              >
                <HomeIcon className="h-5 w-5" />
              </p>
              <p className="text-sm font-medium text-gray-500">/</p>
              <p className="text-sm font-medium text-gray-500">Search</p>
              {/* <code>
            <pre>{JSON.stringify(category.name.name)}</pre>
          </code> */}
            </div>
            {/* Heading */}
            <AuthHeading
              heading={`Search Results for "${query}"`}
              subtitle={`We found ${services.length} services for you`}
            />
          </div>
        </div>

        {/* filter options */}
        <div className="md:px-20 px-5 mt-0 pb-5 md:pb-10  border-b justify-between md:flex grid grid-cols-1 gap-5 items-center flex-row  border-slate-300">
          {/* Budget */}
          <Popover className="relative mt-0 hidden md:block b">
            {({ open, close }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? "text-teal-500" : "text-black",
                    "group bg-white p-2 border border-slate-200 px-5 text-sm md:text-base rounded-md inline-flex items-center text-black font-medium hover:text-teal-500 focus:outline-none  focus:ring-offset-gray-100 "
                  )}
                >
                  <span>Budget</span>
                  <ChevronDownIcon
                    className={classNames(
                      open
                        ? "text-teal-500 rotate-180 transition-all duration-150"
                        : "text-gray-500 rotate-0 transition-all duration-150",
                      "ml-2 h-5 w-5 group-hover:text-teal-500"
                    )}
                  />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute top-50 -left-10 z-50 mt-3 transform w-screen max-w-md lg:max-w-sm sm:px-0 lg:px-8">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="bg-white p-3 space-y-3">
                        <div className="grid grid-cols-2 gap-3">
                          <div>
                            <label className="text-sm font-medium text-gray-500">
                              Min Price
                            </label>
                            <input
                              type="number"
                              className="w-full border border-gray-300 rounded-md p-2"
                              value={selectedPrice?.value?.min}
                              onChange={(e) => {
                                setSelectedPrice({
                                  ...selectedPrice,
                                  value: {
                                    ...selectedPrice.value,
                                    min: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                          <div>
                            <label className="text-sm font-medium text-gray-500">
                              Max Price
                            </label>
                            <input
                              type="number"
                              className="w-full border border-gray-300 rounded-md p-2"
                              value={selectedPrice?.value?.max}
                              onChange={(e) => {
                                setSelectedPrice({
                                  ...selectedPrice,
                                  value: {
                                    ...selectedPrice.value,
                                    max: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                          <PrimaryButton
                            onClick={() => {
                              let urlSearchParams = new URLSearchParams(
                                window.location.search
                              );

                              urlSearchParams.set(
                                "Price",
                                JSON.stringify(selectedPrice.value)
                              );
                              fetchServices(urlSearchParams);
                              close();
                            }}
                            heading={"Apply"}
                          />
                          <SecondarButton
                            onClick={() => {
                              let urlSearchParams = new URLSearchParams(
                                window.location.search
                              );
                              setSelectedPrice({
                                value: {
                                  min: "",
                                  max: "",
                                },
                              });

                              urlSearchParams.delete("Price");
                              fetchServices(urlSearchParams);
                              close();
                            }}
                            heading={"Clear"}
                          />
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
          {/* Check boxes */}
          <div className="flex flex-row space-x-5 pb-2 text-slate-500 text-xs">
            <div className="flex flex-row justify-center items-center space-x-2">
              <CustomSwitch
                enabled={
                  selectedFilters?.includes("Handpicked Sellers") ? true : false
                }
                setEnabled={(e) => {
                  if (e) {
                    setSelectedFilters([
                      ...selectedFilters,
                      "Handpicked Sellers",
                    ]);
                  } else {
                    setSelectedFilters(
                      selectedFilters.filter(
                        (item) => item !== "Handpicked Sellers"
                      )
                    );
                  }
                }}
              />
              <div>Handpicked Sellers</div>
            </div>
            <div className="flex flex-row space-x-5 text-slate-500">
              <div className="flex flex-row justify-center items-center space-x-2">
                <CustomSwitch
                  enabled={
                    selectedFilters?.includes("Pro Sellers") ? true : false
                  }
                  setEnabled={(e) => {
                    if (e) {
                      setSelectedFilters([...selectedFilters, "Pro Sellers"]);
                    } else {
                      setSelectedFilters(
                        selectedFilters.filter((item) => item !== "Pro Sellers")
                      );
                    }
                  }}
                />
                <div>Pro Sellers</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* content */}
      <div className="px-5 md:px-20">
        <div className="md:flex flex-row grid grid-cols-1 gap-2 justify-between items-center">
          {/* no of results */}
          <div className="flex flex-row items-center space-x-1">
            <p className="text-sm font-medium text-gray-500">
              {services?.length} results
            </p>
            <p className="text-sm font-medium text-gray-500">
              services available
            </p>
          </div>
          {/* Sort By */}
          <Popover className="relative mt-0 b">
            {({ open, close }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? "text-teal-500" : "text-black",
                    "group bg-white p-0 md:p-2  text-base rounded-md inline-flex items-center text-black font-medium hover:text-teal-500 focus:outline-none  focus:ring-offset-gray-100 "
                  )}
                >
                  <span className="text-sm font-bold">
                    Sort by :{" "}
                    <span className="font-base font-normal">{sortBy.name}</span>
                  </span>
                  <ChevronDownIcon
                    className={classNames(
                      open
                        ? "text-teal-500 rotate-180 transition-all duration-150"
                        : "text-gray-500 rotate-0 transition-all duration-150",
                      "ml-2 h-5 w-5 group-hover:text-teal-500"
                    )}
                  />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute top-50 -right-3 md:-right-10 z-50 mt-3 transform w-screen max-w-sm sm:px-0 lg:px-8">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="bg-white p-3 space-y-1">
                        {sortByOptions.map((item, index) => {
                          return (
                            <div
                              onClick={() => {
                                setSortBy(item);
                                let urlSearchParams = new URLSearchParams(
                                  window.location.search
                                );
                                urlSearchParams.set("SortBy", item.value);
                                fetchServices(urlSearchParams);
                                close();
                              }}
                              key={index}
                              className="flex flex-row justify-start space-x-5 cursor-pointer items-center p-2 hover:bg-slate-50 rounded-md"
                            >
                              {sortBy.value === item.value && (
                                <CheckIcon className="h-5 w-5 text-teal-400" />
                              )}

                              <p className="text-sm font-medium text-gray-500">
                                {item.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      </div>
      <div className="px-5 md:px-20 pb-10 ">
        <div className="grid  grid-cols-1  md:grid-cols-2 2xl:grid-cols-5 xl:grid-cols-4 gap-5">
          {services?.map((item, index) => {
            return (
              <WorkCard
                key={item.id}
                id={item.id}
                path={"/Services/View/" + item.name}
                category={item.category?.name}
                image={
                  item.packages[0]?.images
                    ? item.packages[0]?.images[0]?.value
                    : "null"
                }
                title={item.name}
                price={item.packages[0]?.price}
                user={item.freelancer}
              />
            );
          })}
        </div>
      </div>
    </MainLayout>
  );
}
