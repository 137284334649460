import MainLayout from "layout/main/MainLayout";
import React from "react";
import SecondarButton from "ui/buttons/SecondaryButton";
import AuthHeading from "ui/typography/heading/AuthHeading";

let contact = [
  {
    id: 1,
    title: "Email",
    value: "team@hivvaru.com",
    icon: "https://www.svgrepo.com/show/484494/mail-part-2.svg",
    buttonTitle: "Send Email",
    href: "mailto:info@hivvaru.com",
  },
  {
    id: 2,
    title: "Phone",
    value: "+960 9993529",
    icon: "https://www.svgrepo.com/show/258848/phone-call-telephone.svg",
    buttonTitle: "Call Us",
    href: "tel:+9609993529",
  },
  {
    id: 3,
    title: "Address",
    value: `Alivelun Pvt Ltd, Male' City, Maldives`,
    icon: "https://www.svgrepo.com/show/375834/location.svg",
    buttonTitle: "Get Directions",
    href: "https://goo.gl/maps/8Z5Z1Z1Z1Z1Z1Z1Z9",
  },
];

export default function ContactUs() {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <MainLayout>
      <div className="flex flex-col items-start space-y-10 px-5 md:px-20 p-5 md:py-10 min-h-[90vh] ">
        <div className="flex col-span-4 flex-col pt-10 space-y-3 md:space-y-3 justify-start items-start">
          <h1 className="text-3xl text-black md:text-4xl font-bold ">
            Get in Touch with us
          </h1>
          <p className="text-sm text-slate-500 md:text-base">
            You can contact us through the following channels and we will get
            back to you as soon as possible
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 relative gap-3 w-full pt-5 md:pt-5">
          {contact.map((contact) => (
            <div className="flex flex-col items-center justify-between space-y-5 bg-slate-100 p-5 py-10 rounded-xl">
              <div className="bg-white shadow-md p-5 rounded-full">
                <div
                  style={{
                    backgroundImage: `url(${contact.icon})`,
                  }}
                  className="w-10 h-10 bg-cover bg-center bg-no-repeat "
                />
              </div>
              <h1 className="text-center  font-medium text-base md:text-base max-w-sm w-2/3 te">
                {contact.value}
              </h1>

              <a
                referrerPolicy="_blank"
                className="bg-secondary"
                href={contact.href}
              >
                <SecondarButton heading={contact.buttonTitle} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </MainLayout>
  );
}
