import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as LogoCircle } from "assets/logo/logo-circle.svg";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronRightIcon, MenuIcon, SearchIcon } from "@heroicons/react/solid";
import { ReactComponent as Logo } from "assets/logo/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIsFreelancer } from "features/global/globalSlice";
import ResetPassword from "pages/auth/reset-password/ResetPassword";
import PrimaryButton from "ui/buttons/PrimaryButton";

let footerLinks = [
  {
    name: "Why Hivvaru?",
    href: "/why-hivvaru?",
  },
  {
    name: "How it works",
    href: "/how-it-works",
  },
  {
    name: "FAQ",
    href: "/faq",
  },
  {
    name: "Contact Us",
    href: "/contact-us",
  },
  {
    name: "About Us",
    href: "/about-us",
  },
  {
    name: "Privacy Policy",
    href: "/privacy-policy",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MobileNavBar() {
  let dispatch = useDispatch();
  let [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  let categories = JSON.parse(localStorage.getItem("categories"));
  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  let user = useSelector((state) => state.global.user);
  let isFreelancer = useSelector((state) => state.global.isFreelancer);

  let switchAccountRender = useCallback(() => {
    // switch case statements if userType array includes

    let userTypes = user?.userType;

    console.log(userTypes);

    if (userTypes?.includes("freelancer") && userTypes?.includes("client")) {
      // switch account
      return (
        <button
          onClick={() => {
            dispatch(setIsFreelancer(!isFreelancer));
            navigate(`/`);
          }}
          to="/switch-account"
        >
          <div className="flex flex-row items-center space-x-2">
            <p className="text-sm">Switch Account</p>
          </div>
        </button>
      );
    } else if (userTypes?.includes("client")) {
      console.log("client");
    } else if (userTypes?.includes("freelancer")) {
      console.log("freelancer");
    } else if (userTypes?.includes("admin")) {
      console.log("admin");
    } else {
      console.log("nah");
    }
  }, [user]);

  return (
    <div className="p-3 bg-white border  z-50 w-full flex justify-between items-center">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button className="text-black focus:outline-none">
              {open ? (
                <XIcon className="h-6 w-6" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="absolute z-10 top-12 -left-3.5 w-screen inset-x-0 transition transform origin-top-right md:hidden">
                <div className="shadow-md     bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pb-10 pt-4 h-[calc(100vh-60px)] overflow-auto flex flex-col pb-10 items-center justify-between">
                    {/* Categories */}
                    <div className="w-full space-y-1 pb-10 ">
                      <h1
                        className="text-lg pb-2 font-bold
                  "
                      >
                        Categories
                      </h1>
                      {categories?.map((category) => {
                        return (
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="flex justify-between w-full  py-2 text-sm font-medium text-left text-black focus:bg-white rounded-lg   f">
                                  <span>{category.name}</span>
                                  <ChevronUpIcon
                                    className={`${
                                      open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-teal-500 `}
                                  />
                                </Disclosure.Button>
                                <Transition
                                  show={open}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Disclosure.Panel
                                    static
                                    className="px-4 pt-2 pb-4 text-sm text-gray-500"
                                  >
                                    <div className="grid grid-cols-1 gap-5 w-full">
                                      {category?.subCategories?.map((sc) => (
                                        <div
                                          onClick={() => {
                                            navigate(`/Services/${sc.name}`, {
                                              state: {
                                                id: sc?.id,
                                                category: {
                                                  id: category.id,
                                                  name: category,
                                                },
                                              },
                                            });
                                          }}
                                          className="text-xs text-start font-medium text-gray-900 hover:text-gray-700"
                                        >
                                          {sc.name}
                                        </div>
                                      ))}
                                    </div>
                                  </Disclosure.Panel>
                                </Transition>
                              </>
                            )}
                          </Disclosure>
                        );
                      })}
                    </div>
                    {/* links */}
                    <div className="grid grid-cols-3 gap-3 w-full">
                      {footerLinks.map((link) => (
                        <Link
                          to={link.href}
                          className="text-xs text-center font-medium text-gray-900 hover:text-gray-700"
                        >
                          {link.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <div>
        <Link
          to={"/"}
          className="mr-0 text-black flex flex-row space-x-1 items-center"
        >
          <LogoCircle className="h-10" />
          <h1 className="text-xl font-bold">Hivvaru</h1>
        </Link>
      </div>
      {token ? (
        <Popover>
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  // open ? "text-secondary" : "text-black",
                  "group  hidden:md-block rounded-md inline-flex items-center text-base  font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                )}
              >
                <img
                  src={
                    isFreelancer
                      ? user?.userInformation?.freelancer?.profileImage
                      : user?.userInformation?.clientCustomer?.profileImage
                  }
                  alt="avatar"
                  className="h-10 w-10 rounded-full"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="fixed top-[65px] right-0 w-full ">
                  <div className="md:block rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="re grid gap-y-6 bg-white p-10 min-w-[180px]  sm:gap-8 sm:p-8">
                      <div className="flex flex-col justify-between items-start space-y-4">
                        <div className="flex flex-row justify-start items-center space-x-3">
                          <img
                            src={
                              isFreelancer
                                ? user?.userInformation?.freelancer
                                    ?.profileImage
                                : user?.userInformation?.clientCustomer
                                    ?.profileImage
                            }
                            alt="avatar"
                            className="h-14 w-14 rounded-full"
                          />
                          {/* <code>{JSON.stringify(user)}</code> */}
                          <div className="flex flex-col space-y-1 justify-start text-start">
                            <h1 className=" font-semibold">
                              {isFreelancer
                                ? user?.userInformation?.freelancer?.name
                                : user?.userInformation?.clientCustomer?.name}
                            </h1>
                            {switchAccountRender()}
                          </div>
                        </div>

                        <div className="flex flex-col space-y-2  justify-start text-start">
                          <Link
                            to="/My Profile"
                            className="text-sm text-gray-500 hover:text-gray-700"
                          >
                            Manage Profile
                          </Link>

                          {!isFreelancer && (
                            <Link
                              to="/my-hires"
                              className="text-sm text-gray-500 hover:text-gray-700"
                            >
                              My Job Hires
                            </Link>
                          )}

                          {/* {!isFreelancer && (
                                  <Link
                                    to="/account"
                                    className="text-sm text-gray-500 hover:text-gray-700"
                                  >
                                    Saved Services
                                  </Link>
                                )} */}

                          <div
                            onClick={() => setResetPasswordModalOpen(true)}
                            className="text-sm text-gray-500 hover:text-gray-700"
                          >
                            Reset Password
                          </div>
                        </div>

                        {/* Logout */}
                        <PrimaryButton
                          onClick={() => {
                            localStorage.removeItem("token");
                            // clear local storage
                            localStorage.clear();
                            navigate("/auth/login");
                          }}
                          heading={"Logout"}
                        />
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      ) : (
        <Link to="/auth/login" className="text-sm pr-4">
          Login
        </Link>
      )}
      {resetPasswordModalOpen && <ResetPassword />}
    </div>
  );
}
