import React from "react";

export default function SecondarButton({ heading, icon, onClick }) {
  return (
    <button
      type="button"
      class=" inline-flex  max-h-10 justify-center rounded-full border border-gray-300 bg-white px-4 py-2 text-xs md:text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2  sm:w-auto sm:text-sm"
      onClick={onClick}
    >
      {icon}
      {heading}
    </button>
  );
}
