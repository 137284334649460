import React from "react";
import NotificationModal from "components/notification-modal/NotificationModal";
import { XIcon } from "@heroicons/react/outline";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import customAxios from "middleware/customAxios";
import { set } from "date-fns";
import { useNavigate } from "react-router-dom";

export default function UpdateBio({ show, setShow, data }) {
  let navigate = useNavigate();
  let [errorMsg, setErrorMsg] = React.useState("");
  let [isError, setIsError] = React.useState(false);
  let user = useSelector((state) => state.global.user);

  let [metadata, setMetadata] = React.useState(data?.metadata ?? []);
  let freelancerAuth = useSelector((state) => state.auth.freelancer);
  let [packages, setPackages] = React.useState([]);
  let [index, setIndex] = React.useState(null);

  React.useEffect(() => {
    setPackages(freelancerAuth?.packages);
    setIndex(
      freelancerAuth?.packages?.findIndex((x) => x.id == data?.id) ?? null
    );
    setMetadata(data?.metadata ?? []);
  }, [data, freelancerAuth]);
  // // update the package in freelancer auth

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "bio",
            displayName: "Bio",
            type: "editor",
            data: data ?? "",
            inputType: "text",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      bio: data ?? "",
    },
    validationSchema: Yup.object().shape({
      bio: Yup.string().required("Bio is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      let body = {
        name: user?.userInformation?.freelancer?.name,
        email: user?.userInformation?.freelancer?.email,
        bio: values.bio,
        address: user?.userInformation?.freelancer?.address,
        city: user?.userInformation?.freelancer?.city,
        state: user?.userInformation?.freelancer?.state,
        documentNumber: user?.userInformation?.freelancer?.documentNumber,
        documentUrl: user?.userInformation?.freelancer?.documentUrl,
        profileImage: user?.userInformation?.freelancer?.profileImage,
        bankName: user?.userInformation?.freelancer?.bankName,
        accountNumber: user?.userInformation?.freelancer?.accountNumber,
        accountName: user?.userInformation?.freelancer?.accountName,
      };

      customAxios
        .put("/freelancer/profile", body)
        .then((res) => {
          let urlParams = new URLSearchParams(window.location.search);
          urlParams.append("refresh", true);
          navigate("/My Profile" + "?" + urlParams.toString());
          setShow(false);
        })
        .catch((err) => {
          console.log(err);
        });
      // create a user
    },
  };

  return (
    <NotificationModal
      showX={true}
      show={show}
      setShowModal={setShow}
      heading={"Update Bio"}
      subHeading={"Please update the following"}
    >
      <FormGenerator
        ErrorMsg={errorMsg}
        isError={isError}
        height={""}
        buttonSubmit={{ title: "Submit" }}
        formData={formData}
      />
    </NotificationModal>
  );
}
