import axios from "axios";

export const customAxios = axios.create({
  // baseURL: "http://localhost:4000/api/v1",
  baseURL: "https://api.hivvaru.com/api/v1",
});

// Add a request interceptor

customAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.append("redirectUrl", window.location.pathname);
      window.location.href = "/auth/login?" + urlParams.toString();
    }

    if (error.response.status === 400) {
      return Promise.reject(error.response);
    }

    if (error.response.status === 445) {
      return Promise.reject(error.response);
    }

    return Promise.reject(error.response);
  }
);

export default customAxios;
