import { Popover, Transition } from "@headlessui/react";
import {
  ArrowCircleRightIcon,
  MenuIcon,
  SearchIcon,
  XIcon,
} from "@heroicons/react/solid";
import React from "react";
import { motion } from "framer-motion";
import { Fragment, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import MobileMenu from "./MobileMenu";
import { ReactComponent as LogoCircle } from "assets/logo/logo-circle.svg";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsFreelancer } from "features/global/globalSlice";
import { ChevronDownIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Framer Motion for icon animation
const Container = styled(motion.div)`
  position: relative;
  max-width: 250px;
  cursor: pointer;
`;

export default function FreelancerNavBar() {
  let isFreelancer = useSelector((state) => state.global.isFreelancer);
  let dispatch = useDispatch();
  let token = localStorage.getItem("token");
  let [loggedIn, setLoggedIn] = useState({});
  let navigate = useNavigate();
  let user = useSelector((state) => state.global.user);
  let pathname = window.location.pathname;

  React.useEffect(() => {
    if (token) {
      setLoggedIn(true);
    }
  }, [token]);

  return (
    <div className="fixed z-20 w-full  bg-slate-100 text-slate-800 border-b border-slate-200  flex justify-around px-3 ">
      <div className="flex w-full max-w-7xl  items-center justify-between  py-3 ">
        <Link
          to="/"
          className="mr-3 flex flex-row space-x-2 justify-start items-center "
        >
          <LogoCircle className="h-10" />
          <div className="flex flex-row items-center justify-start space-x-3">
            <h1 className="font-bold text-base ss ">Freelancer Portal</h1>
          </div>
        </Link>

        <div className="hidden md:flex pl-10   flex-row justify-start space-x-5 text-sm font-medium  ">
          <Link
            className={` ${
              pathname === "/" ? "text-teal-500 " : ""
            } hover:cursor-pointer hover:text-teal-500`}
            to="/"
          >
            Dashboard
          </Link>
          <Link
            className={` ${
              pathname.includes("/my-jobs") ? "text-teal-500 " : ""
            } hover:cursor-pointer hover:text-teal-500`}
            to="/my-jobs"
          >
            My Jobs
          </Link>
          <Link
            className={` ${
              pathname.includes("/my-services") ? "text-teal-500 " : ""
            } hover:cursor-pointer hover:text-teal-500`}
            to="/my-services"
          >
            My Services
          </Link>
          <Link
            className={` ${
              pathname.includes("/my-earnings") ? "text-teal-500 " : ""
            } hover:cursor-pointer hover:text-teal-500`}
            to="/my-earnings"
          >
            My Earnings
          </Link>
        </div>

        <div className="-my-2 space-x-3 flex flex-row items-center ">
          {/* Search */}
          {!loggedIn ? (
            <PrimaryButton
              onClick={() => navigate("/auth/signup")}
              heading={"Sign Up"}
            />
          ) : (
            <div className="flex flex-row space-x-5">
              <div className="flex flex-row space-x-3">
                {token && (
                  <Popover>
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            // open ? "text-secondary" : "text-black",
                            "group  hidden md:block rounded-md md:inline-flex items-center text-base  font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                          )}
                        >
                          <div className="flex flex-row space-x-3">
                            <img
                              src={
                                user?.userInformation?.freelancer?.profileImage
                              }
                              alt="avatar"
                              className="h-10 w-10 rounded-full object-cover object-center"
                            />

                            <div className=" hidden md:block  flex-col space-y-0">
                              <h1 className="text-sm font-bold">
                                {user?.userInformation?.freelancer?.name}
                              </h1>
                              <p className="text-xs text-gray-400">
                                {user?.userInformation?.freelancer?.email}
                              </p>
                            </div>
                          </div>
                          <ChevronDownIcon
                            className={`ml-2 h-5 w-5 `}
                            aria-hidden="true"
                          />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="fixed top-[73px] right-20 ">
                            <div className="md:block rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="re grid gap-y-6 bg-white min-w-[180px]  sm:gap-8 sm:p-8">
                                <div className="flex flex-col justify-between items-start space-y-4">
                                  <div className="flex flex-row justify-start items-center space-x-3">
                                    <img
                                      src={
                                        isFreelancer
                                          ? user?.userInformation?.freelancer
                                              ?.profileImage
                                          : user?.userInformation
                                              ?.clientCustomer?.profileImage
                                      }
                                      alt="avatar"
                                      className="h-14 w-14 rounded-full"
                                    />
                                    {/* <code>{JSON.stringify(user)}</code> */}
                                    <div className="flex flex-col space-y-1 justify-start text-start">
                                      <h1 className=" font-semibold">
                                        {isFreelancer
                                          ? user?.userInformation?.freelancer
                                              ?.name
                                          : user?.userInformation
                                              ?.clientCustomer?.name}
                                      </h1>
                                    </div>
                                  </div>

                                  <div className="flex flex-col space-y-2  justify-start text-start">
                                    <Link
                                      to="/My Profile"
                                      className="text-sm text-gray-500 hover:text-gray-700"
                                    >
                                      Manage Profile
                                    </Link>

                                    {!isFreelancer && (
                                      <Link
                                        to="/my-hires"
                                        className="text-sm text-gray-500 hover:text-gray-700"
                                      >
                                        My Job Hires
                                      </Link>
                                    )}

                                    {/* {!isFreelancer && (
                                  <Link
                                    to="/account"
                                    className="text-sm text-gray-500 hover:text-gray-700"
                                  >
                                    Saved Services
                                  </Link>
                                )} */}

                                    {/* <div
                                      onClick={() =>
                                        setResetPasswordModalOpen(true)
                                      }
                                      className="text-sm text-gray-500 hover:text-gray-700"
                                    >
                                      Reset Password
                                    </div> */}
                                  </div>

                                  {/* Logout */}
                                  <PrimaryButton
                                    onClick={() => {
                                      localStorage.removeItem("token");
                                      // clear local storage
                                      localStorage.clear();
                                      navigate("/auth/login");
                                    }}
                                    heading={"Logout"}
                                  />
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                )}
              </div>

              {/* <PrimaryButton
                heading={"Log out"}
                onClick={() => {
                  // clear cache
                  localStorage.removeItem("user");
                  localStorage.clear();
                  localStorage.setItem("isFreelancer", false);
                  localStorage.removeItem("token");
                  navigate("/auth/login");
                }}
              /> */}
            </div>
          )}

          {/* Menu */}
          <Popover.Group className="bg-slate-100 ml-2 rounded-md p-2 md:hidden inline-flex items-center justify-center  focus:outline-none">
            <Popover>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      // open ? "text-secondary" : "text-black",
                      "group  rounded-md inline-flex items-center text-base  font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                    )}
                  >
                    {open && (
                      <>
                        <XIcon
                          className={classNames(
                            // open ? "text-secondary" : "text-black",
                            "h-8 w-8 mt-1 group-hover:text-secondary"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}

                    {!open && (
                      <>
                        <MenuIcon
                          className={classNames(
                            // open ? "text-secondary" : "text-black",
                            "h-8 w-8 mt-1 group-hover:text-secondary"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    //   onMouseEnter={() => setIsShowingOS(true)}
                    //   onMouseLeave={() => setIsShowingOS(false)}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-x-full"
                    enterTo="opacity-100 translate-x-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="opacity-0 translate-x-full"
                    class="bg-black/5 absolute z-10  right-0 top-full inset-x-0 transform shadow-lg "
                  >
                    <Popover.Panel className=" absolute z-20 w-1/2 right-0 top-full inset-x-0 transform shadow-lg h-screen bg-white">
                      <div className="p-5 bg-white  ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="re grid  bg-white min-w-[180px]  sm:gap-8 sm:p-2">
                          <div className="flex flex-col bg-slate-100 p-4 rounded-lg justify-between items-start space-y-4">
                            <div className="flex flex-row  justify-start items-center space-x-3">
                              <img
                                src={
                                  isFreelancer
                                    ? user?.userInformation?.freelancer
                                        ?.profileImage
                                    : user?.userInformation?.clientCustomer
                                        ?.profileImage
                                }
                                alt="avatar"
                                className="h-14 w-14 rounded-full object-cover object-center"
                              />
                              {/* <code>{JSON.stringify(user)}</code> */}
                              <div className="flex flex-col  space-y-2 justify-start text-start">
                                <h1 className=" font-semibold">
                                  {isFreelancer
                                    ? user?.userInformation?.freelancer?.name
                                    : user?.userInformation?.clientCustomer
                                        ?.name}
                                </h1>
                                <div className="flex flex-row justify-start items-center space-x-3">
                                  <Link
                                    to="/My Profile"
                                    className="text-sm text-gray-500 hover:text-gray-700"
                                  >
                                    Manage Profile
                                  </Link>
                                  <PrimaryButton
                                    onClick={() => {
                                      localStorage.removeItem("token");
                                      // clear local storage
                                      localStorage.clear();
                                      navigate("/auth/login");
                                    }}
                                    heading={"Logout"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-col space-y-2  justify-start text-start">
                            {/* {!isFreelancer && (
                                  <Link
                                    to="/account"
                                    className="text-sm text-gray-500 hover:text-gray-700"
                                  >
                                    Saved Services
                                  </Link>
                                )} */}

                            {/* <div
                                      onClick={() =>
                                        setResetPasswordModalOpen(true)
                                      }
                                      className="text-sm text-gray-500 hover:text-gray-700"
                                    >
                                      Reset Password
                                    </div> */}
                          </div>

                          {/* Logout */}
                        </div>
                      </div>
                      <div className="bg-white flex flex-col p-5 space-y-5 justify-start items-start">
                        <Link to="/my-jobs" className="">
                          <p className="text-sm font-medium text-slate-800 hover:text-teal-500">
                            My Jobs
                          </p>
                        </Link>
                        <Link to="/my-services" className="">
                          <p className="text-sm font-medium text-slate-800 hover:text-teal-500">
                            My services
                          </p>
                        </Link>
                        <Link to="/my-earnings" className="">
                          <p className="text-sm font-medium text-slate-800 hover:text-teal-500">
                            My Earnings
                          </p>
                        </Link>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
        </div>
      </div>
    </div>
  );
}
