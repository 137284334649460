import React from "react";
import { Link } from "react-router-dom";

export default function MobileMenu() {
  let [freelancer, setFreelancer] = React.useState(true);
  let categories = JSON.parse(localStorage.getItem("categories"));
  return (
    <>
      {freelancer ? (
        <div>
          <div className="flex flex-col space-y-3 p-5 h-[94vh] text-left">
            <h1 className="text-xl font-medium w-full border-b pb-2">
              Categories
            </h1>
            {categories.map((category) => (
              <Link
                to={"/Categories/" + category.name}
                className="flex flex-col space-y-3 text-left font-medium"
              >
                <p>{category.name}</p>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <p>Client</p>
      )}
    </>
  );
}
