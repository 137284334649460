import { PencilIcon } from "@heroicons/react/outline";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Widget({ heading, subHeading, children, component }) {
  let navigate = useNavigate();
  return (
    <div className="border relative border-slate-200 p-3 md:p-5 bg-blend-saturation bg-clip-padding backdrop-filter duration-0 backdrop-blur-sm bg-opacity-80  bg-white  transition-opacity  text-slate-700 rounded-md">
      <div className="flex pb-4 flex-row justify-between items-center  border-b border-slate-200 w-full">
        <div className="flex flex-col space-y-0  ">
          <h1 className=" text-base md:text-base font-bold">{heading}</h1>
          <h2 className="text-xs md:text-xs text-slate-600">{subHeading}</h2>
        </div>
        <div>{component}</div>
      </div>
      <div className="grid  grid-cols-1 md:grid-cols-4 gap-3 md:gap-6 pt-4  align-top">
        {children}
      </div>
    </div>
  );
}
