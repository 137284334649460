import React from "react";
import { ReactComponent as LogoCircle } from "assets/logo/logo-circle.svg";
import { Link, Outlet } from "react-router-dom";
import AuthHeading from "ui/typography/heading/AuthHeading";

export default function AuthLayout({ children, heading, subtitle }) {
  return (
    <div className="relative">
      <div className="fixed z-20 w-full  bg-white  flex justify-around px-3 ">
        <div className="flex w-full  items-center justify-between border-b py-2 border-slate-200">
          <Link to="/" className="mr-0">
            <LogoCircle className="h-10" />
          </Link>
        </div>
      </div>

      <div className="flex items-start justify-center  h-screen ">
        <div className="pt-20 p-5 flex flex-col space-y-3 max-w-6xl w-full">
          <AuthHeading heading={heading} />
          <p className="text-slate-600 text-sm md:text-base">{subtitle}</p>
          <div className="flex flex-col w-full">
            <div className="pt-6 space-y-3 justify-between flex flex-col h-full">
              {children}
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
}
