import Parse from "html-react-parser";

export function itemRender(key, element) {
  let elementRenderer = (element) => {
    switch (typeof key) {
      case "string":
        // if contains html

        // if profileImage
        if (key === "Profile Image") {
          return (
            <div className="flex flex-row space-x-2">
              <img
                className="w-40 h-40 object-cover rounded-full"
                src={element}
                alt="profile"
              />
            </div>
          );
        }

        if (key === "Password") {
          return "********";
        }

        // if documentUrl
        if (key === "Document") {
          return (
            <a
              className="hover:text-teal-500 overflow-hidden  text-slate-600"
              href={element}
              target="_blank"
            >
              <h2 className="text-xs overflow-hidden">{element}</h2>
            </a>
          );
        }
        return element;
      case "object":
        return null;
      case "number":
        return element;
      default:
        return key;
    }
  };

  return (
    <div className="flex flex-row justify-start items-start self-start">
      <div className="flex flex-col justify-start items-start space-y-0.5">
        <h1 className="text-[10px] font-medium">{key}</h1>
        <h2 className="text-xs font-mediumupd text-slate-600">
          {elementRenderer(element)}
        </h2>
      </div>
    </div>
  );
}
