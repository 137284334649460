import { Tab, Transition } from "@headlessui/react";
import MainLayout from "layout/main/MainLayout";
import React from "react";
import SecondarButton from "ui/buttons/SecondaryButton";
import AuthHeading from "ui/typography/heading/AuthHeading";

let freelancerTabPanel = [
  {
    id: 1,
    title: "Set up your freelancer profile",
    description: "Create your profile and add your skills and experience.",
    image:
      "https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/graphics/Screenshot+2023-02-28+at+17.31.55.png",
    buttonTitle: "Sign up Now!",
    href: "/auth/signup/seller",
  },
  {
    id: 2,
    title: "Get hired for a job",
    description: "Get hired by clients and start earning.",
    image:
      "https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/graphics/Screenshot+2023-02-28+at+17.30.39.png",
    buttonTitle: "View Hires",
    href: "/my-hires",
  },
  {
    id: 3,
    title: "Create an offer",
    description: "Get hired by clients and start earning.",
    image:
      "https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/graphics/Screenshot+2023-02-28+at+17.31.10.png",
    buttonTitle: "Create an Offer",
    href: "/my-hires",
  },
  {
    id: 4,
    title: "Get paid upon finishing the job",
    description: "Get paid by clients through our secure payment system.",
    image:
      "https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/graphics/Screenshot+2023-02-28+at+17.32.58.png",
    buttonTitle: "View Payments",
    href: "/my-earnings",
  },
];

let clientTabPanel = [
  {
    id: 1,
    title: "Set up your client profile",
    description: "Create your profile and add your skills and experience.",
    image:
      "https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/graphics/Screenshot+2023-02-28+at+17.31.55.png",
    buttonTitle: "Sign up Now!",
    href: "/auth/signup/buyer",
  },
];

export default function HowItWorks() {
  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <MainLayout>
      <div className="flex flex-col items-start space-y-10  min-h-[90vh]  ">
        <div
          data-aos={"fade-in"}
          className="grid md:grid-cols-2 gap-10 pt-14 md:pt-1 w-full bg-black  text-white "
        >
          <div className="flex col-span-1 flex-col space-y-3 md:space-y-3 px-10 md:pl-20 justify-center items-start">
            <h3 className="text-sm md:text-base uppercase text-slate-400 font-medium">
              HOW IT WORKS
            </h3>
            <h1 className="text-4xl md:text-5xl font-semibold">
              It's less complicated than you think
            </h1>
            <p className="text-sm md:text-base">
              We have made the process of getting a hire and earning as a
              freelancer as simple as possible.
            </p>
          </div>
          <div
            style={{
              backgroundImage: `url(https://www.hrmanagementapp.com/wp-content/uploads/2019/06/freelancer-2.jpg)`,
            }}
            className="bg-cover bg-center bg-no-repeat col-span-1 h-64 md:h-96"
          />
        </div>
        {/* Tab */}
        <div className="flex  items-center md:items-start justify-center flex-col w-full ">
          <Tab.Group>
            <Tab.List className="flex p-1 px-10 border-b pb-10 w-full md:px-20 space-x-4 md:space-x-5 font-medium  ">
              <Tab
                className={({ selected }) =>
                  `${
                    selected
                      ? "bg-teal-800 text-white"
                      : "text-slate-700 border border-slate-300"
                  }
                                relative
                                rounded-full
                                px-5
                                md:px-10
                                py-2
                                text-sm
                                md:text-sm
                                md:min-w-[100px]
                                animate-all
                                focus:outline-none
                            `
                }
              >
                <span>For Freelancers</span>
              </Tab>
              {/* <Tab
                className={({ selected }) =>
                  `${
                    selected
                      ? "bg-teal-800 text-white"
                      : "text-slate-700 border border-slate-300"
                  }
                                  relative
                                rounded-full
                                px-5
                                md:px-10
                                py-2
                                text-sm
                                md:text-sm
                                md:min-w-[100px]
                                animate-all
                                focus:outline-none
                            `
                }
              >
                <span>For Clients</span>
              </Tab> */}
            </Tab.List>
            <Tab.Panels className={"md:pt-10 w-full "}>
              <Transition
                show={true}
                as={React.Fragment}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Tab.Panel
                  className={"w-full flex flex-col space-y-10 pt-10 md:pt-0"}
                >
                  {freelancerTabPanel.map((item) => (
                    <div
                      data-aos={"fade-up"}
                      data-aos-once={true}
                      key={item.id}
                      className={`${
                        item.id % 2 == 0 ? "bg-slate-50" : ""
                      } grid md:grid-cols-2 gap-5 md:gap-20 w-full p-10 md:p-20 rounded-2xl`}
                    >
                      {/* if the key id is even change order */}
                      <div
                        className={`${
                          item.id % 2 == 0 ? "md:order-last" : ""
                        } flex flex-col items-center order-last  justify-center space-y-3  rounded-xl`}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${item.image})`,
                          }}
                          className="bg-cover bg-center border border-slate-200  bg-no-repeat h-48 md:h-72  w-full rounded-xl"
                        />
                      </div>
                      <div className="flex flex-col sm:order-1 items-start justify-start space-y-3">
                        <h1 className="text-2xl md:text-4xl text-black  font-bold">
                          {item.title}
                        </h1>
                        <p className="text-sm md:text-xl text-slate-700">
                          {item.description}
                        </p>
                        <div className="pt-2 md:pt-6">
                          <SecondarButton heading={item.buttonTitle} />
                        </div>
                      </div>
                    </div>
                  ))}
                </Tab.Panel>
              </Transition>
              <Transition
                show={true}
                as={React.Fragment}
                enter="transition ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Tab.Panel>For Clients</Tab.Panel>
              </Transition>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </MainLayout>
  );
}
