import React from "react";
import NotificationModal from "components/notification-modal/NotificationModal";
import { XIcon } from "@heroicons/react/outline";
import FormGenerator from "components/form-generator/FormGenerator";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SecondarButton from "ui/buttons/SecondaryButton";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { setFreelancerAuth } from "features/auth/AuthSlice";

export default function UpdatePackage({ show, setShow, data }) {
  let [errorMsg, setErrorMsg] = React.useState("");
  let dispatch = useDispatch();
  let [isError, setIsError] = React.useState(false);
  let [metadata, setMetadata] = React.useState(data?.metadata ?? []);
  let freelancerAuth = useSelector((state) => state.auth.freelancer);
  let [key, setKey] = React.useState("");
  let [value, setValue] = React.useState("");
  let [packages, setPackages] = React.useState([]);
  let [index, setIndex] = React.useState(null);

  React.useEffect(() => {
    setPackages(freelancerAuth?.packages);
    setIndex(
      freelancerAuth?.packages?.findIndex((x) => x.id == data?.id) ?? null
    );
    setMetadata(data?.metadata ?? []);
  }, [data, freelancerAuth]);
  // // update the package in freelancer auth

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "packageName",
            displayName: "Name",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "packagePrice",
            displayName: "Price",
            type: "field",
            inputType: "number",
            required: true,
          },
          {
            name: "packageDescription",
            displayName: "Package Description",
            type: "editor",
            data: data?.description ?? "",
            inputType: "text",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      packageName: packages[index]?.name ?? "",
      packageDescription: packages[index]?.description ?? "",
      packagePrice: packages[index]?.price ?? "",
    },
    validationSchema: Yup.object().shape({
      packageName: Yup.string().required("Package Name is required"),
      packageDescription: Yup.string().required(
        "Please enter a package description"
      ),
      packagePrice: Yup.number().required("Please enter a package price"),
    }),
    onSubmit: async (values, { setErrors }) => {
      console.log(values);

      dispatch(
        setFreelancerAuth({
          ...freelancerAuth,
          packages: [
            ...packages.slice(0, index),
            {
              ...packages[index],
              name: values.packageName,
              description: values.packageDescription,
              price: values.packagePrice,
              metadata: metadata,
            },
            ...packages.slice(index + 1),
          ],
        })
      );
      setShow(false);
      // create a user
    },
  };

  return (
    <NotificationModal
      showX={false}
      show={show}
      setShowModal={setShow}
      heading={"Update Package"}
      subHeading={"Please update the following"}
    >
      <FormGenerator
        ErrorMsg={errorMsg}
        isError={isError}
        height={""}
        buttonSubmit={{ title: "Submit" }}
        formData={formData}
      />
      <h3 className="text-xs mb-2">Additional Information</h3>

      <div className="grid grid-cols-12 gap-4 pt-2">
        {
          // show added Metdata

          metadata
            ?.filter((x) => x.variable?.name != "serviceImage")
            ?.map((item, index) => {
              return (
                <div className="col-span-12 grid grid-cols-3 gap-2 md:gap-4">
                  <div className="flex flex-col">
                    <p
                      placeholder="Title"
                      type="text"
                      className=" w-[96] border border-gray-300 font-medium text-xs rounde rounded-md px-4 py-1.5 "
                    >
                      {item.variable.name}
                    </p>
                  </div>

                  <div className="flex flex-col">
                    <p
                      placeholder="Value"
                      type="text"
                      className="w-full border border-gray-300 text-xs rounde rounded-md px-4 py-1.5 "
                    >
                      {item.value}
                    </p>
                  </div>

                  <div className="">
                    <SecondarButton
                      heading={"Remove"}
                      onClick={() => {
                        setMetadata([
                          ...metadata.slice(0, index),
                          ...metadata.slice(index + 1),
                        ]);
                      }}
                    />
                  </div>
                </div>
              );
            })
        }
        <div className="col-span-12">
          <div className="flex flex-col ">
            <div className="flex flex-row justify-start items-center space-x-1 md:space-x-4">
              <div className="flex flex-col">
                <input
                  id="key"
                  name="key"
                  value={key}
                  placeholder="Title"
                  onChange={(e) => {
                    setKey(e.target.value);
                  }}
                  type="text"
                  className="w-full border border-gray-300 text-xs rounde rounded-md px-4 py-1.5 "
                />
              </div>

              <div className="flex flex-col">
                <input
                  id="value"
                  name="value"
                  placeholder="Value"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  type="text"
                  className="w-full border border-gray-300 text-xs rounde rounded-md px-4 py-1.5 "
                />
              </div>

              <div className="w-1/4">
                <PrimaryButton
                  heading={"Add"}
                  onClick={() => {
                    setMetadata([
                      ...metadata,
                      {
                        value: value,
                        variable: {
                          name: key,
                        },
                      },
                    ]);
                    setKey("");
                    setValue("");
                    // reset input fields
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          setShow(false);
        }}
        className="absolute top-3 right-3 cursor-pointer  bg-slate-50 border-slate-200 p-5"
      >
        <XIcon className="w-5 h-5 text-slate-600" />
      </div>
    </NotificationModal>
  );
}
