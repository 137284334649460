import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import customAxios from "middleware/customAxios";
import Modal from "components/modal/Modal";

export default function OfferModal({ closeModal, contract, show, setRefresh }) {
  let navigate = useNavigate();

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "agreedPrice",
            displayName: "Your Offer",
            type: "field",
            inputType: "number",
            required: true,
          },
          {
            name: "freelancerRemark",
            displayName: "Freelancer Remark",
            type: "editor",
            inputType: "text",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      freelancerRemark: "",
    },
    validationSchema: Yup.object().shape({
      agreedPrice: Yup.number().required("Agreed Price is required"),
      freelancerRemark: Yup.string().required("Freelancer Remark is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      if (values.agreedPrice == 0) {
        setErrors({ agreedPrice: "Agreed Price is required" });
        return;
      }
      customAxios
        .post(
          "/freelancer/contract/" + contract.id + "?action=make-offer",
          values
        )
        .then((res) => {
          closeModal();
          setRefresh(Math.floor(Math.random() * 100000000000000000));
          navigate("/my-jobs");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };

  return (
    <Modal
      show={show}
      heading={"Create Offer"}
      subHeading={
        "Please write your offer and a detailed description about your offer. Please note that customer can either reject or accept this offer"
      }
    >
      <div className="h-[500px] p-5">
        <FormGenerator
          modal={true}
          height={""}
          buttonSubmit={{ title: "Submit Offer" }}
          formData={formData}
          log={"making an offer"}
          closeModal={closeModal}
        />
      </div>
    </Modal>
  );
}
