import { configureStore } from "@reduxjs/toolkit";
import GlobalReducer from "../features/global/globalSlice";
import AuthReducer from "features/auth/AuthSlice";

export const store = configureStore({
  reducer: {
    global: GlobalReducer,
    auth: AuthReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
