import React from "react";
import NotificationModal from "components/notification-modal/NotificationModal";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "ui/buttons/PrimaryButton";
import customAxios from "middleware/customAxios";
import axios from "axios";

export default function ChangeProfilePicture({ show, setShow, data }) {
  // // update the package in freelancer auth
  let [uploadedPicture, setUploadedPicture] = React.useState(null);
  let [pictureURL, setPictureURL] = React.useState(null);
  let [submitted, setSubmitted] = React.useState(false);
  let profile = useSelector((state) => state.global.profile);

  return (
    <NotificationModal
      showX={false}
      show={show}
      setShowModal={setShow}
      heading={"Change Profile Picture"}
      subHeading={"Please add a picture"}
    >
      <h3 className="text-xs mb-2">Add picture here</h3>
      {/* <pre>
        <code>{JSON.stringify(freelancerAuth?.images, null, 2)}</code>
      </pre> */}
      {submitted ? (
        // loading
        <div className="flex flex-col w-full items-center justify-center space-y-2">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-slate-500"></div>
          <span className="text-slate-500">Uploading...</span>
        </div>
      ) : (
        <div>
          <div className="space-y-3 w-full group pb-5  relative justify-start items-center">
            <div
              style={{
                backgroundImage: uploadedPicture
                  ? `url(${URL.createObjectURL(uploadedPicture)}) `
                  : `url('https://cdn.icon-icons.com/icons2/564/PNG/512/Add_Image_icon-icons.com_54218.png')`,
              }}
              className="h-72 w-full rounded bg-contain bg-no-repeat bg-center group-hover:opacity-50"
            />
            <div
              className="absolute top-1/2 left-1/2 transform -translate-x-2/4 -translate-y-2/4 flex flex-col items-center justify-center space-y-2 cursor-pointer group-hover:opacity-100 opacity-0 transition-all
                                 "
            >
              <input
                className="bg-orange-100 sticky z-50 opacity-0 h-14 w-14 border-orange-300 text-orange-800 focus:ring-0 cursor-pointer"
                type="file"
                name="image"
                onChange={(event) => {
                  setUploadedPicture(event.target.files[0]);
                }}
              />
              <img
                className=" absolute transition-all z-10 top-0 left-2 group-hover:opacity-70 opacity-10"
                src="https://cdn-icons-png.flaticon.com/512/4687/4687428.png"
                height={32}
                width={32}
              />
            </div>
          </div>
        </div>
      )}

      <div
        onClick={() => {
          setShow(false);
        }}
        className="absolute top-3 right-3 cursor-pointer  bg-slate-50 border-slate-200 p-5"
      >
        <XIcon className="w-5 h-5 text-slate-600" />
      </div>
      <div className="absolute bottom-5 z-50 ">
        <PrimaryButton
          type={"submit"}
          onClick={() => {
            // upload images to s3
            // get signedURL
            let filename = uploadedPicture.name;
            let filetype = uploadedPicture.type;
            filename = filename.replace(/\s/g, "_");
            setSubmitted(true);

            customAxios
              .post("signedURL", {
                filename,
                filetype,
              })
              .then((res) => {
                let signedURL = res.signedRequest;
                let url = res.url;
                var options = {
                  headers: {
                    "Content-Type": filetype,
                  },
                };
                axios
                  .put(signedURL, uploadedPicture, options)
                  .then((res) => {
                    setPictureURL(url);
                    // update the package in freelancer auth
                    console.log(url);

                    let body = {
                      name: profile?.name,
                      email: profile?.email,
                      bio: profile?.bio,
                      address: profile?.address,
                      city: profile?.city,
                      state: profile?.state,
                      documentNumber: profile?.documentNumber,
                      documentUrl: profile?.documentUrl,
                      profileImage: url,
                      bankName: profile?.bankName,
                      accountNumber: profile?.accountNumber,
                      accountName: profile?.accountName,
                    };

                    console.log(body);

                    customAxios
                      .put("/freelancer/profile", body)
                      .then((res) => {
                        setShow(false);
                      })
                      .catch((err) => {
                        console.log(err);
                      });

                    setShow(false);
                    setPictureURL(null);
                    setUploadedPicture(null);
                    setSubmitted(false);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
          }}
          heading={"Upload"}
        />
      </div>
    </NotificationModal>
  );
}
