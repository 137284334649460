import FreelancerLayout from "layout/freelancer/FreelancerLayout";
import React, { useState } from "react";
import AuthHeading from "ui/typography/heading/AuthHeading";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SecondarButton from "ui/buttons/SecondaryButton";
import Widget from "components/widget/Widget";
import { itemRender } from "functions/ItemRender";
import Parser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import UpdateService from "./modals/UpdateService";
import { setFreelancerAuth } from "features/auth/AuthSlice";
import { MdAdd, MdDelete, MdDragIndicator, MdEdit } from "react-icons/md";
import { usePositionReorder } from "hooks/usePositionReorder";
import { useMeasurePosition } from "hooks/useMeasurePosition";
import { AnimatePresence, motion } from "framer-motion";
import UpdatePackage from "./modals/UpdatePackage";
import AddPackage from "./modals/AddPackage";
import AddPicture from "./modals/AddPicture";
import customAxios from "middleware/customAxios";
import Modal from "components/modal/Modal";

export default function ViewService() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let { data } = useLocation().state;
  let freelancerAuth = useSelector((state) => state.auth.freelancer);
  let [selectedPackage, setSelectedPackage] = React.useState(null);
  let [showSuccessModal, setShowSuccessModal] = React.useState(false);
  let [categories, setCategories] = React.useState(
    JSON.parse(localStorage.getItem("categories"))
  );
  let [selectedCategory, setSelectedCategory] = React.useState(null);
  let [showUpdateServiceModal, setShowUpdateServiceModal] =
    React.useState(false);

  let [showUpdatePackageModal, setShowUpdatePackageModal] =
    React.useState(false);

  let [showAddPackageModal, setShowAddPackageModal] = React.useState(false);

  let [showAddPictureModal, setShowAddPictureModal] = React.useState(false);

  function handleUpdateService() {
    setShowUpdateServiceModal(true);
  }

  function handleUpdatePackage() {
    setShowUpdatePackageModal(true);
  }

  function handleAddPackage() {
    setShowAddPackageModal(true);
  }

  function handleAddPicture() {
    setShowAddPictureModal(true);
  }

  function successModalClose() {
    setShowSuccessModal(false);
  }

  let [subCategories, setSubCategories] = React.useState([]);

  React.useEffect(() => {
    reloadData();
  }, []);

  // reload data
  function reloadData() {
    dispatch(
      setFreelancerAuth({
        ...freelancerAuth,
        serviceName: data?.name,
        serviceDescription: data?.description,
        packages: data?.packages ?? [],
        images: data?.packages?.length >= 1 ? data?.packages[0]?.images : [],
        categoryId: data?.category?.id,
        subCategoryId: data?.subCategory?.id,
        category: data?.category,
        subCategory: data?.subCategory,
      })
    );
  }

  // trim categories

  React.useEffect(() => {
    if (selectedCategory) {
      setSubCategories(selectedCategory.subCategories);
    }
  }, [selectedCategory]);

  function handleDeleteImage(image, i) {
    let newImages = freelancerAuth.images.filter((x) => x.value != image.value);
    dispatch(
      setFreelancerAuth({
        ...freelancerAuth,
        images: newImages,
      })
    );
  }

  return (
    <div className="h-screen w-screen absolute z-50 bg-white top-0">
      {/* <pre>{JSON.stringify(freelancerAuth, null, 2)}</pre> */}
      <FreelancerLayout>
        <div className="flex px-2  flex-row justify-between items-center ">
          <div className="flex flex-col space-y-1 md:space-y-3 text-left justify-start">
            {/* bread crumbs */}
            <div className="flex flex-row justify-start items-center space-x-3">
              <Link
                className="hover:text-slate-600 w-fit md:w-fit truncate  flex text-xs md:text-xs"
                to="/my-services"
              >
                My Services
              </Link>
              <span className="text-slate-600">/</span>
              <span className="text-slate-600 truncate w-44 md:w-full max-w-1/2 md:max-w-none  text-xs md:text-xs">
                {data?.name || "N/A"}
              </span>
            </div>
            <AuthHeading heading={data?.name} />
          </div>
        </div>

        <div className="flex flex-col pt-3  justify-start text-start space-y-3 w-full">
          <Widget heading={"Service Information"}>
            {itemRender("Service Name", freelancerAuth?.serviceName || "N/A")}
            {itemRender(
              "Service Category",
              freelancerAuth?.category?.name || "N/A"
            )}
            {itemRender(
              "Service Sub Category",
              freelancerAuth?.subCategory?.name || "N/A"
            )}
            <div className="col-span-1 md:col-span-4 space-y-0">
              <label className="text-[10px] font-medium">Description</label>
              {freelancerAuth?.serviceDescription?.length > 0 && (
                <div className="text-xs text-slate-700">
                  {Parser(freelancerAuth?.serviceDescription || data)}
                </div>
              )}
            </div>
            <div
              className="absolute top-3 right-2 text-xs text-teal-900 cursor-pointer"
              onClick={() => {
                handleUpdateService();
              }}
            >
              <SecondarButton heading={"Update Service"} />
            </div>
          </Widget>
          {/* Packages */}
          <Widget
            heading={"Packages"}
            subHeading={"You can have upto 3 packages"}
          >
            {freelancerAuth?.packages
              ?.filter(
                // filter deleted
                (x) => x.deleted != true
              )
              ?.map((_package, i) => (
                <div className="shadow-md relative rounded-xl border border-slate-200 p-3 col-span-2 grid grid-cols-2 gap-3">
                  <h1 className="col-span-4 w-[200px]  md:w-full   truncate text-sm md:text-base font-bold  pb-2">
                    {_package.name}
                  </h1>
                  {itemRender("Package Name", _package.name)}
                  {itemRender("Package Price", _package.price)}

                  <div className="col-span-3 space-y-1">
                    <label className="text-[10px] font-medium">
                      Description
                    </label>
                    {_package?.description?.length > 0 && (
                      <div className="text-xs text-slate-600">
                        {Parser(_package?.description)}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col space-y-1 col-span-2">
                    <h1 className="text-[10px] font-medium">Metadata</h1>
                    <div className="flex flex-col  space-y-1">
                      {_package.metadata
                        ?.filter((x) => x.variable?.name != "serviceImage")
                        ?.map((meta, i) => (
                          <div className="flex  rounded-xl text-teal-900 flex-row space-x-1 items-center">
                            <span className="text-xs text-slate-600">
                              {meta.variable?.name}
                            </span>
                            <span className="text-xs text-slate-600">:</span>
                            <span className="text-xs text-slate-600">
                              {meta.value}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* edit */}
                  <div>
                    <div className="absolute flex flex-row space-x-3 top-2 md:top-4 right-4 text-xs text-teal-900 cursor-pointer">
                      <MdEdit
                        onClick={() => {
                          setSelectedPackage({
                            ..._package,
                            index: i,
                            metadata: _package.metadata?.filter(
                              (x) => x.variable?.name != "serviceImage"
                            ),
                          });
                          handleUpdatePackage();
                        }}
                        className="w-6 h-6 text-teal-900 border p-1 rounded-full "
                      />
                      <MdDelete
                        onClick={() => {
                          // add deleted status to package
                          if (freelancerAuth?.packages?.length == 1) {
                            alert("You must have atleast one package");
                            return;
                          }
                          let newPackages = freelancerAuth.packages.map((x) => {
                            if (x.id == _package.id) {
                              return {
                                ...x,
                                deleted: true,
                              };
                            } else {
                              return x;
                            }
                          });

                          dispatch(
                            setFreelancerAuth({
                              ...freelancerAuth,
                              packages: newPackages,
                            })
                          );
                        }}
                        className="w-6 h-6 text-red-600 border p-1 rounded-full  "
                      />
                    </div>
                  </div>
                </div>
              ))}
            {/* add new Package */}
            {freelancerAuth?.packages?.length <= 2 && (
              <div
                onClick={() => {
                  handleAddPackage();
                }}
                className="h-full min-h-[calc(100vh-600px)] w-full col-span-2 relative md:h-full  hover:bg-slate-100 cursor-pointer rounded-2xl bg-cover bg-center"
              >
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <div className="flex flex-col space-y-2 items-center justify-center">
                    <div className="flex flex-col space-y-1 items-center justify-center">
                      <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                        <MdAdd className="w-6 h-6 text-teal-900" />
                      </div>
                      <span className="text-xs text-slate-600">
                        Add New Package
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <div
              className="absolute top-3 right-2 text-xs text-teal-900 cursor-pointer"
              onClick={() => {
                handleUpdateService();
              }}
            >
              <SecondarButton heading={"Change order"} />
            </div> */}
          </Widget>
          <Widget heading={"Service Images"}>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 col-span-4">
              <AnimatePresence>
                {freelancerAuth.images?.map((image, i) => (
                  <div
                    key={i}
                    //  animation props
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    //  animation props

                    style={{ backgroundImage: `url(${image.value})` }}
                    className="h-44 shadow-md relative rounded-xl md:h-64 w-full bg-cover bg-center"
                  >
                    <div>
                      <div className="absolute flex flex-row space-x-2 top-3 right-2 text-xs text-teal-900 cursor-pointer">
                        <div className="p-1.5 rounded-full bg-white">
                          <MdDelete
                            onClick={() => {
                              handleDeleteImage(image, i);
                            }}
                            className="w-4 h-4 text-red-600 "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </AnimatePresence>

              {/* add new picture */}
              <div
                onClick={() => {
                  handleAddPicture();
                }}
                className=" relative col-span-1  min-h-44 md:h-64 w-full h-full hover:bg-slate-100 cursor-pointer rounded-2xl bg-cover bg-center"
              >
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <div className="flex flex-col space-y-2 items-center justify-center">
                    <div className="flex flex-col space-y-1 items-center justify-center">
                      <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                        <MdAdd className="w-6 h-6 text-teal-900" />
                      </div>
                      <span className="text-xs text-center text-slate-600">
                        Add New Picture
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Widget>
        </div>
        <div className="flex flex-row space-x-4 items-end justify-end w-full">
          <SecondarButton
            onClick={() => {
              reloadData();
            }}
            heading={"Discard changes"}
          />
          <PrimaryButton
            onClick={() => {
              let body = {
                categoryId: freelancerAuth.category?.id,
                subCategoryId: freelancerAuth?.subCategory?.id,
                name: freelancerAuth?.serviceName,
                description: freelancerAuth?.serviceDescription,
                packages: freelancerAuth?.packages,
                images: freelancerAuth?.images,
              };
              console.log(body);
              customAxios
                .put(`/freelancer/service/${data?.id}`, body)
                .then((res) => {
                  console.log(res);
                  setShowSuccessModal(true);
                });
            }}
            heading={"Save your changes"}
          />
        </div>
      </FreelancerLayout>
      {/* Add Packages */}
      <UpdateService
        show={showUpdateServiceModal}
        setShow={setShowUpdateServiceModal}
        data={data}
      />
      {/* Update Packages */}
      <UpdatePackage
        show={showUpdatePackageModal}
        setShow={setShowUpdatePackageModal}
        data={selectedPackage}
      />

      {/* add Picture */}
      <AddPicture
        show={showAddPictureModal}
        setShow={setShowAddPictureModal}
        data={freelancerAuth}
      />
      {/* Add Packages */}
      <AddPackage
        show={showAddPackageModal}
        setShow={setShowAddPackageModal}
        data={selectedPackage}
      />
      <Modal
        show={showSuccessModal}
        setShow={successModalClose}
        heading={"Success"}
        subHeading={"Your service has been updated successfully"}
        button1={"Okay"}
        button2={"Okay"}
        button1OnClick={successModalClose}
        button2OnClick={successModalClose}
      >
        <div className="h-14 w-32 flex items-center justify-center"></div>
        <div class="bg-gray-50 fixed bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-3  justify-end items-center flex-row space-x-3">
          <PrimaryButton
            heading={"Okay"}
            onClick={() => {
              successModalClose();
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
