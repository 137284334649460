import { StarIcon } from "@heroicons/react/solid";
import React from "react";
import { useState } from "react";

export default function StarRatings({ setRatingParent }) {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  return (
    <div className="star-rating w-full flex justify-center items-center p-5">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            onClick={() => {
              setRatingParent(index);
              setRating(index);
            }}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <StarIcon
              className={`h-10 w-10
                ${
                  index <= (hover || rating)
                    ? "text-teal-400 "
                    : " text-gray-300"
                }`}
            />
          </button>
        );
      })}
    </div>
  );
}
