import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { isAfter, set } from "date-fns";
import { Combobox } from "@headlessui/react";
import ComboBoxComponent from "components/combo-box/ComboBoxComponent";
import customAxios from "middleware/customAxios";
import { useDispatch, useSelector } from "react-redux";
import { setFreelancerAuth } from "features/auth/AuthSlice";

export default function Service({ next }) {
  let dispatch = useDispatch();
  let freelancerAuth = JSON.parse(localStorage.getItem("freelancerAuth"));
  let navigate = useNavigate();
  let [errorMsg, setErrorMsg] = React.useState("");
  let [isError, setIsError] = React.useState(false);
  let [categories, setCategories] = React.useState(
    JSON.parse(localStorage.getItem("categories"))
  );
  let [selectedCategory, setSelectedCategory] = React.useState(null);
  let [selectedSubCategory, setSelectedSubCategory] = React.useState(null);
  let [loading, setLoading] = React.useState(false);
  let [subCategories, setSubCategories] = React.useState([]);

  // trim categories

  React.useEffect(() => {
    if (selectedCategory) {
      setSubCategories(selectedCategory.subCategories);
    }
  }, [selectedCategory]);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "category",
            displayName: "Category",
            type: "comboBox",
            required: true,
            values: categories ?? [],
            value: (formik) => {
              setSelectedCategory(formik?.values?.category);
              return formik.values.category;
            },
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item?.name}</span>
                </div>
              );
            },
          },
          {
            name: "subCategory",
            displayName: "Sub Category",
            type: "component",
            required: true,
            component: (formik) => {
              return (
                <Combobox
                  value={
                    freelancerAuth?.subCategory ?? selectedSubCategory ?? {}
                  }
                  onChange={(value) => {
                    dispatch(
                      setFreelancerAuth({
                        ...freelancerAuth,
                        subCategory: null,
                      })
                    );
                    console.log(value);
                    setSelectedSubCategory(value);
                    // formik.setFieldValue("subCategory", value);
                  }}
                >
                  <ComboBoxComponent
                    options={function (item) {
                      return (
                        <div className="grid grid-cols-12">
                          <span className="col-span-12">{item?.name}</span>
                        </div>
                      );
                    }}
                    values={subCategories}
                  />
                </Combobox>
              );
            },
          },
          {
            name: "serviceName",
            displayName: "Service Name",
            type: "field",
            required: true,
          },
          {
            name: "serviceDescription",
            displayName: "Description",
            // data: freelancerAuth.serviceDescription,
            type: "editor",
            required: true,
          },

          // {
          //   name: "isHighest",
          //   displayName: "Is Highest",
          //   type: "checkBox",
          //   required: true,
          // },
        ],
      },
    ],
    initialValues: {
      category: freelancerAuth?.category ?? null,
      subCategory: freelancerAuth.subCategory ?? null,
      serviceName: freelancerAuth?.serviceName ?? "",
      serviceDescription: freelancerAuth?.serviceDescription ?? "",
    },
    validationSchema: Yup.object().shape({
      category: Yup.object().required("Category is required"),
      serviceName: Yup.string().required("Service Name is required"),
      serviceDescription: Yup.string().required("Description is required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, errors, setStatus, resetForm, handleSubmit }
    ) => {
      values = {
        category: selectedCategory,
        subCategory: selectedSubCategory,
        serviceName: values.serviceName,
        serviceDescription: values.serviceDescription,
      };

      // Keep the previous values and add the new values
      values = { ...freelancerAuth, ...values };
      console.log(values);

      // navigate to next step
      navigate(next ? next : "/auth/signup/seller/step/Package");
      dispatch(setFreelancerAuth(values));
    },
  };

  return (
    <div className="flex flex-col w-full md:w-1/2 -space-y-1">
      {!loading && (
        <FormGenerator
          ErrorMsg={errorMsg}
          isError={isError}
          height={""}
          buttonSubmit={{ title: "Proceed" }}
          formData={formData}
        />
      )}
    </div>
  );
}
