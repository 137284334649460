import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { isAfter } from "date-fns";
import customAxios from "middleware/customAxios";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerAuth } from "features/auth/AuthSlice";
import axios from "axios";

export default function Profile({ nextUrl }) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let customerAuth = useSelector((state) => state.auth.customer);
  let [errorMsg, setErrorMsg] = React.useState("");
  let [isError, setIsError] = React.useState(false);
  const formData = {
    contents: [
      {
        heading: "Personal Information",
        description: "For now just upload your profile picture",
        function: () => {
          return <div></div>;
        },
        fields: [
          {
            name: "profilePictureURL",
            displayName: "",
            type: "pictureUpload",
            value: (formik) => {
              return formik.values.profilePictureURL;
            },
          },
        ],
      },
    ],
    initialValues: {
      profilePictureURL: customerAuth.profilePictureURL ?? "",
    },
    validationSchema: Yup.object().shape({
      profilePictureURL: Yup.string().required("Profile Picture is required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, setStatus, resetForm, handleSubmit }
    ) => {
      console.log(values);
      // get signedURL
      let filename = values.profilePictureURL.name;
      let filetype = values.profilePictureURL.type;
      filename = filename.replace(/\s/g, "_");

      customAxios
        .post("signedURL", {
          filename,
          filetype,
        })
        .then((res) => {
          let signedURL = res.signedRequest;
          let url = res.url;
          var options = {
            headers: {
              "Content-Type": filetype,
            },
          };
          console.log(values);
          axios
            .put(signedURL, values.profilePictureURL, options)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
          values.profilePictureURL = url;
          // Keep the previous values and add the new values
          values = { ...customerAuth, ...values };

          dispatch(setCustomerAuth(values));

          navigate(
            nextUrl ? nextUrl : "/auth/signup/customer/step/Preview Profile"
          );
        });
    },
  };

  return (
    <div className="flex flex-col -space-y-1">
      <FormGenerator
        ErrorMsg={errorMsg}
        isError={isError}
        height={""}
        buttonSubmit={{ title: "Preview Profile" }}
        formData={formData}
      />
    </div>
  );
}
