import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React from "react";

export default function NotificationModal({
  showX,
  show,
  children,
  setShowModal,
  heading,
  subHeading,
}) {
  return (
    <div
      class=" relative  z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <Transition
        show={show}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          style={{
            zIndex: 1000,
          }}
          class="fixed top-8 -right-3 md:right-0 overflow-y-auto"
        >
          <div class="flex min-h-screen bg-white bg-opacity-10 items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition
              show={show}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div class="relative transform min-w-[100vw]  max-w-[100vw] overflow-auto md:min-w-[calc(50vw-200px)] h-[calc(95vh-20px)] border-l    bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="flex flex-col space-y-1 p-5 bg-slate-50 border-b border-slate-200">
                  <h1 className="f text-bae font-bold">{heading}</h1>
                  <p className="text-xs text-slate-600">{subHeading}</p>
                </div>
                <div className="overflow-auto p-3 md:p-5     transition-all">
                  {showX && (
                    <button
                      className="absolute top-7 right-5 p-2"
                      onClick={() => {
                        setShowModal();
                      }}
                    >
                      <XIcon className="w-5 h-5 text-slate-600" />
                    </button>
                  )}
                  {children}
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </Transition>
    </div>
  );
}
