import React from "react";

export default function LoadingPrimaryButton({
  submitted,
  log,
  title,
  onClick,
}) {
  return (
    <div className="flex items-center justify-center">
      {submitted ? (
        <button
          type="submit"
          className=" flex flex-row justify-between text-teal-600 text-md font-medium p-2 px-6 rounded-md cursor-not-allowed"
        >
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-teal-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"
            ></path>
          </svg>
          {log}
        </button>
      ) : (
        <button
          onClick={onClick}
          type="submit"
          className="bg-teal-600 text-white text-sm font-medium p-2 px-10 rounded-full hover:bg-teal-800"
        >
          {title}
        </button>
      )}
    </div>
  );
}
