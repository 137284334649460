import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import FileUpload from "components/dropZone/dropZone";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { setCustomerAuth } from "features/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import SecondarButton from "ui/buttons/SecondaryButton";

export default function CourseEnroll({ nextUrl }) {
  let customerAuth = useSelector((state) => state.auth.customer);
  let [idCard, setIdCard] = React.useState("");
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    idCardUrl: Yup.string().required(
      "Please upload your verification document"
    ),
  });

  const {
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    setErrors,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      idCardUrl: customerAuth.idCardUrl,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      // if (values.idCardUrl === "") {
      //   setErrors({ idCardUrl: "Please upload your verification document" });
      // }

      values.idCard = idCard;
      console.log(values);

      // if no error navigate
      if (Object.keys(errors).length === 0) {
        // Keep the previous values and add the new values
        values = { ...customerAuth, ...values };
        dispatch(setCustomerAuth(values));
        // navigate to next step
        navigate(nextUrl ? nextUrl : "/auth/signup/customer/step/Banking Info");
      }
    },
  });

  return (
    <div className="flex w-full  ">
      <div className="flex w-full">
        {customerAuth?.idCardUrl || customerAuth?.idCard == "" ? (
          <div className="flex flex-col space-y-6 w-full">
            {/* <div className="flex flex-col space-y-2  row-span-2 justify-start items-start    text-sm">
              <label className="col-span-3 text-sm ">ID Card</label>
              <input
                id="idCard"
                name="idCard"
                type="text"
                onChange={(e) => setIdCard(e.target.value)}
                value={customerAuth?.idCard ?? ""}
                className="w-1/4 rounded-md  py-1  px-2  text-sm border border-gray-200 focus:ring-orange-400 focus:outline-none"
              ></input>
            </div> */}
            <img
              src={customerAuth?.idCardUrl}
              className="rounded-md"
              alt="document"
            />

            <SecondarButton
              onClick={() => {
                dispatch(setCustomerAuth({ idCardUrl: "" }));
              }}
              heading="Remove"
            ></SecondarButton>
          </div>
        ) : (
          <div className="flex flex-col space-y-6 w-full">
            <div className="flex flex-col space-y-2  row-span-2 justify-start items-start    text-sm">
              <label className="col-span-3 text-sm ">ID Card</label>
              <input
                id="idCard"
                name="idCard"
                type="text"
                onChange={(e) => setIdCard(e.target.value)}
                className="w-1/4 rounded-md  py-1  px-2  text-sm border border-gray-200 focus:ring-orange-400 focus:outline-none"
              ></input>
            </div>

            <FileUpload
              previewHeight={300}
              fieldText="Upload your ID card here"
              setFieldValue={setFieldValue}
              field={"idCardUrl"}
            />
            {touched.idCardUrl && errors.idCardUrl && (
              <p
                style={{
                  fontSize: 10,
                  color: "red",
                  paddingTop: 5,
                }}
              >
                {errors.idCardUrl}
              </p>
            )}
          </div>
        )}

        <div className="fixed bottom-5 z-50 ">
          <PrimaryButton
            type={"submit"}
            onClick={() => {
              handleSubmit();
            }}
            heading={"Proceed"}
          />
        </div>
      </div>
    </div>
  );
}
