import Banner from "components/home/banner/Banner";
import Categories from "components/home/categories/Categories";
import FeaturedWorks from "components/home/featured-works/FeaturedWorks";
import HowItWorks from "components/home/how-it-works/HowItWorks";
import MainLayout from "layout/main/MainLayout";
import React from "react";
import FreelancerHome from "./freelancer-home/FreelancerHome";
import { ReactComponent as LogoCircle } from "assets/logo/logo-circle.svg";
import jwtDecode from "jwt-decode";
import { setIsFreelancer, setUser } from "features/global/globalSlice";
import { useDispatch } from "react-redux";
import customAxios from "middleware/customAxios";
import { useSelector } from "react-redux";
import Ads from "components/advertisement/Ads";
import Modal from "components/modal/Modal";
import PrimaryButton from "ui/buttons/PrimaryButton";
import EscrowPayment from "components/banners/EscrowPayment";
import RecentlyViewed from "components/home/recently-viewed/RecentlyViewed";
import BecomeAFreelancer from "components/banners/BecomeAFreelancer";
import { Helmet } from "react-helmet";

export default function Home() {
  let dispatch = useDispatch();
  let [loading, setLoading] = React.useState(true);
  let [token, setToken] = React.useState(localStorage.getItem("token"));
  let [decodedToken, setDecodedToken] = React.useState(null);
  let isFreelancer = JSON.parse(localStorage.getItem("isFreelancer"));
  let [trigger, setTrigger] = React.useState(Math.random());
  let user = useSelector((state) => state.global.user);
  let [show, setShow] = React.useState(true);
  const modalChange = () => {
    localStorage.setItem("firstReload", false);
    setTrigger(Math.random());
    setShow(!show);
  };

  let reload = localStorage.getItem("firstReload");

  React.useEffect(() => {
    customAxios.get("/admin/categories").then((res) => {
      localStorage.setItem("categories", JSON.stringify(res));
    });
    setLoading(false);
  }, [token, isFreelancer, reload, trigger]);

  // React.useEffect(() => {
  //   if (reload) {
  //     setShow(false);
  //   }
  // }, []);

  return (
    <>
      <Helmet title="Hivvaru | Home">
        <meta name="description" content="Hivvaru | Home" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-V6X19H5GPD"
        ></script>
      </Helmet>
      <div className="hidden">
        {JSON.stringify(isFreelancer ? "freelancer" : "client")}
      </div>

      {loading ? (
        <div className="flex justify-center bg-black items-center h-screen">
          <LogoCircle className="h-32 animate-pulse" />
        </div>
      ) : (
        <div
          className="relative bg-gradient-to-br px-20
          flex flex-col space-y-4 justify-center items-center
        from-slate-50 to-neutral-100 h-screen w-screen"
        >
          <LogoCircle
            style={{
              height: "10rem",
              width: "10rem",
            }}
            className="animate-pulse duration-700"
          />
          <div className="space-y-6 pt-8">
            <h1 className="text-5xl text-black font-bold text-center">
              Welcome to Hivvaru
            </h1>
            <p className="text-black text-center">
              We have some new features for you that we are excited to share.
              Stay tuned.
            </p>
            <p className="text-black text-center">- Team Hivvaru -</p>
          </div>
        </div>
      )}
    </>
  );
}
