import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  view: "client",
  loggedIn: false,
  isEnrolled: false,
  isValidated: false,
  user: {},
  token: null,
  loading: false,
  error: null,
  isFreelancer: false,
  bufferImage: null,
  profile: null,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },

    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setIsEnrolled: (state, action) => {
      state.isEnrolled = action.payload;
    },
    setIsValidated: (state, action) => {
      state.isValidated = action.payload;
    },
    setUser: (state, action) => {
      if (action.payload.userType[0] == "freelancer") {
        state.isFreelancer = true;
      }
      state.user = action.payload;
    },
    setIsFreelancer: (state, action) => {
      state.isFreelancer = !state.isFreelancer;
      localStorage.setItem("isFreelancer", state.isFreelancer);
    },
    setBufferImage: (state, action) => {
      state.bufferImage = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setLoggedIn,
  setIsEnrolled,
  setIsValidated,
  setUser,
  setIsFreelancer,
  setBufferImage,
  setProfile,
} = globalSlice.actions;
export const selectLoggedIn = (state) => state.global.loggedIn;
export const selectIsEnrolled = (state) => state.global.isEnrolled;
export const selectIsValidated = (state) => state.global.isValidated;

export default globalSlice.reducer;
