import { FlagIcon, PlayIcon } from "@heroicons/react/outline";
import MainLayout from "layout/main/MainLayout";
import React from "react";
import { Helmet } from "react-helmet";
import { FaPlayCircle, FaWpbeginner } from "react-icons/fa";
import { MdCompassCalibration } from "react-icons/md";

let timelineData = [
  {
    icon: <FaWpbeginner className="h-6 w-6 text-white" />,
    title: "Winners of Miyaheli",
    year: "2021",
    description: `At Miyaheli, an innovation camp held by 
UNDP Maldives, team Hivvaru won a 
grant and first place. for coming up with 
a solution for social issues in the country.
We pitched the idea for an online
marketplace for freelancers.`,
  },
  {
    icon: <MdCompassCalibration className="h-6 w-6 text-white" />,
    title: "Finding Alivelun",
    year: "2022",
    description: `We founded Alivelun Pvt Ltd to bring this 
Hivvaru idea to life. This is when Inan (CVO), 
Saman (CEO) and Arusham (CTO) started
Working on the Hivvaru website. We 
put in all our will-power (Hivvaru) to 
finish the beta version with the feedback
of hundreds of freelancers.`,
  },
  {
    icon: <FlagIcon className="h-6 w-6 text-white" />,
    title: "Hivvaru Website",
    year: "2023",
    description: `www.hivvaru.com is live with its first
freelancers onboard. We have opened
the doors for various workers all around 
the Maldives to have this new money 
making experience on theinternational
labor day.`,
  },
];

let team = [
  {
    name: "Saman Jamal",
    position: "Co-Founder & CEO",
    image:
      "https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/team/saman.jpg",
  },
  {
    name: "Mohamed Inan Fareed",
    position: "Co-Founder & CVO",
    image:
      "https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/team/inan.jpg",
  },
  {
    name: "Mohamed Arusham Hussain",
    position: "Developer & CTO",
    image:
      "https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/team/arsham.jpg",
  },
];

let mission = [
  "Encourage entrepreneurship and promote skill development",
  "Reduce bureaucratic obstacles and secure job placements",
  "Educate people about freelancing and establish legal protections",
  "Promote success stories, Identify, and acknowledge people’s passion",
  "We Search, Collaborate and Network with talents",
  "Build a supportive community",
];

export default function AboutUs() {
  React.useEffect(() => {
    window.scrollTo(0, 0, "smooth");
  }, []);

  return (
    <>
      <Helmet title="Hivvaru | About Us">
        <meta name="description" content="We are a" />
      </Helmet>
      <MainLayout>
        <div className=" w-full overflow-hidden flex justify-center space-y-20  items-center flex-col ">
          {/* Hero */}
          <div
            data-aos="fade-in"
            className="grid  grid-cols-1 md:grid-cols-2 gap-10 bg-black"
          >
            <div className="py-20 px-10 md:pl-20">
              <h5 className="text-teal-500">About Us</h5>
              <h1 className="text-4xl md:text-5xl text-white font-bold mt-3">
                We are a team of{" "}
                <span className="text-teal-600">passionate</span> people who
                love to help{" "}
                <span className="font-light italic">freelancers</span> to
                connect to <span className="font-light italic">clients</span>{" "}
                and <span className="font-light italic">clients</span> to find{" "}
                <span className="font-light italic">freelancers</span>.
              </h1>
              <p className="text-gray-500 mt-6">
                Hivvaru is a safe marketplace for freelancers to connect to
                clients. Our catalog of freelancers cover all budget ranges and
                offer all kinds of freelance services – including personal
                trainers, wedding planners, fashion designers, graphic
                designers, bakers and much more! The best part? All transactions
                are protected by Hivvaru’s escrow system. As clients, you just
                browse services you need and get them done easily.
              </p>
            </div>
            <div
              style={{
                backgroundImage: `url("https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/_S7A3015.png")`,
              }}
              className="bg-cover bg-right-bottom h-full w-full "
            ></div>
          </div>
          {/* timeline */}
          <div className="relative px-10   w-full mt-10 container max-auto">
            <div className="absolute hidden md:block  w-full z-0 top-6 h-[1px] bg-slate-500 opacity-50"></div>
            <div className="grid grid-cols-1 md:grid-cols-3  gap-10 ">
              {timelineData.map((item, index) => (
                <div data-aos="fade-left" className="col-span-1 space-y-2">
                  <div className="flex  z-50 bg-black w-fit h-fit p-3 rounded-full items-center justify-center">
                    {item.icon}
                  </div>
                  <div>
                    <h1 className="text-2xl font-bold">{item.title}</h1>
                    <h1 className="text-base font-bold">{item.year}</h1>
                    <p className="text-gray-500 mt-6">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Our Mission */}
          <div
            data-aos="fade-right"
            className="relative w-full px-10 md:px-0 mt-10 container max-auto"
          >
            <div className="grid grid-cols-1 md:grid-cols-2  gap-10 ">
              <div className="col-span-1 space-y-4 items-start justify-center h-full flex flex-col">
                <h1 className="text-4xl font-bold">Our Mission</h1>
                <div>
                  {mission.map((item, index) => (
                    <div className="flex space-x-5 items-center">
                      <div className="w-2 h-2 bg-teal-500 rounded-full"></div>
                      <p className="text-gray-500">{item}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url("https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/_S7A3008.png")`,
                }}
                className="bg-cover bg-center rounded-xl h-full min-h-[calc(100vh-450px)] w-full "
              ></div>
            </div>
          </div>
          {/* Our Vision */}
          <div
            data-aos="fade-left"
            className="relative w-full px-10 md:px-0 mt-10 container max-auto"
          >
            <div className="grid grid-cols-1 md:grid-cols-2  gap-10 container mx-auto ">
              <div
                style={{
                  backgroundImage: `url("https://img.freepik.com/premium-photo/group-diverse-business-people-working-together-having-meeting_695793-1294.jpg")`,
                }}
                className="bg-cover order-2 md:order-first bg-center rounded-xl h-full min-h-[calc(100vh-450px)] w-full "
              ></div>
              <div className="col-span-1 space-y-4 items-start  justify-center h-full flex flex-col">
                <h1 className="text-4xl font-bold">Our Vision</h1>
                <p className="text-gray-500 mt-6">
                  Give a voice to the talents that are difficult to reach and
                  unheard of. Give employment opportunities to people and
                  minimize the unemployment rate of this country. Make
                  freelancing a respectable and secure way of earning money in
                  the Maldives. Build a place for passionate people to thrive
                  doing what they love most.
                </p>
              </div>
            </div>
          </div>
          {/* Our story */}
          <div className="relative w-full px-10 md:px-0 mt-10 bg-slate-100 flex justify-center items-center py-10 ">
            <div className="grid grid-cols-1 md:grid-cols-2  gap-10 container mx-auto ">
              <div className="col-span-1 space-y-4 items-start  justify-start h-full flex flex-col">
                <h1 className="text-4xl font-bold">Our Story</h1>
                <p className="text-gray-500 mt-6">
                  We are a bunch of freelancers. Saman, our hard-working CEO,
                  works fulltime at a utility company and work part-time at
                  night, Inan CVO, - works at an insurance company and teaches
                  breathwork part-time, and Arusham (CTO) brings fundamental
                  tech upgrades in all the projects he works in and works
                  online/remote all-the-time.
                </p>
                <p className="text-gray-500 mt-6">
                  This trio, share the same goals; to increase efficiency in our
                  developing marketplace, to create something meaningful and
                  empower the future generations.
                </p>
                {/* Watch the video */}
              </div>
              <div
                style={{
                  backgroundImage: `url("https://img.freepik.com/premium-photo/group-diverse-business-people-working-together-having-meeting_695793-1294.jpg")`,
                }}
                className="bg-cover relative bg-center rounded-xl h-full min-h-[calc(100vh-450px)] w-full "
              >
                {/* play button */}
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <div className="flex cursor-pointer  z-50  w-fit h-fit p-3 rounded-full items-center justify-center">
                    <FaPlayCircle className="h-10 w-10 hover:scale-110 transition-all text-white" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Our Team */}
          <div
            data-aos="slide-up"
            className="relative pb-20 w-full justify-center items-center flex  flex-col text-center px-10 md:px-0 mt-10 container max-auto"
          >
            <div>
              <h1 className="text-4xl font-bold">Our Team</h1>
              <p className="text-gray-500 mt-6 max-w-xl">
                Our team endeavors to optimize productivity, develop innovative
                solutions, and inspire future generations.
              </p>
            </div>

            <div className="grid mt-20  w-full h-full grid-cols-1 md:grid-cols-3 gap-10 md:gap-20  ">
              {team.map((item) => (
                <div className="col-span-1 flex items-center justify-center space-y-10 flex-col w-full  text-center   h-full ">
                  <div className="relative">
                    <img
                      src={item.image}
                      alt=""
                      className="rounded-full  w-[200px] h-[200px] object-cover object-center"
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center mt-10">
                    <h1 className="text-2xl font-bold text-black">
                      {item.name}
                    </h1>
                    <p className="text-black">{item.position}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
}
