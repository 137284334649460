import MainLayout from "layout/main/MainLayout";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { replaceDashes, replaceSpaces } from "functions/replaceDashes";
import Heading from "ui/typography/heading/Heading";
import ServiceBanner from "./components/service-banner/ServiceBanner";
import customAxios from "middleware/customAxios";
import PrimaryButton from "ui/buttons/PrimaryButton";
import WorkCard from "components/work-card/WorkCard";
import Ads from "components/advertisement/Ads";
import FeaturedWorks from "components/home/featured-works/FeaturedWorks";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ViewCategory() {
  let navigate = useNavigate();
  let [pageData, setPageData] = React.useState([]);
  const { Category } = useParams();

  let { id, image } = useLocation().state;

  let category = {
    id: id,
    name: replaceDashes(Category),
    image: image,
  };

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  React.useEffect(() => {
    scrollToTop();
  }, []);

  React.useEffect(() => {
    customAxios.get("/content/category/" + id).then((res) => {
      setPageData(res);
    });
  }, [id]);

  return (
    <MainLayout>
      {/* <div className="flex justify-center items-center">
        <Ads />
      </div> */}
      <div className=" ">
        <ServiceBanner category={pageData?.category} />
      </div>

      <div className="flex flex-col space-y-10 items-center justify-start overflow-hidden px-5 md:px-20 pb-10">
        <div className="flex flex-col space-y-5 text-start items-start justify-start">
          <h1>
            <span className="text-2xl font-bold text-gray-800">
              Explore this category
            </span>
          </h1>
          <p className="text-center text-gray-400">
            {pageData?.category?.description}
          </p>
        </div>
        <div className="grid grid-cols-2 w-full md:grid-cols-6 gap-5 md:gap-8">
          {pageData?.subCategoriesArray?.map((subCategory) => (
            <div
              onClick={() =>
                navigate(`/Services/${replaceSpaces(subCategory?.name)}`, {
                  state: {
                    id: subCategory?.id,
                    category: {
                      id: id,
                      name: category,
                    },
                  },
                })
              }
              className="rounded-xl flex justify-start items-center flex-col space-y-2 hover:cursor-pointer"
            >
              <img
                src={
                  "https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/vectors/3D.png"
                }
                alt={subCategory?.name}
                className="rounded-xl h-[100px] w-[100px] object-contain"
              />
              <div className="flex flex-col justify-center items-center space-y-3 p-2">
                <h1 className="text-xs md:text-sm font-medium text-center">
                  {subCategory?.name}
                </h1>
              </div>
            </div>
          ))}
        </div>
      </div>
      <FeaturedWorks />
    </MainLayout>
  );
}
