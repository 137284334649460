import { Tab, Transition } from "@headlessui/react";
import NavBar from "components/navigation/NavBar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "ui/typography/heading/Heading";
import Widget from "components/widget/Widget";
import moment from "moment";
import SecondarButton from "ui/buttons/SecondaryButton";

import { itemRender } from "functions/ItemRender";
import FreelancerNavBar from "components/navigation/FreelancerNavBar";
import Modal from "components/modal/Modal";
import LoadingPrimaryButton from "ui/buttons/LoadingPrimaryButton";
import customAxios from "middleware/customAxios";
import OTPInput from "components/otp-input/OTPInput";
import { useNavigate, useParams } from "react-router-dom";
import UpdateBio from "./modals/UpdateBio";
import HTMLReactParser from "html-react-parser";
import { CameraIcon } from "@heroicons/react/solid";
import ChangeProfilePicture from "./modals/ChangeProfilePicture";
import { setProfile } from "features/global/globalSlice";

export default function Hires() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let user = useSelector((state) => state.global.user);
  let isFreelancer = useSelector((state) => state.global.isFreelancer);
  let [showModal, setShowModal] = React.useState(false);
  let [OTPSent, setOTPSent] = React.useState(false);
  let [OTP, setOTP] = React.useState("");
  let [error, setError] = React.useState("");
  let [freelancerData, setFreelancerData] = React.useState({});
  let [showUpdateBioModal, setShowUpdateBioModal] = React.useState(false);
  // get refresh url param
  let { refresh } = useParams();
  let [trigger, setTrigger] = React.useState(
    Math.random().toString(36).substring(7)
  );
  let [showUpdateProfilePictureModal, setShowUpdateProfilePictureModal] =
    React.useState(false);

  function handleUpdateBio() {
    setShowUpdateBioModal(!showUpdateBioModal);
  }

  function handleUpdateProfilePicture() {
    setShowUpdateProfilePictureModal(!showUpdateProfilePictureModal);
  }

  let censoredPhone = freelancerData?.phone?.replace(
    freelancerData?.phone.slice(3, 6),
    "XXX"
  );

  React.useEffect(() => {
    // if (!user.phoneValidated) {
    //   setShowModal(true);
    // }
    if (isFreelancer) {
      customAxios.get("/freelancer/profile").then((res) => {
        setFreelancerData(res);
        dispatch(setProfile(res));
      });
    }
  }, [refresh]);

  const tabs = [
    {
      name: "Profile",
      userType: ["freelancer", "customer"],
      component: (
        <div className="flex flex-col space-y-3">
          <Widget heading={"User Information"}>
            {itemRender("Phone", freelancerData?.user?.phone)}
            {!freelancerData?.user?.phoneValidated ? (
              <p className="text-xs text-red-500">Phone not validated</p>
            ) : (
              ""
            )}
            {itemRender(
              "Date of Birth",
              moment(freelancerData?.user?.dateOfBirth).format("DD MMM YYYY")
            )}
          </Widget>
          <Widget
            heading={
              isFreelancer ? "Freelancer Information" : "Customer Information"
            }
          >
            <div className="md:flex col-span-4 flex-col space-y-1">
              <div
                onClick={() => {
                  handleUpdateProfilePicture();
                }}
                className="flex cursor-pointer group relative w-32 h-32 md:h-40 md:w-40 flex-row space-x-2"
              >
                <img
                  className="w-32 group-hover:-brightness-20 h-32 md:h-40 md:w-40 object-cover rounded-full"
                  src={
                    isFreelancer
                      ? freelancerData?.profileImage
                      : freelancerData?.userInformation?.clientCustomer
                          ?.profileImage
                  }
                  alt="profile"
                />
                {/* absolute */}
                <div className="absolute bottom-3 left-6 translate-x-1/2 group-hover:opacity-90 opacity-0  text-xs text-teal-900 cursor-pointer">
                  <CameraIcon className="h-10 w-10 text-white" />
                </div>
              </div>
            </div>
            <div className="flex flex-row space-x-2 col-span-2">
              {itemRender(
                "Name",
                isFreelancer
                  ? freelancerData?.name
                  : freelancerData?.userInformation?.clientCustomer?.name
              )}
            </div>

            {/* email */}
            {itemRender(
              "Email",
              isFreelancer
                ? freelancerData?.email
                : freelancerData?.userInformation?.clientCustomer?.email
            )}

            {!isFreelancer &&
              itemRender(
                "ID Card Number",
                isFreelancer
                  ? freelancerData?.documentNumber
                  : freelancerData?.userInformation?.clientCustomer?.idCard
              )}

            {/* render Bio */}
            <div className="col-span-4">
              <label className="text-[10px] font-medium">Bio</label>
              <div className="flex flex-row space-x-2">
                <p className="text-xs text-slate-600">
                  {isFreelancer ? (
                    <div>{freelancerData?.bio}</div>
                  ) : (
                    freelancerData?.userInformation?.clientCustomer?.bio
                  )}
                </p>
              </div>
            </div>
            {/* absolute button */}
            <div
              className="absolute top-3 right-2 text-xs text-teal-900 cursor-pointer"
              onClick={() => {
                handleUpdateBio();
              }}
            >
              <SecondarButton heading={"Update Bio"} />
            </div>
          </Widget>
        </div>
      ),
    },

    {
      name: "Password",
      component: (
        <div className="flex flex-col space-y-3">
          <Widget heading={"Password"}>
            {itemRender("Password", freelancerData?.phone)}
            <SecondarButton heading={"Reset Password"} />
          </Widget>
        </div>
      ),
    },
    {
      name: "Validation Document",
      component: (
        <div className="flex flex-col space-y-3">
          <Widget heading={"Validation Document"}>
            {!isFreelancer && (
              <>{itemRender("ID Card", freelancerData?.documentNumber)}</>
            )}

            <div className="flex col-span-4 flex-col space-y-2">
              <p className="text-sm font-medium">{"Validation Document"} </p>
              <div className="flex flex-row space-x-2">
                <img
                  className="h-[300px] w-[480px] object-cover border border-slate-200 rounded-md"
                  src={
                    isFreelancer
                      ? freelancerData?.documentUrl
                      : freelancerData?.userInformation?.clientCustomer
                          ?.idCardUrl
                  }
                  alt="profile"
                />
              </div>
            </div>
          </Widget>
        </div>
      ),
    },
    {
      name: "Banking Information",
      component: (
        <div className="flex flex-col space-y-3">
          <Widget heading={"Banking Information"}>
            {itemRender(
              "Bank Name",
              isFreelancer
                ? freelancerData?.bankName
                : freelancerData?.userInformation?.clientCustomer?.bankName
            )}
            {itemRender(
              "Account Number",
              isFreelancer
                ? freelancerData?.accountNumber
                : freelancerData?.userInformation?.clientCustomer?.accountNumber
            )}
            {itemRender(
              "Account Name",
              isFreelancer
                ? freelancerData?.accountName
                : freelancerData?.userInformation?.clientCustomer?.accountName
            )}
          </Widget>
        </div>
      ),
    },
  ];

  function handleChange(OTP) {
    setOTP(OTP);
  }

  return (
    <div className="relative">
      {isFreelancer ? <FreelancerNavBar /> : <NavBar />}

      <div className="flex pt-16 items-start justify-center bg-slate-50 min-h-[100vh]  ">
        <div className=" p-5 flex flex-col space-y-3 max-w-6xl w-full">
          {freelancerData && (
            <div className="w-full grid grid-cols-6  rounded-xl space-x-10 md:space-x-0 justify-start items-center ">
              <div
                style={{
                  backgroundImage: `url('${
                    isFreelancer
                      ? freelancerData?.profileImage
                      : freelancerData?.userInformation?.clientCustomer
                          ?.profileImage
                  }')`,
                }}
                className="rounded-full col-span-1 bg-cover bg-center h-20 w-20 bg-white"
              />
              <div className="flex flex-col col-span-5 overflow-hidden space-y-3">
                <Heading
                  heading={
                    isFreelancer
                      ? freelancerData?.name
                      : freelancerData?.userInformation?.clientCustomer?.name
                  }
                />
                <span className="flex flex-row text-sm space-x-3 w-1/3">
                  <p>{freelancerData?.user?.phone} </p>
                  <p>|</p>
                  <p>
                    {isFreelancer
                      ? freelancerData?.email
                      : freelancerData?.userInformation?.clientCustomer
                          ?.email}{" "}
                  </p>
                </span>
                {isFreelancer ? (
                  <></>
                ) : (
                  <p className="border border-teal-500 w-fit px-2 text-sm rounded-md text-teal-600 py-1">
                    Active
                  </p>
                )}
              </div>
            </div>
          )}

          <div className="flex flex-row md:flex-col w-full">
            <div className="  justify-between flex flex-col h-full">
              <Tab.Group>
                <div className="grid grid-cols-1 w-full md:grid-cols-10 gap-3">
                  <Tab.List className="flex flex-row md:flex-col col-span-10 md:col-span-2 h-fit overflow-auto    border-b">
                    {tabs?.map((item, index) => {
                      return (
                        <Tab
                          key={index}
                          className={({ selected }) =>
                            ` ${index == 0 ? "rounded-tl-md" : "pl-4"} ${
                              selected
                                ? "text-teal-700 border-b-2 border-teal-600   "
                                : "text-slate-400"
                            }
                          min-w-fit py-2.5 px-1 text-sm  flex flex-row justify-start items-start  focus:outline-none font-medium text-center`
                          }
                          // onClick={() => setStatus(item?.status ?? "pending")}
                        >
                          <p>{item.name}</p>
                        </Tab>
                      );
                    })}
                  </Tab.List>
                  {freelancerData && (
                    <Tab.Panels className={"col-span-10 md:col-span-8"}>
                      {tabs.map((item, index) => {
                        return (
                          <Tab.Panel key={index} className="">
                            {item.component}
                          </Tab.Panel>
                        );
                      })}
                    </Tab.Panels>
                  )}
                </div>
              </Tab.Group>
            </div>
          </div>
        </div>
      </div>
      <Modal
        heading={"Please Validate your Phone Number"}
        subHeading={
          OTPSent
            ? `An OTP of 6 characters was sent to your mobile phone +960${censoredPhone}. Please enter the OTP below to continue using our services.`
            : "Please validate your phone number to continue using our services. Please click the button below to receive a verification code."
        }
        show={showModal}
        setShowModal={() => setShowModal(false)}
      >
        <p>{error ?? ""}</p>

        {OTPSent && (
          <div className="p-5">
            <OTPInput value={OTP} onChange={handleChange} />
          </div>
        )}

        <div class="bg-gray-50 sticky bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-3  justify-end items-center flex-row space-x-3">
          <SecondarButton
            heading={"Cancel"}
            onClick={() => {
              setShowModal(false);
            }}
          />

          <LoadingPrimaryButton
            title={OTPSent ? "Submit" : "Send OTP"}
            onClick={() => {
              if (OTPSent) {
                customAxios
                  .post("/auth/validate", {
                    phone: freelancerData?.phone,
                    OTP: OTP,
                  })
                  .then((res) => {
                    console.log(res);
                    setShowModal(false);
                    navigate("/auth/login");
                  });
              } else {
                customAxios
                  .post("/auth/validate/send-otp", {
                    phone: freelancerData?.phone,
                  })
                  .then((res) => {
                    console.log(res);
                    setOTPSent(true);
                  });
              }
            }}
            log={"Booking the freelancer..."}
          />
        </div>
      </Modal>
      {/* Update Bio */}
      <UpdateBio
        show={showUpdateBioModal}
        setShow={handleUpdateBio}
        data={
          isFreelancer
            ? freelancerData?.bio
            : freelancerData?.userInformation?.clientCustomer?.bio
        }
      />
      <ChangeProfilePicture
        show={showUpdateProfilePictureModal}
        setShow={handleUpdateProfilePicture}
        data={
          isFreelancer
            ? freelancerData?.profileImage
            : freelancerData?.userInformation?.clientCustomer?.profileImage
        }
      />
    </div>
  );
}
