import MultiUpload from "components/multi-upload/MultiUpload";
import React from "react";
import { useDropzone } from "react-dropzone";
import customAxios from "middleware/customAxios";
import SecondarButton from "ui/buttons/SecondaryButton";
import axios from "axios";
import { setFreelancerAuth } from "features/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";

export default function ServicePictures({ next }) {
  let dispatch = useDispatch();
  let freelancerAuth = JSON.parse(localStorage.getItem("freelancerAuth"));
  let navigate = useNavigate();
  const [files, setFiles] = React.useState([]);
  const [imageUrls, setImageUrls] = React.useState([]);
  let [error, setError] = React.useState();

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    multiple: true,
    accept: "image/*",
    // limit number of files
    maxFiles: 6,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      acceptedFiles.map((file) => {
        let filename = file.name;
        // remove white space
        filename = filename.replace(/\s/g, "");
        let filetype = file.type;
        console.log("filename", filename);

        customAxios
          .post("/signedURL", {
            filename: filename,
            filetype: filetype,
          })
          .then(function (result) {
            var signedUrl = result.signedRequest;
            setImageUrls((prev) => [...prev, result.url]);
            var options = {
              headers: {
                "Content-Type": filetype,
              },
            };
            console.log("signedUrl", signedUrl);

            return axios
              .put(signedUrl, file, options)
              .then(function (result) {
                console.log(result);

                // navigate to url
              })
              .catch(function (err) {
                console.log(err);
              });
          });
      });
    },
  });

  const thumbs = files.map((file) => (
    <div style={{ fontSize: 10 }} className="w-full" key={file.name}>
      <div className="p-0.5   text-secondary rounded-md">
        <div
          className="w-full bg-cover bg-center rounded-xl h-full "
          style={{
            backgroundImage: `url(${file.preview})`,
            height: 180,
            width: 180,
          }}
        />

        {/* <p>{file.name}</p>
        <p>{file.type}</p> */}
      </div>
    </div>
  ));

  return (
    <div className="flex flex-col space-y-2">
      {freelancerAuth?.serviceImages?.length > 0 ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
            {freelancerAuth?.serviceImages?.map((url) => (
              <div style={{ fontSize: 10 }} className="w-full" key={url}>
                <div className="p-0.5   text-secondary rounded-md">
                  <div
                    className="w-full bg-cover bg-center rounded-xl h-full "
                    style={{
                      backgroundImage: `url(${url})`,
                      height: 180,
                      width: 180,
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col space-y-6 w-1/4">
            <aside className="grid grid-cols-3 md:grid-cols-6 gap-2">
              {thumbs}
            </aside>
            <SecondarButton
              onClick={() => {
                setFiles([]);
                setImageUrls([]);
                dispatch(
                  setFreelancerAuth({
                    ...freelancerAuth,
                    serviceImages: [],
                  })
                );
              }}
              heading="Remove current"
            />
          </div>
        </>
      ) : (
        <>
          {!files?.length > 0 ? (
            <div
              {...getRootProps({ className: "dropzone" })}
              className="border border-secondary bg-slate-100 min-w-full md:min-w-[30vw]  focus:outline-none rounded-md space-y-3 flex flex-col items-center justify-center  p-5"
            >
              <input {...getInputProps()} />
              <img
                className="h-10 w-10"
                src="https://cdn-icons-png.flaticon.com/128/3685/3685850.png"
              />
              <p className="text-primary" style={{ fontSize: 10 }}>
                {`Drag 'n' drop some files here, or click to select files`}
              </p>
            </div>
          ) : (
            <div className="flex flex-col space-y-6">
              <aside className="grid grid-cols-6 gap-2">{thumbs}</aside>
              <SecondarButton
                onClick={() => setFiles([])}
                heading="Remove current"
              />
              {/* show error */}
              {error && (
                <div className="bg-red-100 text-red-500 p-2 rounded-md">
                  {error}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {imageUrls?.length > 0 && (
        <div className="fixed bottom-5 z-50 ">
          <PrimaryButton
            type={"submit"}
            onClick={() => {
              dispatch(
                setFreelancerAuth({
                  ...freelancerAuth,
                  serviceImages: imageUrls,
                })
              );
              navigate(next || "/auth/signup/seller/step/Profile");
            }}
            heading={"Proceed"}
          />
        </div>
      )}
    </div>
  );
}
