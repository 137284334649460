import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import React from "react";
import { AnimatePresence } from "framer-motion";
import Home from "pages/home/Home";
import Auth from "pages/auth/Auth";
import Signup from "pages/auth/signup/Signup";
import FreelancerSignUp from "pages/auth/signup/freelancer/FreelancerSignUp";
import FreelancerSignUpStep from "pages/auth/signup/freelancer/steps/FreelancerSignUpStep";
import ProfilePreview from "pages/auth/signup/freelancer/profile-preview/ProfilePreview";
import Services from "pages/services/Services";
import AllServices from "pages/services/all-services/AllServices";
import ViewService from "pages/services/view-service/ViewService";
import BookService from "pages/services/book-service/BookService";
import Login from "pages/auth/login/Login";
import CustomerSignup from "pages/auth/signup/customer/CustomerSignup";
import CustomerSignupStep from "pages/auth/signup/customer/steps/CustomerSignupStep";
import Hires from "pages/customer/hires/Hires";
import Profile from "pages/customer/profile/Profile";
import MyJobs from "pages/freelancer/my-jobs/MyJobs";
import MyServices from "pages/freelancer/services/MyServices";
import MyEarnings from "pages/freelancer/earnings/MyEarnings";
import ViewServiceFreelancer from "pages/freelancer/services/view-service/ViewService";
import ViewCategory from "pages/categories/ViewCategory";
import AboutUs from "pages/about/about-us/AboutUs";
import FreelancerProfile from "pages/freelancer/profile/FreelancerProfile";
import FAQ from "pages/about/faq/FAQ";
import ContactUs from "pages/about/contact-us/ContactUs";
import HowItWorks from "pages/about/how-it-works/HowItWorks";
import EscrowPayment from "pages/about/EscrowPayment";
import CustomerCreate from "pages/customer/create/CustomerCreate";
import CustomerCreateStep from "pages/customer/create/steps/CustomerCreateStep";
import AddService from "pages/freelancer/services/add-service/AddService";
import ResetPassword from "pages/auth/reset-password/ResetPassword";
import SearchResults from "pages/search/SearchResults";

export const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        {/* <Route path="*" element={<p>Not Found</p>} />
        <Route path="auth/" element={<Auth />}>
          <Route path="login/" element={<Login />} />
          <Route path="signup/" element={<Signup />}>
            <Route path="seller/" element={<FreelancerSignUp />}>
              <Route path="step/:step" element={<FreelancerSignUpStep />} />
              <Route path="PreviewProfile" element={<ProfilePreview />} />
            </Route>
            <Route path="customer" element={<CustomerSignup />}>
              <Route path="step/:step" element={<CustomerSignupStep />} />
            </Route>
          </Route>
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>

        <Route path="services/" element={<Services />}>
          <Route path=":SubCategory" element={<AllServices />} />
          <Route path="View/:id" element={<ViewService />} />
          <Route path="Book/:ServiceName" element={<BookService />} />
        </Route>

        {/* Categories */}
        <Route path="categories/:Category" element={<ViewCategory />} />

        {/* Customer */}
        <Route path="my-hires" element={<Hires />} />
        <Route path="My Profile" element={<Profile />} />

        {/* Freelancer */}
        <Route path="my-jobs" element={<MyJobs />} />
        <Route path="my-services/" element={<MyServices />}>
          <Route path=":Service" element={<ViewServiceFreelancer />} />
          <Route path="add/:step" element={<AddService />} />
        </Route>
        <Route path="my-earnings" element={<MyEarnings />} />
        <Route
          path="/freelancer/profile/:freelancer"
          element={<FreelancerProfile />}
        />

        {/* Create customers */}
        <Route path="/create-customer" element={<CustomerCreate />}>
          <Route path="step/:step" element={<CustomerCreateStep />} />
        </Route>

        {/* Search results */}
        <Route path="/search/:query" element={<SearchResults />} />

        {/* Extra */}
        <Route path="404" element={<p>Not Found</p>} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/payment-policy" element={<EscrowPayment />} />
      </Routes>{" "}
      */}
    </AnimatePresence>
  );
};
