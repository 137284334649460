import { Line } from "react-chartjs-2";
import ChartContainer from "../charts-container/ChartsContainer";
import { normaliseDatasets } from "../HelperFunctions";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default function LineChart({
  title,
  labels,
  datasets,
  className,
  height,
}) {
  const normalisedDatasets = normaliseDatasets(datasets);

  const dataConfig = {
    title: title,
    labels: labels,
    datasets: normalisedDatasets,
  };

  const options = {
    plugins: {
      legend: datasets.length > 1,
    },
    title: {
      display: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return <Line id={"chart"} data={dataConfig} options={options} />;
}

LineChart.defaultProps = {
  title: "",
  labels: [],
  datasets: [],
  className: "",
};
