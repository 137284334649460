import { Popover, Transition } from "@headlessui/react";
import {
  ArrowCircleRightIcon,
  MenuIcon,
  SearchIcon,
  XIcon,
} from "@heroicons/react/solid";
import { motion } from "framer-motion";
import { Fragment, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import MobileMenu from "./MobileMenu";
import { ReactComponent as LogoCircle } from "assets/logo/logo-circle.svg";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import SecondarButton from "ui/buttons/SecondaryButton";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import Heading from "ui/typography/heading/Heading";
import { replaceDashes, replaceSpaces } from "functions/replaceDashes";
import { setIsFreelancer } from "features/global/globalSlice";
import { useDispatch } from "react-redux";
import ResetPassword from "pages/auth/reset-password/ResetPassword";
import MobileNavBar from "./nav/MobileNavBar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Framer Motion for icon animation
const Container = styled(motion.div)`
  position: relative;
  max-width: 250px;
  cursor: pointer;
`;

const categories = JSON.parse(localStorage.getItem("categories"));

export default function NavBar() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [hoverShow, setHoverShow] = useState({});
  let [token, setToken] = useState(localStorage.getItem("token"));
  let user = useSelector((state) => state.global.user);
  let isFreelancer = useSelector((state) => state.global.isFreelancer);
  let [isBaseUrl, setIsBaseUrl] = useState(false);
  let [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  useEffect(() => {
    // check if baseUrl is "/"
    if (window.location.pathname === "/") {
      setIsBaseUrl(true);
    } else {
      setIsBaseUrl(false);
    }
  }, []);

  let links = [
    // {
    //   name: "Why Hivvaru?",
    //   path: "/why-hivvaru",
    // },
    {
      name: "How it works",
      path: "/how-it-works",
    },
    {
      name: "FAQ",
      path: "/faq",
    },
    {
      name: "Contact Us",
      path: "/contact-us",
    },
    {
      name: "About Us",
      path: "/about-us",
    },
  ];

  let switchAccountRender = useCallback(() => {
    // switch case statements if userType array includes

    let userTypes = user?.userType;

    console.log(userTypes);

    if (userTypes?.includes("freelancer") && userTypes?.includes("client")) {
      // switch account
      return (
        <button
          onClick={() => {
            dispatch(setIsFreelancer(!isFreelancer));
            navigate(`/`);
          }}
          to="/switch-account"
        >
          <div className="flex flex-row items-center space-x-2">
            <p className="text-sm">Switch Account</p>
            <ArrowCircleRightIcon className="h-5 w-5" />
          </div>
        </button>
      );
    } else if (userTypes?.includes("client")) {
      console.log("client");
    } else if (userTypes?.includes("freelancer")) {
      console.log("freelancer");
    } else if (userTypes?.includes("admin")) {
      console.log("admin");
    } else {
      console.log("nah");
    }
  }, [user]);

  // useEffect(() => {
  //   // check scroll position and set hoverShow to true
  //   window.addEventListener("scroll", () => {
  //     if (window.scrollY > 200) {
  //       setHoverShow(true);
  //     } else {
  //       setHoverShow(false);
  //     }
  //   });
  // });

  return (
    <div
      className={`fixed z-50 w-full bg-white  s items-center justify-center flex flex-col `}
    >
      <div className="md:flex hidden justify-around w-full  border-b border-slate-200   ">
        <div className="flex w-full  items-center justify-between  py-2 md:py-4 px-20">
          <Link to={"/"} className="mr-0 flex flex-row space-x-3 items-center">
            <LogoCircle className="h-10" />
            <div className="flex items-end h-fit justify-end flex-col">
              <h1 className="font-sans text-2xl font-bold">Hivvaru</h1>
              <p className="text-[10px]">By Alivelun</p>
            </div>
          </Link>
          <div className="flex flex-row space-x-10 ml-10 font-medium w-full">
            {links.map((link, index) => {
              return (
                <Link
                  to={link.path}
                  key={index}
                  className="hidden md:flex flex-row items-center space-x-3"
                >
                  {link.name}
                </Link>
              );
            })}
          </div>

          <div className="-my-2 space-x-2 hidden md:flex md:space-x-3 min-w-[100px]  items-center">
            {token ? (
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        // open ? "text-secondary" : "text-black",
                        "group  hidden:md-block rounded-md inline-flex items-center text-base  font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                      )}
                    >
                      <img
                        src={
                          isFreelancer
                            ? user?.userInformation?.freelancer?.profileImage
                            : user?.userInformation?.clientCustomer
                                ?.profileImage
                        }
                        alt="avatar"
                        className="h-10 w-10 rounded-full"
                      />
                      <ChevronDownIcon
                        className={`ml-2 h-5 w-5 `}
                        aria-hidden="true"
                      />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="fixed top-[73px] right-10 ">
                        <div className="md:block rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="re grid gap-y-6 bg-white min-w-[180px]  sm:gap-8 sm:p-8">
                            <div className="flex flex-col justify-between items-start space-y-4">
                              <div className="flex flex-row justify-start items-center space-x-3">
                                <img
                                  src={
                                    isFreelancer
                                      ? user?.userInformation?.freelancer
                                          ?.profileImage
                                      : user?.userInformation?.clientCustomer
                                          ?.profileImage
                                  }
                                  alt="avatar"
                                  className="h-14 w-14 rounded-full"
                                />
                                {/* <code>{JSON.stringify(user)}</code> */}
                                <div className="flex flex-col space-y-1 justify-start text-start">
                                  <h1 className=" font-semibold">
                                    {isFreelancer
                                      ? user?.userInformation?.freelancer?.name
                                      : user?.userInformation?.clientCustomer
                                          ?.name}
                                  </h1>
                                  {switchAccountRender()}
                                </div>
                              </div>

                              <div className="flex flex-col space-y-2  justify-start text-start">
                                <Link
                                  to="/My Profile"
                                  className="text-sm text-gray-500 hover:text-gray-700"
                                >
                                  Manage Profile
                                </Link>

                                {!isFreelancer && (
                                  <Link
                                    to="/my-hires"
                                    className="text-sm text-gray-500 hover:text-gray-700"
                                  >
                                    My Job Hires
                                  </Link>
                                )}

                                {/* {!isFreelancer && (
                                  <Link
                                    to="/account"
                                    className="text-sm text-gray-500 hover:text-gray-700"
                                  >
                                    Saved Services
                                  </Link>
                                )} */}

                                <div
                                  onClick={() =>
                                    setResetPasswordModalOpen(true)
                                  }
                                  className="text-sm text-gray-500 hover:text-gray-700"
                                >
                                  Reset Password
                                </div>
                              </div>

                              {/* Logout */}
                              <PrimaryButton
                                onClick={() => {
                                  localStorage.removeItem("token");
                                  // clear local storage
                                  localStorage.clear();
                                  navigate("/auth/login");
                                }}
                                heading={"Logout"}
                              />
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            ) : (
              <>
                <SecondarButton
                  onClick={() => navigate("/auth/login")}
                  heading={"Login"}
                />
                {/* Search */}
                <PrimaryButton
                  onClick={() => navigate("/auth/signup")}
                  heading={"Signup"}
                />
              </>
            )}

            {/* Menu */}
            <Popover.Group className=" ml-2 rounded-md p-2 md:hidden inline-flex items-center justify-center  focus:outline-none">
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        // open ? "text-secondary" : "text-black",
                        "group   rounded-md inline-flex items-center text-base  font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                      )}
                    >
                      {open && (
                        <>
                          <XIcon
                            className={classNames(
                              // open ? "text-secondary" : "text-black",
                              "h-8 w-8 mt-1 group-hover:text-secondary"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}

                      {!open && (
                        <>
                          <MenuIcon
                            className={classNames(
                              // open ? "text-secondary" : "text-black",
                              "h-8 w-8 mt-1 group-hover:text-secondary"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      //   onMouseEnter={() => setIsShowingOS(true)}
                      //   onMouseLeave={() => setIsShowingOS(false)}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-x-full"
                      enterTo="opacity-100 translate-x-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-x-0"
                      leaveTo="opacity-0 translate-x-full"
                    >
                      <Popover.Panel className=" absolute z-50 top-full inset-x-0 transform shadow-lg bg-white">
                        <div className="">
                          {/* Add Search Component */}

                          <MobileMenu />
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
          </div>
        </div>
        {resetPasswordModalOpen && <ResetPassword />}
      </div>

      {!isFreelancer && isBaseUrl && (
        <div className="hidden w-full md:flex flex-row space-x-5 py-3 px-20   text-slate-800">
          {/* map first 7 categories */}

          {categories
            ?.filter((x) => x.id <= 7)
            .map((item, key) => (
              <Popover key={key}>
                {({ open }) => (
                  <>
                    <Popover.Button
                      onMouseEnter={() =>
                        setHoverShow({
                          item: item,
                          key: true,
                        })
                      }
                      className={classNames(
                        open ? "text-teal-600" : "text-black",
                        "group  rounded-md inline-flex items-center min-w-max text-sm  font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                      )}
                    >
                      <span
                        className={`${"text-black  bg-clip-text bg-gradient-to-r from-primary to-secondary"}`}
                      >
                        {item.name}
                      </span>
                      <ChevronDownIcon
                        className={`ml-2 h-5 w-5 `}
                        aria-hidden="true"
                      />
                    </Popover.Button>
                    <Transition
                      // return key as show

                      onMouseLeave={() => setHoverShow({ key: false })}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="fixed top-[120px] ">
                        <div className="md:block rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-y-6 bg-white min-w-[200px]  sm:gap-8 sm:p-8">
                            {item.subCategories.map((item, key) => (
                              <div
                                key={key}
                                onClick={() =>
                                  navigate(
                                    `/Services/${replaceSpaces(item.name)}`,
                                    {
                                      state: {
                                        category: item,
                                      },
                                    }
                                  )
                                }
                                className="-m-3 p-0 text-sm  hover:text-teal-600 hover:cursor-pointer flex items-start rounded-lg "
                              >
                                {item.name}
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            ))}

          {/* Map rest of the categories */}
          {
            <Popover>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-teal-600" : "text-black",
                      "group bg-white rounded-md inline-flex items-center text-sm  font-medium hover:text-secondary focus:outline-none  focus:ring-secondary"
                    )}
                  >
                    <span
                      className={`${" bg-clip-text bg-gradient-to-r from-primary to-secondary"}`}
                    >
                      More
                    </span>
                    <ChevronDownIcon
                      className={`ml-2 h-5 w-5 `}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    // return key as show
                    onMouseLeave={() => setHoverShow({ key: false })}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="fixed top-[120px] ">
                      <div className="md:block rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-y-6 bg-white min-w-[200px]  sm:gap-8 sm:p-8">
                          {categories
                            ?.filter((x) => x.id > 7)
                            .map((item, key) => (
                              <div
                                key={key}
                                onClick={() =>
                                  navigate(
                                    `/Categories/${replaceSpaces(item.name)}`,
                                    {
                                      state: {
                                        id: item.id,
                                        image: item.image,
                                        category: item,
                                      },
                                    }
                                  )
                                }
                                className="-m-3 p-0 text-sm  hover:text-teal-600 hover:cursor-pointer flex items-start rounded-lg "
                              >
                                {item.name}
                              </div>
                            ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          }
        </div>
      )}

      {/* Phone NavBar */}
      <div className="md:hidden w-full">
        <MobileNavBar />
      </div>
    </div>
  );
}
