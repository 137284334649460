import MainLayout from "layout/main/MainLayout";
import React from "react";
import { useLocation } from "react-router-dom";
import Parser from "html-react-parser";
import customAxios from "middleware/customAxios";
import WorkCard from "components/work-card/WorkCard";
import SecondarButton from "ui/buttons/SecondaryButton";

export default function FreelancerProfile() {
  let { freelancer } = useLocation().state;
  let [services, setServices] = React.useState([]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    customAxios.get("/content/services").then(
      (res) => {
        // get services by freelancer
        let freelancerServices = res.filter(
          (service) => service.freelancer.id === freelancer.id
        );
        setServices(freelancerServices);
      },
      (err) => {
        console.log(err);
      }
    );
  });

  return (
    <MainLayout>
      {/* banner */}
      <div className="w-full relative grid grid-cols-1 gap-10 md:grid-cols-5 overflow-hidden md:pt-10 px-5 md:px-20 ">
        {/* Image */}
        <div className="relative pt-10 col-span-1 md:col-span-2 bg-slate-50 p-5 rounded-md">
          <div className="w-full h-[130px] md:h-[180px] absolute top-0 z-100"></div>
          <div
            data-aos="fade-up"
            className=" z-20 flex w-full justify-center items-center"
          >
            <div
              style={{
                backgroundImage: `url(${freelancer?.profileImage})`,
              }}
              className=" bg-cover bg-top flex relative  border-2 border-teal-500 h-36 w-36 md:h-50 md:w-50 rounded-full bg-no-repeat"
            ></div>
            {/* Verified */}
            <div className="absolute z-20 -bottom-5 ">
              <img
                className="w-10 h-10"
                src="https://www.svgrepo.com/show/423679/store-verified-shopping.svg"
                alt="object-contain object-center"
              />
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="col-span-2  p-5  text-xl flex flex-col space-y-2 text-center justify-center items-center"
          >
            <div className="flex justify-center items-center flex-col space-y-5 text-center">
              <h1 className="text-2xl md:text-3xl w-3/3 font-medium  ">
                {freelancer?.name}
              </h1>
              {/* location and badges */}
              <div>
                <div className="flex flex-row space-x-2 items-center">
                  <img
                    src="https://www.svgrepo.com/show/452052/location-marker.svg"
                    alt=""
                    className="h-5 w-5"
                  />
                  <p className="text-xs text-slate-800">{freelancer?.city}</p>
                </div>
              </div>
              {/* Contact me */}
              <SecondarButton heading={"Contact Me"}>
                <a href={`mailto:${freelancer?.email}`}>Contact Me</a>
              </SecondarButton>
              <p className="text-xs md:text-sm pt-5 max-w-[90vw] md:max-w-[50vw] ">
                {Parser(freelancer?.bio)}{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="col-span-1 md:col-span-3  space-y-3 md:space-y-5  rounded-xl py-5">
          <div className="flex flex-col space-y-5">
            <h1 className="text-xl md:text-2xl font-semibold">My Gigs</h1>
            <div
              data-aos={"fade-up"}
              className="grid grid-cols-1 md:grid-cols-2 w-full   gap-5"
            >
              {services.map((service) => (
                <WorkCard
                  key={service.id}
                  id={service.id}
                  path={"/Services/View/" + service.name}
                  category={service.category?.name}
                  image={
                    service.packages[0]?.images
                      ? service.packages[0]?.images[0]?.value
                      : "null"
                  }
                  title={service.name}
                  price={service.packages[0]?.price}
                  user={service.freelancer}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
