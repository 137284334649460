import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import DaySelect from "./DaySelect";
import YearSelect from "./YearSelect";
import MonthSelect from "./MonthSelect";

export default function DatePickerV2({ handleDateChange, handleDateClick }) {
  const [selectedDate, setselectedDate] = useState(dayjs());
  const [isMonthSelected, setIsMonthSelected] = useState(false);
  const [isYearSelected, setisYearSelected] = useState(false);
  const [isDaySelected, setisDaySelected] = useState(true);

  const currentDay = useMemo(() => dayjs().toDate(), []);

  const firstDayOfTheMonth = useMemo(
    () => selectedDate.clone().startOf("month"),
    [selectedDate]
  );

  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf("week"),
    [firstDayOfTheMonth]
  );

  const generateFirstDayOfEachWeek = useCallback((day) => {
    const dates = [day];
    for (let i = 1; i < 6; i++) {
      const date = day.clone().add(i, "week");
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeek = useCallback((day) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = day.clone().add(i, "day").toDate();
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeeksOfTheMonth = useMemo(() => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    return firstDayOfEachWeek.map((date) => generateWeek(date));
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

  const switchToMonthSelect = () => {
    setisDaySelected(false);
    setisYearSelected(false);
    setIsMonthSelected(true);
  };

  const switchToDaySelect = () => {
    setisDaySelected(true);
    setisYearSelected(false);
    setIsMonthSelected(false);
  };

  const switchToYearSelect = () => {
    setisDaySelected(false);
    setisYearSelected(true);
    setIsMonthSelected(false);
  };

  const changeMonth = (monthIndex) => {
    setselectedDate((date) => date.month(monthIndex));
  };

  const changeYear = (year) => {
    setselectedDate((date) => date.year(year));
  };

  return (
    <div
      className={
        "absolute z-50 w-[250px] h-fit rounded-lg p-[5px] bg-white shadow-md"
      }
    >
      <div className={"flex items-center justify-between pb-3 "}>
        <h3
          className={
            "hover:bg-gray-100  rounded-md w-fit p-2 text-center cursor-pointer"
          }
          onClick={() => {
            if (isDaySelected) {
              switchToMonthSelect();
            } else if (isMonthSelected) {
              switchToYearSelect();
            }
          }}
        >
          {isDaySelected && selectedDate.clone().format("MMM YYYY")}
          {isMonthSelected && selectedDate.clone().format("MMM YYYY")}
          {isYearSelected && selectedDate.clone().format("YYYY")}
        </h3>
        <div
          className={
            isMonthSelected
              ? "hidden"
              : " w-20 flex items-center justify-around"
          }
        >
          <MdKeyboardArrowLeft
            className="cursor-pointer"
            size={25}
            onClick={() => {
              if (isDaySelected) {
                setselectedDate((date) => date.subtract(1, "month"));
              } else if (isYearSelected) {
                setselectedDate((date) =>
                  date.set("year", selectedDate.year() - 9)
                );
              }
            }}
          />
          <MdKeyboardArrowRight
            className="cursor-pointer"
            size={25}
            onClick={() => {
              if (isDaySelected) {
                setselectedDate((date) => date.add(1, "month"));
              } else if (isYearSelected) {
                setselectedDate((date) =>
                  date.set("year", selectedDate.year() + 9)
                );
              }
            }}
          />
        </div>
      </div>
      <div>
        {isDaySelected && (
          <DaySelect
            weeksOfTheMonth={generateWeeksOfTheMonth}
            handleDateChange={handleDateChange}
            handleDateClick={handleDateClick}
            selectedDate={selectedDate}
            currentDay={currentDay}
          />
        )}
        {isMonthSelected && (
          <MonthSelect
            changeMonth={changeMonth}
            handleMonthSelect={switchToDaySelect}
          />
        )}
        {isYearSelected && (
          <YearSelect
            selectedDate={selectedDate}
            currentYear={parseInt(selectedDate.clone().format("YYYY"))}
            changeYear={changeYear}
            handleYearSelect={switchToMonthSelect}
          />
        )}
      </div>
    </div>
  );
}
