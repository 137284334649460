import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { isAfter } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerAuth } from "features/auth/AuthSlice";

export default function BankingInfo({ nextUrl }) {
  let customerAuth = useSelector((state) => state.auth.customer);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [errorMsg, setErrorMsg] = React.useState("");
  let [isError, setIsError] = React.useState(false);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "bankName",
            displayName: "Bank Name",
            type: "comboBox",
            values: [
              {
                id: 1,
                name: "Bank of Maldives(BML)",
              },
              {
                id: 2,
                name: "Maldives Islamic Bank(MIB)",
              },
              {
                id: 3,
                name: "Commercial Bank of Maldives(CBM)",
              },
            ],
            value: (formik) => {
              return formik.values.bankName;
            },
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item.name}</span>
                </div>
              );
            },
          },
          {
            name: "accountNumber",
            displayName: "Account Number",
            type: "field",
            inputType: "numeric",
            required: true,
          },
          {
            name: "accountName",
            displayName: "Account Name",
            type: "field",
            inputType: "text",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      bankName: customerAuth.bankName ?? "",
      accountNumber: customerAuth.accountNumber ?? "",
      accountName: customerAuth.accountName ?? "",
    },
    validationSchema: Yup.object().shape({
      bankName: Yup.object().required("Bank Name is required"),
      accountNumber: Yup.string().required("Account Number is required"),
      accountName: Yup.string().required("Account Name is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      // Keep the previous values and add the new values
      values = { ...customerAuth, ...values };
      navigate(nextUrl ? nextUrl : "/auth/signup/customer/step/Profile");
      dispatch(setCustomerAuth(values));
    },
  };

  return (
    <div className="flex flex-col -space-y-1">
      <FormGenerator
        ErrorMsg={errorMsg}
        isError={isError}
        height={""}
        buttonSubmit={{ title: "Proceed" }}
        formData={formData}
      />
    </div>
  );
}
