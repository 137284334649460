import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { ReactComponent as LogoCircle } from "assets/logo/logo-circle.svg";
import customAxios from "middleware/customAxios";
import { useDispatch } from "react-redux";
import { setIsFreelancer } from "features/global/globalSlice";
import FormGenerator from "components/form-generator/FormGenerator";
import PrimaryButton from "ui/buttons/PrimaryButton";
import moment from "moment";
import { set, sub } from "date-fns";

export default function ResetPassword() {
  let location = useLocation();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [selectedPhone, setSelectedPhone] = React.useState();
  let [phoneEntered, setPhoneEntered] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState();
  // get url params
  const params = new URLSearchParams(location.search);

  const [timeRemaining, setTimeRemaining] = React.useState(
    moment().fromNow(moment().add(1, "minutes").format("YYYY-MM-DD HH:mm:ss"))
  );

  let passwordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])"
  );

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "code",
            displayName: "Code",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "password",
            displayName: "Password",
            type: "field",
            inputType: "password",
            required: true,
          },
          {
            name: "confirmPassword",
            displayName: "Confirm Password",
            type: "field",
            inputType: "password",
            required: true,
          },
        ],
      },
    ],
    initialValues: {},
    validationSchema: Yup.object().shape({
      password: Yup.string().matches(
        passwordRegex,
        "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
      ),

      confirmPassword: Yup.string().required("Please confirm your password"),
    }),
    onSubmit: async (values, { setErrors }) => {
      if (values.password !== values.confirmPassword) {
        setErrors({ confirmPassword: "Passwords do not match" });
        return;
      }
      customAxios
        .post("/auth/reset-password", {
          phone: selectedPhone,
          OTP: values.code,
          password: values.password,
        })
        .then(
          (res) => {
            console.log(res);
            setPhoneEntered(false);
            setSelectedPhone(null);
            setSubmitted(true);
            setInterval(() => {
              navigate("/auth/login");
            }, 2000);
          },
          (err) => {
            setErrors({
              code: err.data.msg,
            });
          }
        );

      // create a user
    },
  };

  return (
    <div
      style={{
        backgroundImage: `url("https://elorus.ams3.cdn.digitaloceanspaces.com/blogen/2022/06/becoming-a-freelancer---Elorus-Blog.jpg")`,
      }}
      className="w-screen h-screen bg-center  bg-cover flex flex-col justify-center items-center bg-slate-200 from-slate-400 via-black to-slate-300 "
    >
      {/* create a gradient */}
      <div className="absolute inset-0 bg-black opacity-70 z-0"></div>
      <div className=" flex w-full md:w-[30vw]   justify-start items-center z-50 ">
        <div className="max-w-xl md:max-w-lg flex flex-col space-y-3 md:space-y-2 justify-start  items-start  p-10 pt-10 shadow-xl rounded-xl w-full m-5 md:m-0 bg-white py-5 md:py-10">
          <Link to={"/"} className="mr-0">
            <LogoCircle className="h-16" />
          </Link>
          {submitted ? (
            <h1 className="text-2xl font-semibold text-center">
              Password changed successfully
            </h1>
          ) : (
            <div className="space-y-2 flex flex-col justify-start items-start">
              <h1 className="text-2xl font-semibold text-center">
                {phoneEntered ? "Change Password" : "Help us find your account"}
              </h1>
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                  <strong className="font-bold">Error!</strong>
                  <span className="block sm:inline">{error}</span>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg
                      className="fill-current h-6 w-6 text-red-500"
                      role="button"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      onClick={() => setError(null)}
                    >
                      <title>Close</title>
                      <path
                        fillRule="evenodd"
                        // eslint-disable-next-line max-len
                        d="M14.348 5.652a.5.5 0 010 .707L9.707 10l4.641 4.641a.5.5 0 11-.707.707L9 10.707l-4.641 4.64a.5.5 0 11-.707-.707L8.293
                  10 3.652 5.359a.5.5 0 01.707-.708L9 9.293l4.641-4.64a.5.5 0
                  .707z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
              )}

              <h5 className="text-xs text-gray-600 text-start pb-5">
                {phoneEntered
                  ? `Enter the code we just sent to your phone number ending in ${selectedPhone?.slice(
                      -4
                    )}`
                  : "Enter your phone number and we will send you a code to reset your password"}
              </h5>
              {/* Error and Success Server Message */}
              {phoneEntered ? (
                <div className="w-full overflow-hidden">
                  <FormGenerator
                    // ErrorMsg={errorMsg}
                    // isError={isError}
                    buttonFixed={false}
                    height={""}
                    buttonSubmit={{ title: "Submit" }}
                    formData={formData}
                  />
                  {/* Retry again in 1 minute */}
                  {/* <div className="flex flex-row pt-5 justify-center items-center space-x-1">
                <p className="text-xs text-gray-600">Didn't receive code?</p>
                <p className="text-xs text-teal-700">
                  Retry in {moment(timeRemaining).format("MM:SS")} minute
                </p>
              </div> */}
                </div>
              ) : (
                <div className="flex flex-col space-y-3">
                  {/* Phone */}
                  <label htmlFor="phone" className="text-xs text-gray-600">
                    Phone
                  </label>
                  <div className="flex text-sm flex-row space-x-1 w-full">
                    <div className="flex flex-row space-x-1 border rounded-md w-full  px-2 py-1 border-gray-200">
                      <p className="text-slate-500"> +960</p>
                      <input
                        // required={field.required}
                        // disabled={field.disabled}
                        name="phone"
                        onChange={(e) => {
                          setSelectedPhone(e.target.value);
                        }}
                        // value={field.value}
                        className="w-full   px-2  text-sm  focus:ring-orange-400 focus:outline-none"
                      />
                    </div>
                  </div>

                  <div className="pt-5">
                    {/* Submit button */}
                    <PrimaryButton
                      heading={"Send Code"}
                      onClick={() => {
                        customAxios
                          .post("/auth/validate/send-otp", {
                            phone: selectedPhone,
                          })
                          .then(
                            (res) => {
                              console.log(res);
                              setPhoneEntered(true);
                            },
                            (err) => {
                              console.log(err);
                            }
                          );
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <p className=" text-sm md:text-base text-center p-10 text-slate-100 z-30">
        If you face any technical difficulties please contact 9993529 or visit
        our chat services here
      </p>
    </div>
  );
}
