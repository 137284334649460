import customAxios from "middleware/customAxios";
import React from "react";
import AuthHeading from "ui/typography/heading/AuthHeading";
import LoadingPrimaryButton from "ui/buttons/LoadingPrimaryButton";
import FreelancerLayout from "layout/freelancer/FreelancerLayout";
import LineChart from "components/charts/line-chart/LineChart";
import PieChart from "components/charts/pie-chart/PieChart";
import Table from "components/table/Table";
import { columns } from "./columns";
import moment from "moment";
import Modal from "components/modal/Modal";
import SecondarButton from "ui/buttons/SecondaryButton";

export default function MyEarnings() {
  let [loading, setLoading] = React.useState(true);
  let [earnings, setEarnings] = React.useState([]);
  let [showAttachment, setShowAttachment] = React.useState(false);
  let [pieData, setPieData] = React.useState({
    labels: ["Available", "Pending"],
    datasets: [
      {
        data: [],
        hoverOffset: 4,
      },
    ],
  });
  let [lineData, setLineData] = React.useState([
    {
      label: "Earnings", // the name of the dataset
      data: [],
      fill: true,
    },
    {
      label: "Released",
      data: [],
      fill: false,
    },
  ]);

  const modalChange = () => {
    setShowAttachment(!showAttachment);
  };

  React.useEffect(() => {
    customAxios.get(`/freelancer/earnings`).then((res) => {
      if (res.length > 0) {
        setEarnings(res);
      }
      setLoading(false);
    });
  }, []);

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  React.useEffect(() => {
    // get total released earnings
    let releasedEarnings = 0;
    let pendingEarnings = 0;
    earnings.forEach((earning) => {
      if (earning.escrowStatus === "released") {
        releasedEarnings += parseInt(earning.total);
      } else {
        pendingEarnings += parseInt(earning.total - earning.comission);
      }
    });

    // feed dataset
    pieData.datasets[0].data = [releasedEarnings, pendingEarnings];

    // get line chart data
    labels.forEach((label, index) => {
      earnings.forEach((earning) => {
        if (moment(earning.createdAt).format("MMM") === label) {
          console.log(label, earning.createdAt, earning.contract.agreedPrice);
        }
      });
    });
  }, [earnings]);

  return (
    <FreelancerLayout>
      <div className="flex flex-col space-y-3 text-left justify-start">
        <AuthHeading heading={"My Earnings"} />
        <p className="text-slate-600">
          {"Here you can find all your earnings from this platform"}
        </p>
      </div>
      <div className="grid grid-cols-6 w-full order-1">
        <div className="col-span-6 md:col-span-6">
          <div className="w-full order-2 p-5 bg-white shadow rounded-md mt-10">
            {loading ? (
              <div className="flex justify-center items-center">
                <LoadingPrimaryButton />
              </div>
            ) : (
              <Table
                cellFunction={(cell) => {
                  if (cell.column.id === "action") {
                    return (
                      <div className="flex flex-col space-y-2">
                        <p className="text-slate-600 hover:text-teal-600 text-sm">
                          View Contract
                        </p>
                      </div>
                    );
                  } else if (cell.column.id === "attachment") {
                    return (
                      <div className="flex flex-col space-y-2">
                        {cell.value !== null ? (
                          <>
                            <p
                              onClick={() => setShowAttachment(true)}
                              className="text-slate-600 hover:text-teal-600 text-sm"
                            >
                              View Attachment
                            </p>
                            <Modal
                              heading={"View Attachment"}
                              show={showAttachment}
                              setShowModal={modalChange}
                            >
                              <img src={cell.value} alt="attachment" />
                              <div className="fixed top-5 right-3">
                                <SecondarButton
                                  heading={"Close"}
                                  onClick={modalChange}
                                />
                              </div>
                            </Modal>
                          </>
                        ) : (
                          <p className="text-slate-600 text-sm">
                            No Attachment
                          </p>
                        )}
                      </div>
                    );
                  } else if (cell.column.id === "escrowStatus") {
                    return (
                      <div className="flex flex-col space-y-2">
                        {cell.value === "released" ? (
                          <p className="text-slate-600 text-sm">Available</p>
                        ) : (
                          <p className="text-slate-600 text-sm">Not Released</p>
                        )}
                      </div>
                    );
                  } else if (cell.column.id === "paymentStatus") {
                    return (
                      <div className="flex flex-col space-y-2">
                        {cell.value === "paid" ? (
                          <p className="text-green-600 text-sm">Paid</p>
                        ) : (
                          <p className="text-slate-600 text-sm">Not Paid</p>
                        )}
                      </div>
                    );
                  } else if (cell.column.id === "createdAt") {
                    return (
                      <div className="flex flex-col space-y-2">
                        <p className="text-slate-600 text-sm">
                          {moment(cell.value).format("DD MMM YYYY")}
                        </p>
                      </div>
                    );
                  } else if (cell.column.id === "recievable") {
                    return (
                      <div className="flex flex-col space-y-2">
                        <p className="text-teal-600 text-sm">
                          {cell.row.original.total -
                            cell.row.original.comission}
                        </p>
                      </div>
                    );
                  }
                  return (
                    <div className="flex flex-col space-y-2">
                      <p className="text-slate-600 text-sm">{cell.value}</p>
                    </div>
                  );
                }}
                columns={columns}
                testData={earnings ?? []}
                showHeader={true}
              />
            )}
          </div>
        </div>
      </div>
    </FreelancerLayout>
  );
}
