import { Tab } from "@headlessui/react";
import NavBar from "components/navigation/NavBar";
import Widget from "components/widget/Widget";
import MainLayout from "layout/main/MainLayout";
import customAxios from "middleware/customAxios";
import moment from "moment";
import React from "react";
import AuthHeading from "ui/typography/heading/AuthHeading";
import Parse from "html-react-parser";
import SecondarButton from "ui/buttons/SecondaryButton";
import LoadingPrimaryButton from "ui/buttons/LoadingPrimaryButton";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { itemRender } from "functions/ItemRender";
import { Link, useNavigate } from "react-router-dom";
import Modal from "components/modal/Modal";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import FormGenerator from "components/form-generator/FormGenerator";
import StarRatings from "components/star-ratings/StarRatings";

export default function Hires() {
  // get url params from url
  let UrlParams = new URLSearchParams(window.location.search);
  let statusParam = UrlParams.get("state");
  let navigate = useNavigate();
  let [hires, setHires] = React.useState();
  let [loading, setLoading] = React.useState(true);
  let [status, setStatus] = React.useState("in-progress");
  let [paymentState, setPaymentState] = React.useState(statusParam);
  let [trigger, setTrigger] = React.useState("");
  let [showModal, setShowModal] = React.useState(false);
  let [modalContent, setModalContent] = React.useState({});
  let [selectedContract, setSelectedContract] = React.useState(null);
  let [rating, setRating] = React.useState(0);

  React.useEffect(() => {
    if (statusParam == "CONFIRMED") {
      customAxios
        .post("/payment/update?action=approve", {
          state: UrlParams.get("state"),
          transactionId: UrlParams.get("transactionId"),
          signature: UrlParams.get("signature"),
        })
        .then((res) => {
          console.log(res);
          setTrigger(Math.floor(Math.random() * 100000000000000000));
        });
    }
  }, [statusParam]);

  React.useEffect(() => {
    customAxios.get(`/customer/get-all-contracts`).then((res) => {
      setHires(res);
    });
  }, [trigger]);

  const negotiateFormData = {
    contents: [
      {
        fields: [
          {
            name: "customerRemark",
            displayName: "Customer Remark",
            type: "editor",
            inputType: "text",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      customerRemark: "",
    },
    validationSchema: Yup.object().shape({
      customerRemark: Yup.string().required("Freelancer Remark is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      console.log(values);
      customAxios
        .post(
          "/freelancer/contract/" +
            selectedContract.id +
            "?action=renegotiate-offer",
          values
        )
        .then((res) => {
          setShowModal(false);
          setTrigger(Math.floor(Math.random() * 100000000000000000));
          navigate("/my-hires");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };
  const reviewFormData = {
    contents: [
      {
        fields: [
          {
            name: "review",
            displayName: "Review",
            type: "editor",
            inputType: "text",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      review: "",
    },
    validationSchema: Yup.object().shape({
      review: Yup.string().required("Freelancer Remark is required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      console.log(values);
      console.log(rating);
      let data = {
        review: values.review,
        rating: rating,
      };
      customAxios
        .post("/customer/contract/" + selectedContract?.id + "/review", data)
        .then((res) => {
          setShowModal(false);
          setTrigger(Math.floor(Math.random() * 100000000000000000));
          navigate("/my-hires");
        });
    },
  };

  function renderPanel(type, data) {
    let buttonsRender = (contract) => {
      switch (type) {
        case "pending":
          let type;
          return (
            <div className="bg-gray-50 col-span-4 rounded-xl bottom-0 w-full px-4 py-1 flex   justify-end items-center flex-row space-x-3">
              <div className="flex flex-row space-x-4 items-center w-1/3">
                <div
                  style={{
                    backgroundImage: `url(${contract?.service[0]?.freelancer?.profileImage})`,
                  }}
                  className="h-10 w-10 bg-cover rounded-full "
                ></div>
                <p
                  className="text-sm font-medium
                          "
                >
                  {contract?.service[0]?.freelancer?.name}
                </p>
              </div>

              {contract.agreedPrice ? (
                <div class="flex flex-row w-2/3 items-center p-2 justify-between">
                  <p className="mr-4">
                    Do you want to accept the offer of{" "}
                    {/* convert to currency */}
                    <span className="font-bold">
                      MVR {contract?.agreedPrice}
                    </span>
                    ?{" "}
                  </p>

                  <div className="flex text-center flex-col">
                    <div className="flex flex-row space-x-3">
                      <SecondarButton
                        heading={"Reject Offer"}
                        onClick={() => {
                          setModalContent({
                            type: "reject",
                            heading: "Reject Offer",
                            subHeading:
                              "Are you sure you want to reject this offer?",
                            status: "rejected",
                          });

                          setShowModal(true);
                        }}
                      />

                      <PrimaryButton
                        heading={"Accept Offer"}
                        onClick={() => {
                          customAxios
                            .post(
                              "/freelancer/contract/" +
                                contract.id +
                                "?action=accept-offer",
                              {
                                customerAgreed: true,
                              }
                            )
                            .then((res) => {
                              //  open payment page in same tab
                              window.location.href = res.url;
                            });
                        }}
                      />
                    </div>
                    <button
                      onClick={() => {
                        setShowModal(true);
                        setSelectedContract(contract);
                        setModalContent({
                          isRenogatiate: true,
                          type: "renogatiate",
                          heading: "Renogatiate Offer",
                          subHeading:
                            "Are you sure you want to renogatiate this offer?",
                        });
                      }}
                    >
                      <p className="text-sm text-teal-500 mt-2">
                        Renogatiate Offer
                      </p>
                    </button>
                  </div>
                </div>
              ) : (
                <div class="bg-gray-50 col-span-4 rounded-xl bottom-0 w-full px-4 py-3 flex  justify-end items-center flex-row space-x-3">
                  <SecondarButton
                    heading={"Cancel"}
                    onClick={() => {
                      setModalContent({
                        type: "cancel",
                        heading: "Cancel Project",
                        subHeading:
                          "Are you sure you want to cancel this project?",
                        status: "canceled",
                      });

                      setShowModal(true);
                    }}
                  />

                  {/* call a number */}
                  <a href={`tel:${contract?.service[0]?.freelancer?.phone}`}>
                    <PrimaryButton heading={"Contact Freelancer"} />
                  </a>
                </div>
              )}
              <Modal
                show={showModal}
                setShowModal={setShowModal}
                heading={modalContent?.heading}
                subHeading={modalContent?.subHeading}
              >
                {modalContent?.isRenogatiate && (
                  <div className="p-10">
                    <FormGenerator
                      buttonSubmit={{
                        title: "Submit",
                      }}
                      formData={negotiateFormData}
                    />
                  </div>
                )}

                {/* Button Layout */}
                <div class="bg-gray-50 sticky bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-1  justify-end items-center flex-row space-x-3">
                  <SecondarButton
                    heading={"Cancel"}
                    onClick={() => setShowModal(false)}
                  />

                  {!modalContent?.isRenogatiate && (
                    <LoadingPrimaryButton
                      title={
                        type == "cancel" ? "Cancel Project" : "Reject Offer"
                      }
                      log={"Please wait"}
                      onClick={() => {
                        let urlParams = new URLSearchParams();
                        urlParams.append("status", modalContent?.status);
                        customAxios
                          .put(
                            `/admin/contracts/${contract.id}/change-status` +
                              "?" +
                              urlParams
                          )
                          .then((response) => {
                            // close modal after 2 seconds
                            setTimeout(() => {
                              setShowModal(false);
                            }, 2000);
                            setTrigger(Math.random());
                          });
                      }}
                    />
                  )}
                </div>
              </Modal>
            </div>
          );

        case "in-progress":
          let completed = false;
          return (
            <div className="bg-gray-50 col-span-4 rounded-xl bottom-0 w-full px-4 py-1 flex   justify-end items-center flex-row space-x-3">
              <div className="flex flex-row space-x-4 items-center w-1/3">
                <div
                  style={{
                    backgroundImage: `url(${contract?.service[0]?.freelancer?.profileImage})`,
                  }}
                  className="h-10 w-10 bg-cover rounded-full "
                ></div>
                <p
                  className="text-sm font-medium
                          "
                >
                  {contract?.service[0]?.freelancer?.name}
                </p>
              </div>
              <div class="bg-gray-50 col-span-12 rounded-xl bottom-0 w-full px-4 py-3 flex  justify-end items-center flex-row space-x-3">
                <p className="mr-4">
                  Has the freelancer completed the project{" "}
                  {/* convert to currency */}?{" "}
                </p>

                <PrimaryButton
                  heading={"Mark as Completed"}
                  onClick={() => {
                    // let urlParams = new URLSearchParams();
                    // urlParams.append("status", "completed");
                    // customAxios
                    //   .put(
                    //     `/admin/contracts/${contract.id}/change-status` +
                    //       "?" +
                    //       urlParams
                    //   )
                    //   .then((response) => {
                    //     setTrigger(Math.random());
                    //   });
                    setShowModal(true);
                  }}
                />
              </div>
              <Modal
                show={showModal}
                setShowModal={setShowModal}
                heading={"Mark as Completed"}
                subHeading={
                  "Are you sure you want to mark this project as completed?"
                }
              >
                {/* Button Layout */}
                <div class="bg-gray-50 sticky bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-3  justify-end items-center flex-row space-x-3">
                  <SecondarButton
                    heading={"Cancel"}
                    onClick={() => setShowModal(false)}
                  />

                  <LoadingPrimaryButton
                    title={completed ? "Completed" : "Mark as Completed"}
                    log={
                      completed
                        ? "The project is marked as complete"
                        : "Changing the status..."
                    }
                    onClick={() => {
                      let urlParams = new URLSearchParams();
                      urlParams.append("status", "completed");
                      customAxios
                        .put(
                          `/admin/contracts/${contract.id}/change-status` +
                            "?" +
                            urlParams
                        )
                        .then((response) => {
                          // close modal after 2 seconds
                          setTimeout(() => {
                            setShowModal(false);
                          }, 2000);
                          setTrigger(Math.random());
                        });
                    }}
                  />
                </div>
              </Modal>
            </div>
          );

        case "completed":
          return (
            <div className="bg-gray-50 col-span-4 rounded-xl bottom-0 w-full px-4 py-3 flex   justify-start items-center flex-row space-x-3">
              <div className="flex flex-row space-x-4 items-center w-1/3">
                <div
                  style={{
                    backgroundImage: `url(${contract?.service[0]?.freelancer?.profileImage})`,
                  }}
                  className="h-10 w-10 bg-cover rounded-full "
                ></div>
                <p
                  className="text-sm font-medium
                          "
                >
                  {contract?.service[0]?.freelancer?.name}
                </p>
              </div>
              {/* Would you like to leave a review for the freelancer? */}
              {contract?.isReviewed ? (
                <div className="flex flex-row w-full items-center justify-end space-x-4">
                  <p className=" font-medium">Thank you for the review!</p>
                </div>
              ) : (
                <div className="flex flex-row w-full items-center justify-end space-x-4">
                  <p className=" font-medium">
                    Please leave a review for the freelancer
                  </p>
                  <PrimaryButton
                    heading={"Leave a Review"}
                    onClick={() => {
                      setSelectedContract(contract);
                      setShowModal(true);
                    }}
                  />
                </div>
              )}

              <Modal
                show={showModal}
                setShowModal={setShowModal}
                heading={"Leave a Review"}
                subHeading={
                  "Please leave a review for the freelancer. This will help us improve our services."
                }
              >
                <StarRatings setRatingParent={setRating} />
                <div className="px-5 pb-5">
                  <FormGenerator
                    formData={reviewFormData}
                    buttonSubmit={{
                      title: "Submit",
                    }}
                  />
                </div>

                {/* Button Layout */}
                <div class="bg-gray-50 sticky bottom-0 w-full px-4 py-3 md:flex grid grid-cols-2 gap-3  justify-end items-center flex-row space-x-3">
                  <SecondarButton
                    heading={"Cancel"}
                    onClick={() => setShowModal(false)}
                  />
                </div>
              </Modal>
            </div>
          );

        default:
          return (
            <div className="bg-gray-50 col-span-4 rounded-xl bottom-0 w-full px-4 py-3 flex   justify-start items-center flex-row space-x-3">
              <div className="flex flex-row space-x-4 items-center w-1/3">
                <div
                  style={{
                    backgroundImage: `url(${contract?.service[0]?.freelancer?.profileImage})`,
                  }}
                  className="h-10 w-10 bg-cover rounded-full "
                ></div>
                <p
                  className="text-sm font-medium
                          "
                >
                  {contract?.service[0]?.freelancer?.name}
                </p>
              </div>
            </div>
          );
      }
    };

    return (
      <div className="flex flex-col space-y-3">
        {data?.length >= 1 ? (
          <>
            {data?.map((contract, i) => (
              <Widget
                heading={contract?.service[0]?.name}
                subHeading={`Hired on ${moment(contract?.createdAt).format(
                  "DD MMM YYYY"
                )}`}
              >
                <div className="p-4 col-span-4 flex flex-col space-y-2 shadow-md border border-slate-100 rounded-xl">
                  <h1 className="font-medium w-full border-b border-slate-100 pb-2">
                    Booking Information
                  </h1>
                  <div className="grid grid-cols-4 gap-3">
                    {itemRender(
                      "Offer Price",
                      contract?.agreedPrice ?? "Not fixed"
                    )}

                    {itemRender("Package Name", contract?.package?.name)}
                    {itemRender("Package Price", contract?.package?.price)}
                  </div>
                </div>
                <div className="p-4 col-span-2 flex flex-col space-y-2 shadow-md border border-slate-100 rounded-xl">
                  <h1 className="font-medium w-full border-b border-slate-100 pb-2">
                    Customer Remark
                  </h1>
                  <p className="text-sm">{Parse(contract?.customerRemark)}</p>
                </div>
                <div className="p-4 col-span-2  flex flex-col space-y-2  shadow-md border border-slate-100 rounded-xl">
                  <h1 className="font-medium w-full border-b border-slate-100 pb-2">
                    Freelancer Remark
                  </h1>
                  <p className="text-sm">
                    {contract.freelancerRemark
                      ? Parse(contract?.freelancerRemark)
                      : "No reply yet"}
                  </p>
                </div>

                {buttonsRender(contract)}
              </Widget>
            ))}
          </>
        ) : (
          <div className="w-full flex p-10 flex-col items-center justify-center space-y-10">
            <img
              className="w-60 h-60"
              src="https://ouch-cdn2.icons8.com/9IdwhLDbWmauvim0L9oFGf-hRu2yOzwzHazwQxAAHhk/rs:fit:256:256/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvNjgx/LzYyNjMxNWFmLTY2/NDEtNGJiYi04ZjU3/LWMwZWJkN2VhMjc4/ZC5zdmc.png"
            />
            <h1 className="text-center text-xl font-medium">
              No Bookings found
            </h1>
            <PrimaryButton
              heading={"Create a Booking"}
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
        )}
      </div>
    );
  }

  const tab = [
    {
      name: "In Progress",
      status: "in-progress",
      count: hires?.inProgressContracts?.length ?? 0,
      component: renderPanel("in-progress", hires?.inProgressContracts ?? []),
    },
    {
      name: "Pending",
      status: "pending",
      count: hires?.pendingContracts?.length ?? 0,
      component: renderPanel("pending", hires?.pendingContracts ?? []),
    },
    {
      name: "Completed",
      status: "completed",
      count: hires?.completedContracts?.length ?? 0,
      component: renderPanel("completed", hires?.completedContracts ?? []),
      modal: {
        heading: "Mark as Completed",
        subHeading: "Are you sure you want to mark this contract as completed?",
        buttons: [
          {
            heading: "Cancel",
            onClick: () => {
              setShowModal(false);
            },
          },
          {
            heading: "Mark as Completed",
            onClick: (contract) => {
              let urlParams = new URLSearchParams();
              urlParams.append("status", "completed");
              customAxios
                .put(
                  `/admin/contracts/${contract.id}/change-status` +
                    "?" +
                    urlParams
                )
                .then((response) => {
                  setTrigger(Math.random());
                });
              setShowModal(false);
            },
          },
        ],
      },
    },
    {
      name: "Rejected",
      status: "rejected",
      count: hires?.rejectedContracts?.length ?? 0,
      component: renderPanel("rejected", hires?.rejectedContracts ?? []),
    },
    {
      name: "Cancelled",
      status: "canceled",
      count: hires?.canceledContracts?.length ?? 0,
      component: renderPanel("canceled", hires?.canceledContracts ?? []),
    },
  ];

  return (
    <div className="relative">
      <NavBar />

      <div className="flex items-start justify-center min-h-[100vh]  ">
        <div className=" pt-24 md:pt-28 p-5 flex flex-col space-y-1 px-5 md:px-20 w-full">
          <AuthHeading heading={"My Hires"} />

          <p className="text-slate-600 text-sm ">
            {"Here you can find all the hires"}
          </p>
          <div className="flex flex-col w-full">
            <div className="pt-2 space-y-3 justify-between flex flex-col h-full">
              <Tab.Group>
                <Tab.List className="flex   overflow-x-auto border-b">
                  {tab.map((item, index) => {
                    return (
                      <Tab
                        key={index}
                        className={({ selected }) =>
                          ` ${
                            selected
                              ? "text-slate-900 font-semibold  border-b-2  border-teal-500"
                              : "text-slate-400  "
                          }
                          w-full min-w-max py-2.5 px-1 text-sm md:text-base flex flex-row items-center justify-center space-x-3 focus:outline-none font-medium text-center`
                        }
                        onClick={() => setStatus(item?.status ?? "pending")}
                      >
                        <p>{item.name}</p>
                        <div className="rounded-full p-1 w-8 h-8 bg-white">
                          <p>{item.count ?? 0}</p>
                        </div>
                      </Tab>
                    );
                  })}
                </Tab.List>
                <Tab.Panels>
                  {tab.map((item, index) => {
                    return (
                      <Tab.Panel key={index} className="py-5">
                        {item.component}
                      </Tab.Panel>
                    );
                  })}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
