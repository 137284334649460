import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Widget from "components/widget/Widget";
import moment from "moment";
import parser from "html-react-parser";
import PrimaryButton from "ui/buttons/PrimaryButton";
import { itemRender } from "functions/ItemRender";
import customAxios from "middleware/customAxios";
import Checkbox from "components/checkbox/Checkbox";
import jwtDecode from "jwt-decode";

export default function Preview() {
  let customerAuth = useSelector((state) => state.auth.customer);
  let [submitted, setSubmitted] = React.useState(true);
  let [logState, setLogState] = React.useState("Creating a user...");
  let [error, setError] = React.useState("");
  let [tocAgreed, setTocAgreed] = React.useState(false);
  let token = localStorage.getItem("token");
  let [tokenData, setTokenData] = React.useState({});

  React.useEffect(() => {
    // scroll to top
    // JWT decode token
    setTokenData(jwtDecode(token));

    window.scrollTo(0, 0);
    setSubmitted(false);
    setLogState("Creating a user...");
  }, [error]);

  const handleSubmit = () => {
    if (!tocAgreed) {
      setError("Please agree to the terms and conditions");
      setSubmitted(false);
      return;
    }

    let userData = {
      phone: customerAuth.phone,
      password: customerAuth.password,
      dateOfBirth: moment(customerAuth.dateOfBirth).format("MM-DD-YYYY"),
    };

    let data = {
      name: customerAuth.name,
      email: customerAuth.email,
      idCard: customerAuth.idCard,
      idCardUrl: customerAuth.idCardUrl,
      profileImage: customerAuth.profilePictureURL,
      bankName: customerAuth.bankName?.name,
      accountNumber: customerAuth.accountNumber,
      accountName: customerAuth.accountName,
    };

    customAxios
      .post("/freelancer/create-client/" + tokenData?.user?.id, data)
      .then((res) => {
        if (res.error) {
          setError(res.error);
          setSubmitted(false);
          return;
        }
        setLogState("Customer Created Successfully");
      });
  };

  return (
    <div className="flex flex-col space-y-3">
      {/* show error */}
      {error && (
        <div className="flex flex-row justify-between items-center p-5 bg-red-100 rounded-md">
          <div className="flex flex-col space-y-1">
            <h1 className="text-lg font-medium text-red-500">Error</h1>
            <h2 className="text-sm text-slate-600">{error}</h2>
          </div>
        </div>
      )}
      <Widget
        heading={"Basic Information"}
        subHeading={"Basic information about the user"}
        editUrl={"/auth/signup/customer/step/Basic Information"}
      >
        {itemRender("Name", customerAuth?.name || "N/A")}
        {itemRender("Email", customerAuth?.email || "N/A")}
      </Widget>
      {/* Profile */}
      <Widget
        heading={"Profile"}
        subHeading={""}
        editUrl={"/auth/signup/customer/step/Profile"}
      >
        {/* Render profile picture */}
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col space-y-1">
            <h1 className="text-sm font-medium">Profile Picture</h1>
            <div className="flex flex-row space-x-2">
              <img
                className="w-40 h-40 object-cover rounded-full"
                src={customerAuth?.profilePictureURL || "/images/profile.png"}
                alt="profile"
              />
            </div>
          </div>
        </div>
      </Widget>

      {/* Document */}
      <Widget
        heading={"Document"}
        subHeading={""}
        editUrl={"/auth/signup/customer/step/Document Upload"}
      >
        {itemRender("ID Card", customerAuth?.idCard || "N/A")}
        {itemRender("Document", customerAuth?.idCardUrl || "N/A")}
      </Widget>

      {/* Banking Info */}
      <Widget
        heading={"Banking Information"}
        subHeading={""}
        editUrl={"/auth/signup/customer/step/Banking Info"}
      >
        {itemRender("Bank Name", customerAuth?.bankName?.name || "N/A")}
        {itemRender("Account Number", customerAuth?.accountNumber || "N/A")}
        {itemRender("Account Name", customerAuth?.accountName || "N/A")}
      </Widget>

      <div className="flex flex-row space-x-3 pt-7">
        <Checkbox
          checked={tocAgreed}
          onClick={() => setTocAgreed(!tocAgreed)}
        />
        <p>
          I agree to the{" "}
          <a
            className="hover:text-teal-500 text-slate-600"
            href="https://hivvaru-s3-bucket.s3.ap-south-1.amazonaws.com/documents/terms-and-conditionspdf.pdf"
            target="_blank"
          >
            Terms and Conditions
          </a>
        </p>
      </div>

      <div className="flex items-start justify-start py-10">
        {submitted ? (
          <button
            type="button"
            className=" flex flex-row justify-between text-teal-600 text-md font-medium p-2 px-6 rounded-md cursor-not-allowed"
          >
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-teal-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"
              ></path>
            </svg>
            {logState}
          </button>
        ) : (
          <button
            type="submit"
            onClick={() => {
              setSubmitted(true);
              // 5 seconds later
              setTimeout(() => {
                setSubmitted(false);
              }, 5000);
              handleSubmit();
            }}
            className="bg-teal-600 text-white text-sm font-medium p-2 px-10 rounded-full hover:bg-teal-800"
          >
            Login
          </button>
        )}
      </div>
    </div>
  );
}
