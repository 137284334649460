import AuthLayout from "layout/auth/AuthLayout";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import PrimaryButton from "ui/buttons/PrimaryButton";

export default function CustomerCreate() {
  let navigate = useNavigate();
  return (
    <div className="w-screen h-screen flex flex-col justify-between absolute z-50 top-0 bg-white">
      <AuthLayout
        heading={`Let's setup your Customer profile`}
        subtitle={`This won't take long. Here are some tips from our team to help you get started`}
      >
        <div className="flex flex-col space-y-3 mb-6 h-full font-medium">
          <p>Keep it short and simple</p>
          <p>Be specific</p>
          <p>Be honest</p>
          <p>Be professional</p>
        </div>
        <PrimaryButton
          onClick={() => navigate("step/Basic Information")}
          heading={"Get Started"}
        />
      </AuthLayout>
    </div>
  );
}
