import axios from "axios";
import customAxios from "middleware/customAxios";
import React from "react";
import { useDropzone } from "react-dropzone";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondarButton from "ui/buttons/SecondaryButton";

export default function FileUpload({
  setFieldValue,
  field,
  fieldText,
  previewHeight,
}) {
  const [files, setFiles] = React.useState([]);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      let filename = acceptedFiles[0].name;
      // remove white space
      filename = filename.replace(/\s/g, "");
      let filetype = acceptedFiles[0].type;
      customAxios
        .post("/signedURL", {
          filename: filename,
          filetype: filetype,
        })
        .then(function (result) {
          var signedUrl = result.signedRequest;
          setFieldValue(field, result.url);
          var options = {
            headers: {
              "Content-Type": filetype,
            },
          };
          console.log("signedUrl", signedUrl);

          return axios
            .put(signedUrl, acceptedFiles[0], options)
            .then(function (result) {
              console.log(result);

              // navigate to url
            })
            .catch(function (err) {
              console.log(err);
            });
        });
    },
  });

  const thumbs = files.map((file) => (
    <div style={{ fontSize: 10 }} className="w-full" key={file.name}>
      <div className="p-0.5 px-2 border border-secondary text-secondary rounded-md">
        <div
          className="w-full bg-contain bg-no-repeat bg-center rounded-md "
          style={{
            backgroundImage: `url(${file.preview})`,
            height: previewHeight ?? 100,
          }}
        />

        <p>{file.name}</p>
        <p>{file.type}</p>
      </div>
    </div>
  ));

  return (
    <div className="flex flex-col space-y-2">
      {!files?.length > 0 ? (
        <div
          {...getRootProps({ className: "dropzone" })}
          className="border border-secondary bg-slate-100 min-w-full md:min-w-[30vw]  focus:outline-none rounded-md space-y-3 flex flex-col items-center justify-center  p-5"
        >
          <input {...getInputProps()} />
          <img
            className="h-10 w-10"
            src="https://cdn-icons-png.flaticon.com/128/3685/3685850.png"
          />
          <p className="text-primary" style={{ fontSize: 10 }}>
            {fieldText ??
              `Drag 'n' drop some files here, or click to select files`}
          </p>
        </div>
      ) : (
        <>
          <aside className="">{thumbs}</aside>
          <SecondarButton
            onClick={() => setFiles([])}
            heading="Remove current"
          />
        </>
      )}
    </div>
  );
}
