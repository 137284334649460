import Footer from "components/navigation/footer/Footer";
import FreelancerNavBar from "components/navigation/FreelancerNavBar";
import React from "react";

export default function FreelancerLayout({ children, heading }) {
  return (
    <div className="text-center bg-slate-200">
      <FreelancerNavBar />

      <div className=" overflow-hidden bg-red-400 ">
        <img
          alt=""
          src="https://braydoncoyer.dev/_next/static/media/rays.426980b9.png"
          decoding="async"
          data-nimg="intrinsic"
          class="absolute top-0 right-0"
        ></img>
      </div>
      <div className="relative  min-h-[100vh]">
        <div className="flex items-start justify-center  ">
          <div className="pt-24 md:pt-24 relative justify-start items-start  p-2 md:p-5 flex flex-col space-y-2 md:space-y-4 max-w-7xl w-full">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
