export const statusRender = (status) => {
  switch (status) {
    case "pending":
      return (
        <span className="px-4 inline-flex text-[10px] w-fit p-0.5 leading-5  rounded-full bg-fuchsia-100 text-fuchsia-800">
          Pending
        </span>
      );
    case "in-progress":
      return (
        <span className="px-4 inline-flex text-[10px] w-fit p-0.5 leading-5  rounded-full bg-blue-100 text-blue-800">
          In Progress
        </span>
      );
    case "canceled":
      return (
        <span className="px-4 inline-flex text-[10px] w-fit p-0.5 leading-5  rounded-full bg-red-100 text-red-800">
          Cancelled
        </span>
      );
    case "rejected":
      return (
        <span className="px-4 inline-flex text-[10px] w-fit p-0.5 leading-5  rounded-full bg-red-100 text-red-800">
          Rejected
        </span>
      );
    case "delivered":
      return (
        <span className="px-4 inline-flex text-[10px] w-fit p-0.5 leading-5  rounded-full bg-teal-100 text-teal-800">
          Delivered
        </span>
      );
    default:
      return (
        <span className="px-4 inline-flex text-[10px] w-fit p-0.5 leading-5  rounded-full bg-amber-100 text-amber-800">
          Pending
        </span>
      );
      break;
  }
};
