import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setFreelancerAuth } from "features/auth/AuthSlice";

export default function Rate({ next }) {
  let dispatch = useDispatch();
  let freelancerAuth = JSON.parse(localStorage.getItem("freelancerAuth"));
  let navigate = useNavigate();
  let [errorMsg, setErrorMsg] = React.useState("");
  let [isError, setIsError] = React.useState(false);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "rate",
            displayName: "Set your minimum rate",
            type: "field",
            inputType: "number",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      rate: freelancerAuth?.rate ?? "",
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: async (values, { setErrors, errors }) => {
      // check for any errors
      // if (Object.keys(errors).length > 0) {
      //   setIsError(true);
      //   setErrorMsg("Please fill in all the required fields");
      //   return;
      // }

      //   Keep the previous values and add the new values
      values = { ...freelancerAuth, ...values };

      navigate(next ? next : "/auth/signup/seller/step/Package Metadata");
      dispatch(setFreelancerAuth(values));
    },
  };

  return (
    <div className="flex flex-col -space-y-1">
      <FormGenerator
        ErrorMsg={errorMsg}
        isError={isError}
        height={""}
        buttonSubmit={{ title: "Proceed" }}
        formData={formData}
      />
    </div>
  );
}
