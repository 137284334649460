import { useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
  useMountedLayoutEffect,
} from "react-table";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaSearch,
} from "react-icons/fa";
import customAxios from "middleware/customAxios";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          className="bg-orange-100 border-orange-300 text-orange-500 focus:ring-0 cursor-pointer"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

export default function Table({
  searchable,
  api,
  second,
  showFooter,
  showCard,
  cellFunction,
  columns,
  testData,
  setSelectedRow,
  setSelectedRows,
  selectedRow,
  showVerticalDividers,
  path,
  height,
  selectable,
  showHeader,
  preSelectedItems,
  alignRow,
  alignHeader,
}) {
  const handleSelectedRows = () => {
    return preSelectedItems ?? {};
  };

  let [tableData, setTableData] = useState([]);
  let [rowData, setRowData] = useState([]);
  let [search, setSearch] = useState("");
  let [loading, setLoading] = useState(true);

  React.useEffect(() => {
    let urlParams = new URLSearchParams();
    if (search) {
      urlParams.append("search", search);
    }
    customAxios
      .get(api + "?" + urlParams.toString())
      .then((res) => {
        console.log(res + "res");
        setTableData(res);
        setRowData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [search]);

  let navigate = useNavigate();
  let data = testData;
  const {
    footerGroups,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    setGlobalFilter,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: api != null ? rowData : testData,
      initialState: {
        pageIndex: 0,
        pageSize: 200,
        selectedRowIds: handleSelectedRows(),
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",

            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox

            Header: ({ getToggleAllRowsSelectedProps }) => {
              if (selectable) {
                return (
                  <div>
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                    />
                  </div>
                );
              }
            },

            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const spring = React.useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
    }),
    []
  );

  const { globalFilter } = state;

  async function rowSelectHandler(row) {
    if (selectable) {
      navigate(path + row.original.id);
    }
    setSelectedRow(row.original);
    await setSelectedRows(selectedFlatRows);
  }

  return (
    <div
      className={`flex flex-col space-y-4 ${
        height != null ? height : ""
      } w-full justify-between items-start `}
    >
      {searchable && (
        <div className="h-8  w-1/3 bg-slate-100 bg-opacity-20 rounded-md flex justify-center px-4 space-x-3  items-center">
          {/* add search icon */}
          <IconContext.Provider value={{ className: "text-slate-400" }}>
            <div className="flex justify-center items-center">
              <FaSearch />
            </div>
          </IconContext.Provider>
          <input
            type="text"
            className="w-full h-full bg-transparent text-black dark:text-white focus:outline-none"
            placeholder="Quick Search"
            value={search || ""}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      )}

      <div
        className={
          "flex-1 w-full  justify-start bg-white  h-[20vh] rounded-md overflow-auto"
        }
      >
        <table className={"w-full"} {...getTableProps()}>
          <thead
            className={` ${
              showHeader ? "" : "text-white h-0  top-10"
            } w-full  border-slate-400 dark:border-slate-700`}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                className={` rounded-md    font-bold`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className={`text-sm bg-white   ${
                      second ? "border" : "border-b"
                    }rounded-md p-2 pl-3 py-1  font-bold sticky top-0 ${
                      alignHeader == "center" ? "text-center " : "text-left"
                    } w-fit`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <span
                      className={`${
                        showHeader ? "" : "invisible "
                      } text-start  ${
                        alignHeader == "center" ? "text-center " : "text-left"
                      }`}
                    >
                      {column.render("Header")}
                    </span>
                    <div className=""></div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody
            className="divide-y divide-slate-300 dark:divide-slate-200 "
            {...getTableBodyProps()}
          >
            <AnimatePresence>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <motion.tr
                    key={i}
                    onClick={() => rowSelectHandler(row)}
                    className={`
                    ${showVerticalDividers && "divide-x"}  
                    ${selectedRow == row.original ? "bg-slate-100" : ""}
                    ${
                      selectedFlatRows.includes(row.values)
                        ? "bg-orange-100"
                        : ""
                    }
                    pb-1 pl-3  hover:bg-slate-100/40 hover:dark:bg-slate-200/10 bg-opacity-75 cursor-pointer
                  `}
                    {...row.getRowProps({
                      layoutTransition: spring,
                      exit: { opacity: 0, maxHeight: 0 },
                      enter: { opacity: 0, maxHeight: 0 },
                    })}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className={`pl-3 py-2  ${
                            alignRow == "center" ? "text-center" : "text-left"
                          }`}
                          {...cell.getCellProps()}
                        >
                          {cellFunction(cell)}
                        </td>
                      );
                    })}
                  </motion.tr>
                );
              })}
            </AnimatePresence>
          </tbody>
          {showFooter && (
            <tfoot>
              {footerGroups.map((group) => (
                <tr
                  className="border-t border-slate-400 "
                  {...group.getFooterGroupProps()}
                >
                  {group.headers.map((column) => (
                    <td className="p-2" {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>

      {/* Pagination */}
      {api && (
        <div className="flex justify-between items-center w-full bg-slate-100 rounded-xl px-4 p-1">
          <div className="flex items-center space-x-2">
            <div className="flex items-center space-x-2">
              <div className="mr-10">
                <span className="text-sm">Rows per page:</span>
                <select
                  className="w-10 h-8 rounded-md bg-white ml-2 text-black dark:text-white focus:outline-none"
                  value={tableData?.meta?.per_page}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option
                      className="bg-white"
                      key={pageSize}
                      value={pageSize}
                    >
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex items-center space-x-4">
                <span className="text-sm">Page:</span>
                <span className="text-sm">
                  {tableData?.meta?.current_page} of{" "}
                  {tableData?.meta?.last_page}
                </span>

                <button
                  className="w-8 h-8  rounded-full bg-white text-black dark:text-white focus:outline-none"
                  //
                  disabled={tableData?.links?.next == null}
                >
                  <IconContext.Provider value={{ className: "text-slate-400" }}>
                    <div className="flex justify-center items-center">
                      <FaAngleDoubleLeft />
                    </div>
                  </IconContext.Provider>
                </button>
                <button
                  className="w-8 h-8  rounded-full bg-white text-black dark:text-white focus:outline-none"
                  // onClick={() => previousPage()}
                  // disabled={!canPreviousPage}
                >
                  <IconContext.Provider value={{ className: "text-slate-400" }}>
                    <div className="flex justify-center items-center">
                      <FaAngleLeft />
                    </div>
                  </IconContext.Provider>
                </button>
                <button
                  className="w-8 h-8 rounded-full bg-white text-black dark:text-white focus:outline-none"
                  // onClick={() => nextPage()}
                  // disabled={!canNextPage}
                >
                  <IconContext.Provider value={{ className: "text-slate-400" }}>
                    <div className="flex justify-center items-center">
                      <FaAngleRight />
                    </div>
                  </IconContext.Provider>
                </button>
                <button
                  className="w-8 h-8  rounded-full bg-white text-black dark:text-white focus:outline-none"
                  // onClick={() => gotoPage(pageCount - 1)}
                  // disabled={!canNextPage}
                >
                  <IconContext.Provider value={{ className: "text-slate-400" }}>
                    <div className="flex justify-center items-center">
                      <FaAngleDoubleRight />
                    </div>
                  </IconContext.Provider>
                </button>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-sm">Showing:</span>
            <span className="text-sm">
              {tableData?.meta?.per_page + 1} - {tableData?.meta?.total} of{" "}
              {rowData?.length ?? 0}
            </span>

            <span className="text-sm">Rows</span>
          </div>
        </div>
      )}
    </div>
  );
}
