import React from "react";

export default function ServiceBanner({ image, category }) {
  return (
    <div className=" rounded-5xl">
      <div
        style={{
          backgroundImage: `url(${category?.imageURL})`,
        }}
        className="bg-cover relative bg-clip-content rounded-2xl  bg-left-top border h-[calc(100vh-590px)]  transition-all duration-150 bg-no-repeat  w-full"
      >
        <div className="flex items-center text-center p-5 md:p-10 md:py-20 justify-start h-full w-full bg-black bg-opacity-20 ">
          <div className="text-white w-full flex items-center justify-center flex-col z-10 space-y-5  text-3xl font-semibold  ">
            <h1> {category?.name}</h1>
            <p className="font-sans text-base font-light  max-w-lg ">
              {category?.description}
            </p>
          </div>
        </div>
        {/* Create a gradient over */}
        <div className="absolute inset-0 bg-gradient-to-l from-transparent via-black-800 to-black opacity-100 "></div>
      </div>
    </div>
  );
}
