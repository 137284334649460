import React from "react";
import { useSelector } from "react-redux";

export default function AuthHeading({ heading }) {
  let isFreelancer = useSelector((state) => state.global.isFreelancer);
  return (
    <h1
      className={`w-fit  ${
        isFreelancer ? "" : ""
      } text-xl md:text-3xl font-extrabold text-gray-900`}
    >
      {heading}
    </h1>
  );
}
