import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { isAfter } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerAuth } from "features/auth/AuthSlice";

export default function BasicInformation({ nextUrl }) {
  let customerAuth = useSelector((state) => state.auth.customer);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [errorMsg, setErrorMsg] = React.useState("");
  let [isError, setIsError] = React.useState(false);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "name",
            displayName: "Name",
            type: "field",
            inputType: "text",
            required: true,
          },
          {
            name: "email",
            displayName: "Email",
            type: "field",
            inputType: "email",
            required: true,
          },
        ],
      },
    ],
    initialValues: {
      name: customerAuth?.name ?? "",
      email: customerAuth?.email ?? "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
    }),
    onSubmit: async (values, { setErrors }) => {
      // Keep the previous values and add the new values
      values = { ...customerAuth, ...values };

      navigate(nextUrl ? nextUrl : "/auth/signup/customer/step/Password");
      dispatch(setCustomerAuth(values));
    },
  };

  return (
    <div className="flex flex-col -space-y-1">
      <FormGenerator
        ErrorMsg={errorMsg}
        isError={isError}
        height={""}
        buttonSubmit={{ title: "Proceed" }}
        formData={formData}
      />
    </div>
  );
}
