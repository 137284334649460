import WorkCard from "components/work-card/WorkCard";
import { replaceSpaces } from "functions/replaceDashes";
import customAxios from "middleware/customAxios";
import React from "react";
import Heading from "ui/typography/heading/Heading";

export default function FeaturedWorks() {
  let [services, setServices] = React.useState([]);

  React.useEffect(() => {
    customAxios.get("/content/services").then(
      (res) => {
        console.log(res);
        setServices(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  return (
    <div className="flex flex-col w-full space-y-6 pb-0 px-5 md:px-20 pb-10">
      <div className="flex col-span-4 flex-col space-y-3 justify-start items-start">
        <Heading heading={"Featured Works"} />
        <p className="text-sm md:text-sm text-slate-700">
          Browse through our categories and find the best freelancers for your
          job
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-5 md:gap-5  ">
        {services.map((service, i) => {
          if (i == 3) {
            return (
              <WorkCard
                ad={true}
                key={3}
                id={2}
                path={"https://sto.gov.mv"}
                category={service.category?.name}
                image={"https://pbs.twimg.com/media/Ccdb7ZjUkAAMfRk.jpg"}
                title={
                  "Makita 18V - The best cordless power tool system in the world (and why)"
                }
                price={"Sponsored"}
                user={{
                  name: "State Trading Organization",
                  profileImage:
                    "https://www.local.mv/wp-content/uploads/listing-uploads/logo/2019/12/12742745_495389707336114_6384276971333799446_n.jpg",
                }}
              />
            );
          } else {
            return (
              <WorkCard
                key={service.id}
                id={service.id}
                path={
                  "/Services/View/" +
                  service.id +
                  "?name=" +
                  replaceSpaces(service.name)
                }
                category={service.category?.name}
                image={
                  service.packages[0]?.images
                    ? service.packages[0]?.images[0]?.value
                    : "null"
                }
                title={service.name}
                price={service.packages[0]?.price}
                user={service.freelancer}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
