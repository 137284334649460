export const columns = [
  {
    Header: "Date",
    accessor: "createdAt",
  },
  {
    Header: "Contract Status",
    accessor: "contract.status",
  },
  {
    Header: "Total",
    accessor: "total",
  },
  {
    Header: "Receievable",
    accessor: "recievable",
  },
  {
    Header: "Customer",
    accessor: "contract.customer.name",
  },
  {
    Header: "Payment Status",
    accessor: "paymentStatus",
  },
  {
    Header: "Available for Withdrawal",
    accessor: "escrowStatus",
  },
  {
    Header: "Attachment",
    accessor: "attachment",
  },
];
