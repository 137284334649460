import { XIcon } from "@heroicons/react/outline";
import NotificationModal from "components/notification-modal/NotificationModal";
import Service from "pages/auth/signup/freelancer/steps/service/Service";
import React from "react";
import FormGenerator from "components/form-generator/FormGenerator";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Combobox } from "@headlessui/react";
import ComboBoxComponent from "components/combo-box/ComboBoxComponent";
import { useDispatch, useSelector } from "react-redux";
import { setFreelancerAuth } from "features/auth/AuthSlice";

export default function UpdateService({ show, setShow }) {
  let dispatch = useDispatch();
  let freelancerAuth = JSON.parse(localStorage.getItem("freelancerAuth"));
  let navigate = useNavigate();
  let [errorMsg, setErrorMsg] = React.useState("");
  let [isError, setIsError] = React.useState(false);
  let [categories, setCategories] = React.useState(
    JSON.parse(localStorage.getItem("categories"))
  );
  let [selectedCategory, setSelectedCategory] = React.useState(null);
  let [selectedSubCategory, setSelectedSubCategory] = React.useState(null);
  let [loading, setLoading] = React.useState(false);
  let [subCategories, setSubCategories] = React.useState([]);

  // trim categories

  React.useEffect(() => {
    if (selectedCategory) {
      setSubCategories(selectedCategory.subCategories);
    }
  }, [selectedCategory]);

  const formData = {
    contents: [
      {
        fields: [
          {
            name: "category",
            displayName: "Category",
            type: "comboBox",
            required: true,
            values: categories ?? [],
            value: (formik) => {
              setSelectedCategory(formik?.values?.category);
              return formik.values.category;
            },
            options: function (item) {
              return (
                <div className="grid grid-cols-12">
                  <span className="col-span-12">{item?.name}</span>
                </div>
              );
            },
          },
          {
            name: "subCategory",
            displayName: "Sub Category",
            type: "component",
            required: true,
            component: (formik) => {
              return (
                <Combobox
                  value={
                    freelancerAuth?.subCategory ?? selectedSubCategory ?? {}
                  }
                  onChange={(value) => {
                    dispatch(
                      setFreelancerAuth({
                        ...freelancerAuth,
                        subCategory: null,
                      })
                    );
                    console.log(value);
                    setSelectedSubCategory(value);
                    // formik.setFieldValue("subCategory", value);
                  }}
                >
                  <ComboBoxComponent
                    options={function (item) {
                      return (
                        <div className="grid grid-cols-12">
                          <span className="col-span-12">{item?.name}</span>
                        </div>
                      );
                    }}
                    values={subCategories}
                  />
                </Combobox>
              );
            },
          },
          {
            name: "serviceName",
            displayName: "Service Name",
            type: "field",
            required: true,
          },
          {
            name: "serviceDescription",
            displayName: "Description",
            data: freelancerAuth?.serviceDescription,
            type: "editor",
            required: true,
          },

          // {
          //   name: "isHighest",
          //   displayName: "Is Highest",
          //   type: "checkBox",
          //   required: true,
          // },
        ],
      },
    ],
    initialValues: {
      category: freelancerAuth?.category ?? null,
      subCategory: freelancerAuth?.subCategory ?? null,
      serviceName: freelancerAuth?.serviceName ?? "",
      serviceDescription: freelancerAuth?.serviceDescription ?? "",
    },
    validationSchema: Yup.object().shape({
      category: Yup.object().required("Category is required"),
      serviceName: Yup.string().required("Service Name is required"),
      serviceDescription: Yup.string().required("Description is required"),
    }),
    onSubmit: async (
      values,
      { setSubmitting, setErrors, errors, setStatus, resetForm, handleSubmit }
    ) => {
      values = {
        category: selectedCategory,
        subCategory: selectedSubCategory,
        serviceName: values.serviceName,
        serviceDescription: values.serviceDescription,
      };

      // Keep the previous values and add the new values
      values = { ...freelancerAuth, ...values };
      console.log(values);

      dispatch(setFreelancerAuth(values));
      setShow(false);
    },
  };

  return (
    <NotificationModal
      showX={false}
      show={show}
      setShowModal={setShow}
      heading={"Update Service"}
      subHeading={"Please update the following"}
    >
      {!loading && (
        <FormGenerator
          ErrorMsg={errorMsg}
          isError={isError}
          height={""}
          buttonSubmit={{ title: "Proceed" }}
          formData={formData}
        />
      )}
      <div
        onClick={() => {
          setShow(false);
        }}
        className="absolute top-3 right-3 cursor-pointer  bg-slate-50 border-slate-200 p-5"
      >
        <XIcon className="w-5 h-5 text-slate-600" />
      </div>
    </NotificationModal>
  );
}
