import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import HTMLReactParser from "html-react-parser";
import MainLayout from "layout/main/MainLayout";
import React, { Fragment } from "react";

const faqs = [
  {
    question: "What kind of information will Hivvaru Never ask me to provide?",
    answer: `<p>We may ask for a few things, but we will never ask for:<p>
    <ol>
    <li>1. Your password or sign-in credentials</li>
    <li>2. Your bank account or credit card number</li>
    <li>3. You to log into a site outside of the www.hivvaru.com domain</li>
    <li>4. Your PIN or password</li>
    </ol>`,
  },
  {
    question: " How do I use the search bar?",
    answer:
      " Type in the service you want. You can also search by category of the Freelancer you are looking for ",
  },
  {
    question: " How do I sign up as a Freelancer?",
    answer: `<ul><li>1. Follow the easy step by step process, you will need a valid Phone number, a password, a short bio of yourself, services you offer.</li>
<li> 2. You will also need to upload National ID card or Passport photo for verification and Bank details for payments to be made. (Public will not see this information).</li>
<li>3. After you create a service, you must create at least one Package which the clients can book.</li>`,
  },
  {
    question: "How do I sign up?",
    answer:
      "Signing up is easy. Just click on the sign up button on the top right of the page and fill in the required information. Once you have signed up you can start posting your projects and finding the right freelancer for your project.",
  },
  {
    question: "How do I create a service?",
    answer:
      "Write a short sentence or phrase that describes your service, add photos of your services, related material. Then, write a detailed version of your service describing what you do, important details like, location, duration estimates and why clients should hire you. You’re all set.",
  },
  {
    question: "How do I create a package?",
    answer:
      "The packages are what the Clients will book out of your service. This is where you can sell multiple packages, each offering designed to cater your client’s needs. You could create basic package for a minimal service, and a higher starting range for the full service. This heavily depends on the type of your freelance work. Make the Package name short and simple",
  },
  {
    question: "Who is Handpicked by Hivvaru?",
    answer:
      " We verify quality services, by amount of work they have completed, experience in the field and when they have several satisfied customers. Having a star rating of 4 and 5 stars also help freelancers become Handpicked by us. They get featuring perks and more marketing opportunities. You can also get Handpicked! It is a part of our core values to search for the best of us and give a boost",
  },
  {
    question: "What’s in My Earnings?",
    answer:
      "Freelancers can see their receivable payments made by the client here. You will have a record of all your earnings through Hivvaru on this page with the client details as well as the package details.",
  },
  {
    question: "What’s in My Jobs?",
    answer: `<p><b>Pending:</b>  This is the negotiation stage where you are discussing the price and hiring or rejecting the service.</p>
<p><b>In progress:</b> Your booking is in progress after the payment</p>
<p><b>Completed:</b>  Hires can be seen here once the service is marked as complete</p>
<p><b>Rejected:</b>  Hires are seen here If any party rejects the service</p>
<p><b>Cancelled:</b>  Cancelled Hires.</p>`,
  },
  {
    question: "How do I chat with a freelancer?",
    answer:
      "You can click on “contact freelancer” on their profile. This stage is also where you initiate discussion about service and finalize price.",
  },
  {
    question: "How do I book a freelancer?",
    answer:
      " On their service page, click the preferred package, chat with the freelancer and hire.",
  },
  {
    question: "How do I pay a Freelancer?",
    answer:
      " Paying a freelancer is easy. Just click accept after the negotiation with freelancer and you will redirect to Bank of Maldives payment gateway where you can use any Bank Card accepted in the Maldives. Once you have paid a freelancer you can start getting your service started. The funds will be in Hivvaru Secure Pay™ and released to the freelancer once the service is marked as complete",
  },
  {
    question: "Why was my payment declined?",
    answer: `<p>Payments can be declined for several reasons.
Make sure that: </p>
1. Your payment details have been entered correctly.
2. Your credit/debit card is valid (and not expired)
3. You have available funds on your card.
If there is any failed transaction please do email to us, so that we can double check with the Bank.`,
  },
  {
    question: "How do I review a freelancer?",
    answer:
      " When your order is marked as completed, you will be asked to review and rate the service. You can give a star rating and your review will be seen on the freelancer’s profile.",
  },
  {
    question: "How do I cancel a Booking?",
    answer: `You can reject offers only when the negotiations are ongoing and booking is still pending. If you are cancelling after you hire the freelancer, you must contact the Hivvaru team. 
Make sure that: Your cancellation is within a reasonable timeframe. Refer to the Terms & Conditions.`,
  },
];

export default function FAQ() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainLayout>
      <div className="flex flex-col items-start space-y-10 p-5 px-5 md:px-20 py-10 md:py-10 min-h-[90vh] ">
        <div data-aos={"fade-in"} className="flex flex-col space-y-3">
          <h1 className="text-black text-3xl md:text-4xl font-bold">
            Frequently Asked Questions{" "}
          </h1>
          <p className="text-sm md:text-base text-slate-700">
            Here are some of our FAQs. If you have any other questions you'd
            like answered please feel free to email us. We'd love to hear from
            you.
          </p>
        </div>

        <div className="grid md:grid-cols-1 gap-3 w-full">
          {faqs.map((faq) => (
            <Disclosure>
              {({ open }) => (
                <div className="flex flex-col space-y-2 w-full">
                  <Disclosure.Button className="flex w-full items-center text-sm justify-between rounded-lg bg-slate-100 px-4 py-2 text-left md:text-base font-medium text-teal-900  focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75">
                    <span className="w-full">{faq.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-teal-500 `}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel
                    data-aos={"fade-up"}
                    className="px-4 pt-4 pb-2 text-sm leading-relaxed text-gray-500"
                  >
                    {HTMLReactParser(faq.answer)}
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </MainLayout>
  );
}
