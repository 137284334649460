import React, { useMemo } from "react";

const RE_DIGIT = new RegExp(/^\d+$/);

const valueLength = 6;

export default function OTPInput({ value, onChange }) {
  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  const focusToNext = (idx) => {
    const nextInput = document.querySelector(
      `.otp-input:nth-child(${idx + 2})`
    );
    if (nextInput) {
      nextInput.focus();
    }
  };

  const focusToPrev = (idx) => {
    const prevInput = document.querySelector(`.otp-input:nth-child(${idx})`);
    if (prevInput) {
      prevInput.focus();
    }
  };

  const inputOnChange = (e, idx) => {
    const { value } = e.target;
    if (RE_DIGIT.test(value)) {
      const newValue = valueItems.map((digit, i) => {
        if (i === idx) {
          return value;
        }
        return digit;
      });
      onChange(newValue.join(""));
      focusToNext(idx);
    }
  };

  const inputOnKeyDown = (e) => {
    const { key, target } = e;
    const idx = parseInt(target.dataset.idx);
    if (key === "Backspace") {
      e.preventDefault();
      focusToPrev(idx);
    }
  };

  const inputOnFocus = (e) => {
    const { target } = e;
    const idx = parseInt(target.dataset.idx);
    if (idx === 0) {
      target.select();
    }
  };

  return (
    <div className="flex flex-row text-2xl font-medium space-x-3 items-center justify-center">
      <div className="otp-group flex flex-row space-x-3 items-center justify-center">
        {valueItems.map((digit, idx) => (
          <input
            key={idx}
            type="text"
            className="otp-input text-xl p-2 rounded-md border border-secondary text-center w-10"
            data-idx={idx + 1}
            value={digit}
            onChange={(e) => inputOnChange(e, idx)}
            onKeyDown={inputOnKeyDown}
            onFocus={inputOnFocus}
            maxLength={1}
          />
        ))}
      </div>
    </div>
  );
}
