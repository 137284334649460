import { Disclosure, Tab } from "@headlessui/react";
import NavBar from "components/navigation/NavBar";
import Widget from "components/widget/Widget";
import MainLayout from "layout/main/MainLayout";
import customAxios from "middleware/customAxios";
import moment from "moment";
import React from "react";
import AuthHeading from "ui/typography/heading/AuthHeading";
import Parse from "html-react-parser";
import SecondarButton from "ui/buttons/SecondaryButton";
import LoadingPrimaryButton from "ui/buttons/LoadingPrimaryButton";
import PrimaryButton from "ui/buttons/PrimaryButton";
import FreelancerNavBar from "components/navigation/FreelancerNavBar";
import FreelancerLayout from "layout/freelancer/FreelancerLayout";
import { itemRender } from "functions/ItemRender";
import Modal from "components/modal/Modal";
import OfferModal from "./modals/OfferModal";

export default function MyJobs() {
  let [hires, setHires] = React.useState();
  let [loading, setLoading] = React.useState(true);
  let [status, setStatus] = React.useState("in-progress");
  let [showOfferModal, setShowOfferModal] = React.useState(false);
  let [offerStatus, setOfferStatus] = React.useState(null);
  let [selectedJob, setSelectedJob] = React.useState(null);
  let [refresh, setRefresh] = React.useState(
    Math.floor(Math.random() * 100000000000000000)
  );

  React.useEffect(() => {
    customAxios.get(`/freelancer/contracts`).then((res) => {
      setHires(res);
    });
  }, [status, refresh]);

  React.useEffect(() => {}, [refresh]);

  let offerModalClose = () => {
    console.log("hit");
    setShowOfferModal(false);
  };

  function renderPanel(type, data) {
    let buttonsRender = (contract) => {
      switch (type) {
        case "pending":
          return (
            <div className="flex justify-end items-end flex-row w-full space-x-3">
              {!contract.agreedPrice && (
                <SecondarButton heading={"Reject"} onClick={() => {}} />
              )}

              <PrimaryButton
                heading={contract.agreedPrice ? "Change Offer" : "Create Offer"}
                onClick={() => {
                  setSelectedJob(contract);
                  setShowOfferModal(true);
                }}
              />
            </div>
          );
        default:
          return <p></p>;
      }
    };
    return (
      <div className="flex flex-col space-y-2 md:space-y-2 text-start ">
        {data?.length >= 1 ? (
          <>
            {data?.map((contract, i) => (
              <>
                <Widget
                  heading={contract?.package?.service?.name}
                  subHeading={`Booked by ${
                    contract?.customer?.name
                  } on ${moment(contract?.createdAt).format(
                    "dddd, DD MMMM YYYY"
                  )} `}
                >
                  <div className=" col-span-4 a flex flex-col space-y-2 shadow-none  rounded-xl">
                    <h1 className="font-semibold w-full text-sm md:text-base border-b border-slate-100 pb-2">
                      Booking Information
                    </h1>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                      {itemRender(
                        "Selected Package",
                        contract?.package?.name ?? "Not selected"
                      )}
                      {itemRender(
                        "Offered Price",
                        contract?.agreedPrice ?? "Not fixed"
                      )}
                    </div>
                  </div>

                  <div className=" col-span-4 md:col-span-2 flex flex-col space-y-2 shadow-none  rounded-xl">
                    <h1 className="font-semibold w-full text-sm md:text-base border-b border-slate-100 pb-2">
                      Customer Remarks
                    </h1>
                    <p className="text-xs max-h-[calc(100vh-300px)] overflow-auto  bg-white border border-slate-200 p-2 rounded-md">
                      {Parse(contract?.customerRemark)}
                    </p>
                  </div>
                  <div className=" col-span-4 md:col-span-2 flex flex-col space-y-2 shadow-none  rounded-xl">
                    <h1 className="font-semibold w-full text-sm md:text-base border-b border-slate-100 pb-2">
                      My Remarks
                    </h1>
                    <p className="text-xs max-h-[calc(100vh-300px)] overflow-auto  bg-white border border-slate-200 p-2 rounded-md">
                      {contract.freelancerRemark
                        ? Parse(contract?.freelancerRemark)
                        : "No reply yet"}
                    </p>
                  </div>

                  <div class="bg-white col-span-4 rounded-xl bottom-0 w-full px-4 py-3 flex  justify-between items-start flex-col md:flex-row space-x-3">
                    <div className="col-span-2 hidden justify-between w-full md:flex ">
                      {
                        <div className="flex flex-row space-x-2 text-sm font-medium items-center">
                          <div
                            style={{
                              backgroundImage: `url(${contract?.customer?.profileImage})`,
                            }}
                            className="h-10 w-10 bg-cover rounded-full "
                          ></div>
                          <p>{contract?.customer?.name}</p>
                        </div>
                      }
                    </div>

                    {buttonsRender(contract)}
                  </div>
                </Widget>
              </>
            ))}
          </>
        ) : (
          <div className="w-full flex p-10 flex-col items-center justify-center space-y-10">
            <img
              className="w-60 h-60"
              src="https://ouch-cdn2.icons8.com/9IdwhLDbWmauvim0L9oFGf-hRu2yOzwzHazwQxAAHhk/rs:fit:256:256/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvNjgx/LzYyNjMxNWFmLTY2/NDEtNGJiYi04ZjU3/LWMwZWJkN2VhMjc4/ZC5zdmc.png"
            />
            <h1 className="text-center text-xl font-medium">
              No Bookings found
            </h1>
          </div>
        )}
      </div>
    );
  }

  const tab = [
    {
      name: "In Progress",
      status: "in-progress",
      count: hires?.inProgressContracts?.length ?? 0,
      component: renderPanel("in-progress", hires?.inProgressContracts),
    },
    {
      name: "Pending",
      status: "pending",
      count: hires?.pendingContracts?.length ?? 0,
      component: renderPanel("pending", hires?.pendingContracts),
    },
    {
      name: "Completed",
      status: "completed",
      count: hires?.completedContracts?.length ?? 0,
      component: renderPanel("completed", hires?.completedContracts),
    },
    {
      name: "Rejected",
      status: "rejected",
      count: hires?.rejectedContracts?.length ?? 0,
      component: renderPanel("rejected", hires?.rejectedContracts),
    },
    {
      name: "Cancelled",
      status: "cancelled",
      count: hires?.cancelledContracts?.length ?? 0,
      component: renderPanel("cancelled", hires?.cancelledContracts),
    },
  ];

  return (
    <FreelancerLayout>
      <div className="flex flex-col space-y-1 md:space-y-3 text-left justify-start">
        <AuthHeading heading={"My Jobs"} />
        <p className="text-slate-600 text-sm">
          {"Here you can find all the jobs"}
        </p>
      </div>

      <div className="flex flex-col w-full">
        <div className="md:pt-2 pt-0 space-y-1 md:space-y-3 justify-between  flex flex-col h-full">
          <Tab.Group>
            <Tab.List className="flex   overflow-x-auto border-b">
              {tab.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    className={({ selected }) =>
                      `px-2  ${
                        selected
                          ? "text-slate-900 font-semibold  border-b-2  border-teal-500"
                          : "text-slate-400  "
                      }
                          w-full min-w-max py-2.5 text-sm md:text-base flex flex-row items-center justify-center space-x-3 focus:outline-none font-medium text-center`
                    }
                    onClick={() => setStatus(item?.status ?? "pending")}
                  >
                    <p className="text-sm">{item.name}</p>
                    <div className="rounded-full text-sm p-1 w-8 h-8 bg-white">
                      <p>{item.count ?? 0}</p>
                    </div>
                  </Tab>
                );
              })}
            </Tab.List>
            <Tab.Panels>
              {tab.map((item, index) => {
                return (
                  <Tab.Panel key={index} className="py-5">
                    {item.component}
                  </Tab.Panel>
                );
              })}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>

      <OfferModal
        show={showOfferModal}
        contract={selectedJob}
        closeModal={offerModalClose}
        setRefresh={setRefresh}
      />
    </FreelancerLayout>
  );
}
