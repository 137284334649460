import React from "react";
import PrimaryButton from "ui/buttons/PrimaryButton";
import SecondarButton from "ui/buttons/SecondaryButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFreelancerAuth } from "features/auth/AuthSlice";

export default function ServiceMetadata({ next }) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let freelancerAuth = JSON.parse(localStorage.getItem("freelancerAuth"));

  let [metadata, setMetadata] = React.useState(
    freelancerAuth?.ServiceMetadata ?? []
  );
  let [loading, setLoading] = React.useState(false);
  let [inputType, setInputType] = React.useState("none");
  let [key, setKey] = React.useState("");
  let [value, setValue] = React.useState("");

  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        {
          // show added Metdata
          metadata.map((item, index) => {
            return (
              <div className="col-span-12">
                <div className="flex flex-col ">
                  <div className="flex flex-row justify-start items-center space-x-1 md:space-x-6 ">
                    <div className="flex flex-col">
                      <p
                        placeholder="Title"
                        type="text"
                        className=" w-[96] border border-gray-300 text-sm rounde rounded-md px-4 py-2 "
                      >
                        {item.key}
                      </p>
                    </div>

                    <div className="flex flex-col">
                      <p
                        placeholder="Value"
                        type="text"
                        className="w-full border border-gray-300 text-sm rounde rounded-md px-4 py-2 "
                      >
                        {item.value}
                      </p>
                    </div>

                    <div className="w-1/4">
                      <SecondarButton
                        heading={"Remove"}
                        onClick={() => {
                          setMetadata(
                            metadata.filter((item, i) => i !== index)
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
        <div className="col-span-12">
          <div className="flex flex-col ">
            <div className="flex flex-row justify-start items-center space-x-1 md:space-x-6">
              <div className="flex flex-col">
                <input
                  id="key"
                  name="key"
                  value={key}
                  placeholder="Title"
                  onChange={(e) => {
                    setKey(e.target.value);
                  }}
                  type="text"
                  className="w-full border border-gray-300 text-sm rounde rounded-md px-4 py-2 "
                />
              </div>

              <div className="flex flex-col">
                <input
                  id="value"
                  name="value"
                  placeholder="Value"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  type="text"
                  className="w-full border border-gray-300 text-sm rounde rounded-md px-4 py-2 "
                />
              </div>

              <div className="w-1/4">
                <PrimaryButton
                  heading={"Add"}
                  onClick={() => {
                    setMetadata([...metadata, { key, value }]);
                    setKey("");
                    setValue("");
                    // reset input fields
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-5 z-50 ">
        <PrimaryButton
          type={"submit"}
          onClick={() => {
            dispatch(
              setFreelancerAuth({
                ...freelancerAuth,
                ServiceMetadata: metadata,
              })
            );
            navigate(next ? next : "/auth/signup/seller/step/Service Pictures");
          }}
          heading={"Proceed"}
        />
      </div>
    </div>
  );
}
